import { useState, useEffect } from "react";
import { Alert, AlertIcon, Text, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import Select from "@svix/common/widgets/form/Select";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@svix/common/widgets/Modal";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { trackEvent } from "src/analytics";
import { getApiConfiguration } from "src/api";
import { AuthenticationApi } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import { Key } from "./";

interface IRotateTokenModal {
  isOpen: boolean;
  onClose: () => void;
  apiKey: Key | undefined;
  onRotate: () => void;
}

const defaultValues = {
  expiry: "0",
};

const expirationOpts = [
  { label: "now", value: 0 },
  { label: "in 5 minutes", value: 5 * 60 },
  { label: "in 30 minutes", value: 30 * 60 },
  { label: "in 1 hour", value: 60 * 60 },
  { label: "in 6 hours", value: 60 * 60 * 6 },
  { label: "in 12 hours", value: 60 * 60 * 12 },
  { label: "in 24 hours", value: 60 * 60 * 24 },
];

// Using an '<option>' element always gives us a string value
type AuthTokenExpireInString = {
  expiry: string;
};

export default function RotateTokenModal(props: IRotateTokenModal) {
  const activeEnvId = useAppSelector((state) => state.auth.activeEnvId);
  const [error, setError] = useState<string>();
  const toast = useToast();
  const queryClient = useQueryClient();

  const formCtx = useForm<AuthTokenExpireInString>({
    defaultValues,
  });
  const { reset } = formCtx;

  useEffect(() => {
    if (props.isOpen) {
      reset(defaultValues);
    }
  }, [props.isOpen, reset]);

  async function onRotateToken(form: AuthTokenExpireInString) {
    const config = await getApiConfiguration();
    const authApi = new AuthenticationApi(config);

    if (!props.apiKey) {
      return;
    }

    try {
      await authApi.expireApiTokenAuthenticationApiTokenKeyIdExpirePost(props.apiKey.id, {
        expiry: parseInt(form.expiry),
      });
      trackEvent("Expire API Key");
      toast({
        title: "Token expired",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      closeModal();
      queryClient.removeQueries(["environments", activeEnvId, "authToken"]);
    } catch (e) {
      setError(e.body?.detail || "An error occurred when expiring the token.");
    }
  }

  const closeModal = () => {
    props.onRotate();
    props.onClose();
  };

  return (
    <Modal onClose={closeModal} size="lg" isOpen={props.isOpen}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>Expire Token?</ModalHeader>
        <Form onSubmit={onRotateToken} {...formCtx}>
          <ModalBody>
            <ModalCloseButton />
            <Text fontSize="md">Are you sure you would like to expire this token?</Text>
            <Text fontSize="md" my={4}>
              Click <strong>Expire</strong> will invalidate the token at the requested
              expiration time. After the expiration time, every client using the old token
              will stop working.
            </Text>
            <Select label="Expiration" control={formCtx.control} name="expiry">
              {expirationOpts.map((opt) => (
                <option value={opt.value} key={opt.value}>
                  {opt.label}
                </option>
              ))}
            </Select>
            {error && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {error}
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" onClick={closeModal} mr={3}>
              Nevermind
            </Button>
            <SubmitButton colorScheme="red">Expire</SubmitButton>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}
