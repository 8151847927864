import { Route, Switch, Redirect, RouteProps, useLocation } from "react-router";

import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";

import { routeResolver } from "./App";
import AuthedChrome from "./components/AuthedChrome";
import AuthedContainer from "./components/AuthedContainer";
import { useAppSelector } from "./hooks/store";
import AdminStatsScreen from "./screens/AdminStats";
import AdminDetailsScreen from "./screens/AdminStats/AdminDetails";
import ApiAccessScreen from "./screens/ApiAccess";
import Application from "./screens/Application";
import ApplicationCreateScreen from "./screens/ApplicationCreate";
import Applications from "./screens/Applications";
import AuditLog from "./screens/AuditLog";
import AuthScreen from "./screens/Auth";
import AuthedNotFound from "./screens/AuthedNotFound";
import BillingScreen from "./screens/Billing";
import PaymentNewScreen from "./screens/Billing/PaymentNew";
import CliLoginScreen from "./screens/Cli/Login";
import Connectors from "./screens/Connectors";
import Environments from "./screens/Environments";
import EventTypeScreen from "./screens/EventType";
import EventTypeCreateScreen from "./screens/EventTypeCreate";
import EventTypesScreen from "./screens/EventTypes";
import EventTypesGenerateSDKScreen from "./screens/EventTypesGenerateSDK";
import EventTypesImportScreen from "./screens/EventTypesImport";
import GettingStartedScreen from "./screens/GettingStarted";
import InGettingStartedScreen from "./screens/In/GettingStarted";
import SourceScreen from "./screens/In/Source";
import SourceCreateScreen from "./screens/In/SourceCreate";
import SourcesScreen from "./screens/In/Sources";
import IntegrationsScreen from "./screens/Integrations";
import { DocumentationTemplate } from "./screens/Integrations/documentation/DocumentationTemplate";
import VercelConfigureScreen from "./screens/Integrations/vercel/Configure";
import VercelInstallScreen from "./screens/Integrations/vercel/Install";
import OrganizationInviteScreen from "./screens/OrganizationInvite";
import PaymentScreen from "./screens/Payment";
import PaymentCompleteScreen from "./screens/PaymentComplete";
import SettingsScreen from "./screens/Settings";
import EventCatalogSettings from "./screens/Settings/EventCatalogSettings";
import GeneralOrganizationSettings from "./screens/Settings/GeneralOrganizationSettings";
import OrganizationGroupSettings from "./screens/Settings/OrganizationGroupSettings";
import OrganizationMembers from "./screens/Settings/OrganizationMembers";
import MemberScreen from "./screens/Settings/OrganizationMembers/MemberScreen";
import SlackAppSettings from "./screens/Settings/SlackApp";
import SlackAppRegistration from "./screens/Settings/SlackApp/SlackAppRegistration";
import DisplayPreferences from "./screens/Settings/UserSettings/DisplayPreferences";
import UserManagement from "./screens/Settings/UserSettings/UserManagement";
import WhiteLabelSettings from "./screens/Settings/WhiteLabelSettings";
import { ConnectorCreateScreen } from "./screens/TransformationTemplate/ConnectorCreate";
import { ConnectorUpdateScreen } from "./screens/TransformationTemplate/ConnectorUpdate";
import UsageScreen from "./screens/Usage";
import WebhooksScreen from "./screens/Webhooks";
import { useSvixAuth } from "./utils";

export default function MainRouter() {
  return (
    <Switch>
      <PublicRoute
        path={[routeResolver.getRoute("login"), routeResolver.getRoute("signup")]}
      >
        <Route path={routeResolver.getRoute("signup")} exact>
          <AuthScreen isSignup />
        </Route>
        <Route path={routeResolver.getRoute("login")} exact>
          <AuthScreen />
        </Route>
        <Route path="/login/:auth0ConnectionName" exact>
          <AuthScreen />
        </Route>
      </PublicRoute>
      <PrivateRoute path="*">
        <AuthedContainer>
          <Switch>
            <Route path={routeResolver.getRoute("integrations.vercel.install")} exact>
              <VercelInstallScreen />
            </Route>

            <AuthedChrome>
              <Switch>
                <Route path={["/in", routeResolver.getRoute("in")]}>
                  <SvixInRouter />
                </Route>

                <SvixWebhooksRouter />
              </Switch>
            </AuthedChrome>
          </Switch>
        </AuthedContainer>
      </PrivateRoute>
    </Switch>
  );
}

function SvixWebhooksRouter() {
  const { isNewCustomer } = useAppSelector((app) => app.auth);

  return (
    <Switch>
      <Route path={routeResolver.getRoute("home")} exact>
        {isNewCustomer ? (
          <Redirect
            to={{
              pathname: routeResolver.getRoute("getting-started"),
              search: "signup-step=questionnaire",
            }}
          />
        ) : (
          <Redirect to={routeResolver.getRoute("applications")} />
        )}
      </Route>
      <Route path={routeResolver.getRoute("getting-started")} exact>
        <GettingStartedScreen />
      </Route>
      <Route path={routeResolver.getRoute("integrations")} exact>
        <IntegrationsScreen />
      </Route>
      <Route path={routeResolver.getRoute("integrations.vercel")} exact>
        <VercelConfigureScreen />
      </Route>
      <Route path={routeResolver.getRoute("invite")}>
        <OrganizationInviteScreen />
      </Route>
      <Route path={routeResolver.getRoute("documentation.generator")} exact>
        <DocumentationTemplate />
      </Route>
      <Route path={routeResolver.getRoute("applications.new")}>
        <ApplicationCreateScreen />
      </Route>
      <Route path={routeResolver.getRoute("applications._id")}>
        <Application />
      </Route>
      <Route path={routeResolver.getRoute("applications")}>
        <Applications />
      </Route>
      <Route path={routeResolver.getRoute("environments")}>
        <Environments />
      </Route>
      <Route path={routeResolver.getRoute("api-access")} exact>
        <ApiAccessScreen />
      </Route>
      <Route path={routeResolver.getRoute("billing.paymentComplete._planName")} exact>
        <PaymentCompleteScreen />
      </Route>
      <Route path={routeResolver.getRoute("billing.payment._planName")} exact>
        <PaymentScreen />
      </Route>
      <Route path={routeResolver.getRoute("billing.payment.new._planName")} exact>
        <PaymentNewScreen />
      </Route>
      <Route path={routeResolver.getRoute("billing")} exact>
        <BillingScreen />
      </Route>
      <Route path={routeResolver.getRoute("usage")} exact>
        <UsageScreen />
      </Route>
      <Route path={routeResolver.getRoute("usage.audit-log")} exact>
        <Redirect to={routeResolver.getRoute("security.audit-log")} />
      </Route>
      <Route path={routeResolver.getRoute("security.audit-log")} exact>
        <AuditLog />
      </Route>
      <Route path={routeResolver.getRoute("event-types")} exact>
        <EventTypesScreen />
      </Route>
      <Route path={routeResolver.getRoute("event-types.create.new")}>
        <EventTypeCreateScreen />
      </Route>
      <Route path={routeResolver.getRoute("event-types.create.import")}>
        <EventTypesImportScreen />
      </Route>
      <Route path={routeResolver.getRoute("event-types.create.generateSDK._taskId")}>
        <EventTypesGenerateSDKScreen />
      </Route>
      <Route path={routeResolver.getRoute("event-types._name")}>
        <EventTypeScreen />
      </Route>
      <Route path={routeResolver.getRoute("connectors")} exact>
        <Connectors />
      </Route>
      <Route path={routeResolver.getRoute("connectors.update._id")} exact>
        <ConnectorUpdateScreen />
      </Route>
      <Route path={routeResolver.getRoute("connectors.create.new")} exact>
        <ConnectorCreateScreen />
      </Route>
      <Route path={routeResolver.getRoute("webhooks")} exact>
        <WebhooksScreen />
      </Route>
      <Route path={routeResolver.getRoute("admin-stats")} exact>
        <AdminStatsScreen />
      </Route>
      <Route path={routeResolver.getRoute("admin-stats._orgid")}>
        <AdminDetailsScreen />
      </Route>
      <Route path={routeResolver.getRoute("settings")} exact>
        <SettingsScreen />
      </Route>
      <Route path={routeResolver.getRoute("settings.organizationGroup")} exact>
        <OrganizationGroupSettings />
      </Route>
      <Route path={routeResolver.getRoute("settings.organizationGroup.members")} exact>
        <OrganizationMembers />
      </Route>
      <Route
        path={routeResolver.getRoute("settings.organizationGroup.members._userId")}
        exact
      >
        <MemberScreen />
      </Route>
      <Route path={routeResolver.getRoute("settings.organization.whiteLabel")} exact>
        <WhiteLabelSettings />
      </Route>
      <Route path={routeResolver.getRoute("settings.organization.catalog")} exact>
        <EventCatalogSettings />
      </Route>
      <Route path={routeResolver.getRoute("settings.organization.generalSettings")} exact>
        <GeneralOrganizationSettings />
      </Route>
      <Route path={routeResolver.getRoute("settings.user.display")} exact>
        <DisplayPreferences />
      </Route>
      <Route path={routeResolver.getRoute("settings.slack")} exact>
        <SlackAppSettings />
      </Route>
      <Route path={routeResolver.getRoute("settings.slack.register")} exact>
        <SlackAppRegistration />
      </Route>
      <Route path={routeResolver.getRoute("settings.user.account")} exact>
        <UserManagement />
      </Route>
      <Route path={routeResolver.getRoute("cli.login")} exact>
        <CliLoginScreen />
      </Route>
      <Route path="*">
        <AuthedNotFound />
      </Route>
    </Switch>
  );
}

function SvixInRouter() {
  return (
    <Switch>
      <Route path={routeResolver.getRoute("in.getting-started")} exact>
        <InGettingStartedScreen />
      </Route>
      <Route path={routeResolver.getRoute("in")} exact>
        <SourcesScreen />
      </Route>
      <Route path={["/in", routeResolver.getRoute("in.sources")]} exact>
        <Redirect to={routeResolver.getRoute("in")} />
      </Route>
      <Route path={routeResolver.getRoute("in.sources.new")} exact>
        <SourceCreateScreen />
      </Route>
      <Route path={routeResolver.getRoute("in.sources._id")} exact>
        <SourceScreen />
      </Route>
      <Route path="*">
        <AuthedNotFound />
      </Route>
    </Switch>
  );
}

function PrivateRoute(props: Omit<RouteProps, "render">) {
  const { isLoading, isAuthenticated } = useSvixAuth();
  const { children, ...rest } = props;
  const location = useLocation();

  if (isLoading) {
    return <LoadingIndicator style={{ display: "block", margin: "40px auto" }} />;
  }

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: routeResolver.getRoute("login"),
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
}

function PublicRoute(props: Omit<RouteProps, "render">) {
  const { isLoading, isAuthenticated } = useSvixAuth();
  const { children, ...rest } = props;
  const location = useLocation();

  if (isAuthenticated && isLoading) {
    return <LoadingIndicator style={{ display: "block", margin: "40px auto" }} />;
  }

  return (
    <Route {...rest}>
      {isAuthenticated ? (
        <Redirect
          to={{
            pathname: routeResolver.getRoute("home"),
            state: { from: location },
          }}
        />
      ) : (
        children
      )}
    </Route>
  );
}
