import { useEffect } from "react";
import {
  Box,
  Collapse,
  Flex,
  ButtonGroup,
  Button,
  useToast,
  Tag,
  Divider,
  useBoolean,
} from "@chakra-ui/react";
import { SubmitHandler, useForm, UseFormReturn } from "react-hook-form";
import { useQueryClient } from "react-query";

import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import Toggle from "@svix/common/widgets/form/Toggle";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import {
  SourceOut,
  SourcesApi,
  IngestSourceIn,
  isRequiredSecretSource,
  isAdobeSignSource,
  SourceConfig,
  isOptionalSecretSource,
  SourceType,
} from "src/api/in";
import { useAppSelector } from "src/hooks/store";
import SourceTypeSelect from "./SourceTypeSelect";

export interface ISourceConfigurationForm {
  type: SourceType;
  enableAuth: boolean;
  secret: string | undefined;
  clientId: string | undefined;
}

export default function SourceConfiguration({ source }: { source: SourceOut }) {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;

  const queryClient = useQueryClient();
  const defaultValues = toDefaultValues(source);
  const toast = useToast();
  const formCtx = useForm<ISourceConfigurationForm>({
    defaultValues,
  });

  async function onSave(form: ISourceConfigurationForm) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);

    const updatedSource = {
      ...source,
      type: form.type,
      config: sourceAuthConfigFromForm(form),
    } as IngestSourceIn;

    try {
      await api.update(source.id, updatedSource);
      toast({
        title: "Configuration updated",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Error updating source",
        status: "error",
      });
    }
    formCtx.reset({}, { keepValues: true });
    queryClient.invalidateQueries([
      "environments",
      activeEnvId,
      "ingest",
      "sources",
      source.id,
    ]);
  }

  return (
    <Card title="Configuration">
      <SourceConfigurationForm
        formCtx={formCtx}
        onSubmit={onSave}
        onCancel={() => formCtx.reset(defaultValues)}
      />
    </Card>
  );
}

export interface SourceConfigurationForm {
  type: string;
  enableAuth: boolean;
  secret: string | undefined;
  clientId: string | undefined;
}

function toDefaultValues(source: SourceOut): ISourceConfigurationForm {
  return {
    type: source.type,
    enableAuth: false,
    secret: undefined,
    clientId: undefined,
  };
}

export function sourceAuthConfigFromForm(
  form: ISourceConfigurationForm
): SourceConfig["config"] {
  if (isRequiredSecretSource(form.type)) {
    return { secret: form.secret! };
  }

  if (isOptionalSecretSource(form.type)) {
    return { secret: form.enableAuth ? form.secret : undefined };
  }

  if (isAdobeSignSource(form.type)) {
    return { clientId: form.clientId! };
  }

  return undefined;
}

interface SourceConfigurationFormProps {
  formCtx: UseFormReturn<ISourceConfigurationForm>;
  onSubmit: SubmitHandler<ISourceConfigurationForm>;
  onCancel?: () => void;
  showActions?: boolean;
  cancelLabel?: string;
  isNew?: boolean;
}

export function SourceConfigurationForm({
  formCtx,
  onSubmit,
  onCancel,
  isNew = false,
  cancelLabel = "Cancel",
}: SourceConfigurationFormProps) {
  const { watch } = formCtx;
  const type = watch("type");
  const isSecretRequired =
    isRequiredSecretSource(type) ||
    isAdobeSignSource(type) ||
    (isOptionalSecretSource(type) && watch("enableAuth"));

  const [showUpdateSecret, setShowUpdateSecret] = useBoolean(isNew);

  const onCancelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowUpdateSecret.off();
    formCtx.reset({}, { keepDefaultValues: true });
    onCancel?.();
  };

  useEffect(() => {
    if (formCtx.formState.isDirty) {
      setShowUpdateSecret.on();
    }
  }, [setShowUpdateSecret, formCtx.formState.isDirty]);

  return (
    <Form onSubmit={onSubmit} {...formCtx} shouldPromptOnDirty={false}>
      <SourceTypeSelect control={formCtx.control} name="type" />
      <GeneralFormErrors />
      <Button
        size="sm"
        onClick={() => setShowUpdateSecret.on()}
        variant="outline"
        mt={4}
        hidden={showUpdateSecret}
      >
        Update secret
      </Button>
      <Collapse in={showUpdateSecret}>
        <Collapse in={isOptionalSecretSource(type)}>
          <Box>
            <Divider my={4} />
            <Toggle
              label={
                <>
                  <span>Enable authentication</span>
                  <Tag colorScheme="green" ml={2}>
                    Recommended
                  </Tag>
                </>
              }
              helpText="To validate the authenticity of the incoming events."
              control={formCtx.control}
              name="enableAuth"
            />
          </Box>
        </Collapse>
        <Collapse in={isSecretRequired}>
          <Box mt={4}>
            {isAdobeSignSource(type) ? (
              <TextField
                label="Client ID"
                control={formCtx.control}
                name="clientId"
                type="password"
                required={isSecretRequired}
                helperText="The client ID to validate incoming events."
              />
            ) : (
              <TextField
                label="Secret"
                control={formCtx.control}
                name="secret"
                type="password"
                required={isSecretRequired}
                helperText="The signing secret to validate incoming events."
              />
            )}
          </Box>
        </Collapse>
      </Collapse>
      {(isNew || formCtx.formState.isDirty) && (
        <Box mt={4}>
          <Flex justifyContent="flex-end" mt={4}>
            <ButtonGroup>
              {!isNew && (
                <Button variant="outline" onClick={onCancelClick}>
                  {cancelLabel}
                </Button>
              )}
              <SubmitButton isLoading={formCtx.formState.isSubmitting}>Save</SubmitButton>
            </ButtonGroup>
          </Flex>
        </Box>
      )}
    </Form>
  );
}
