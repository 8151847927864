import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import * as models from '../models/all';
import { Configuration} from '../configuration'
import { Observable, of, from } from '../rxjsStub';
import {mergeMap, map} from  '../rxjsStub';
import { ApplicationCountResponse } from '../models/ApplicationCountResponse';
import { ApplicationEventTypesResponse } from '../models/ApplicationEventTypesResponse';
import { ApproveCliLoginIn } from '../models/ApproveCliLoginIn';
import { ApproveCliLoginOut } from '../models/ApproveCliLoginOut';
import { AuditLogRecord } from '../models/AuditLogRecord';
import { AuthTokenCensoredOut } from '../models/AuthTokenCensoredOut';
import { AuthTokenExpireIn } from '../models/AuthTokenExpireIn';
import { AuthTokenIn } from '../models/AuthTokenIn';
import { AuthTokenOut } from '../models/AuthTokenOut';
import { AuthTokenUpdateIn } from '../models/AuthTokenUpdateIn';
import { Authenticator } from '../models/Authenticator';
import { BillingAlertsIn } from '../models/BillingAlertsIn';
import { BillingAlertsOut } from '../models/BillingAlertsOut';
import { BillingOut } from '../models/BillingOut';
import { BorderRadiusConfig } from '../models/BorderRadiusConfig';
import { BorderRadiusEnum } from '../models/BorderRadiusEnum';
import { CustomColorPalette } from '../models/CustomColorPalette';
import { CustomStringsOverride } from '../models/CustomStringsOverride';
import { CustomThemeOverride } from '../models/CustomThemeOverride';
import { DashboardAccessOut } from '../models/DashboardAccessOut';
import { DashboardAuditLog } from '../models/DashboardAuditLog';
import { EndpointCountResponse } from '../models/EndpointCountResponse';
import { EventCatalogOut } from '../models/EventCatalogOut';
import { EventTypeOut } from '../models/EventTypeOut';
import { Feature } from '../models/Feature';
import { FindInviteByTokenIn } from '../models/FindInviteByTokenIn';
import { FontSizeConfig } from '../models/FontSizeConfig';
import { GenericCollectionListOut } from '../models/GenericCollectionListOut';
import { GenericCollectionOut } from '../models/GenericCollectionOut';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { HttpErrorOut } from '../models/HttpErrorOut';
import { InviteIn } from '../models/InviteIn';
import { InviteOut } from '../models/InviteOut';
import { InviteResponseIn } from '../models/InviteResponseIn';
import { InvoiceLineOut } from '../models/InvoiceLineOut';
import { ListResponseAuthTokenCensoredOut } from '../models/ListResponseAuthTokenCensoredOut';
import { ListResponseInviteOut } from '../models/ListResponseInviteOut';
import { ListResponseOrganizationOut } from '../models/ListResponseOrganizationOut';
import { ListResponseOrganizationOutEE } from '../models/ListResponseOrganizationOutEE';
import { ListResponseOrganizationRoleOverride } from '../models/ListResponseOrganizationRoleOverride';
import { ListResponseOrganizationUserOut } from '../models/ListResponseOrganizationUserOut';
import { ListResponsePaymentMethodOut } from '../models/ListResponsePaymentMethodOut';
import { ListResponseSearchResultOut } from '../models/ListResponseSearchResultOut';
import { ListResponseVercelProjectOut } from '../models/ListResponseVercelProjectOut';
import { LoginFinishedIn } from '../models/LoginFinishedIn';
import { LoginFinishedOut } from '../models/LoginFinishedOut';
import { MfaEnrollment } from '../models/MfaEnrollment';
import { MfaValidation } from '../models/MfaValidation';
import { OrganizationAndRole } from '../models/OrganizationAndRole';
import { OrganizationEnvironment } from '../models/OrganizationEnvironment';
import { OrganizationGroupRow } from '../models/OrganizationGroupRow';
import { OrganizationGroupSettingUpdate } from '../models/OrganizationGroupSettingUpdate';
import { OrganizationGroupSettingsOut } from '../models/OrganizationGroupSettingsOut';
import { OrganizationIdAndEnvironment } from '../models/OrganizationIdAndEnvironment';
import { OrganizationIn } from '../models/OrganizationIn';
import { OrganizationInviteStatus } from '../models/OrganizationInviteStatus';
import { OrganizationMessageStatisticsData } from '../models/OrganizationMessageStatisticsData';
import { OrganizationMessageStatisticsResponse } from '../models/OrganizationMessageStatisticsResponse';
import { OrganizationOut } from '../models/OrganizationOut';
import { OrganizationOutEE } from '../models/OrganizationOutEE';
import { OrganizationRegion } from '../models/OrganizationRegion';
import { OrganizationRole } from '../models/OrganizationRole';
import { OrganizationRoleOverride } from '../models/OrganizationRoleOverride';
import { OrganizationRoleUpdate } from '../models/OrganizationRoleUpdate';
import { OrganizationUpdate } from '../models/OrganizationUpdate';
import { OrganizationUser } from '../models/OrganizationUser';
import { OrganizationUserOut } from '../models/OrganizationUserOut';
import { OtelConfig } from '../models/OtelConfig';
import { PaymentMethodOut } from '../models/PaymentMethodOut';
import { QuestionnaireIn } from '../models/QuestionnaireIn';
import { RecoveryCode } from '../models/RecoveryCode';
import { RecoveryCodeIn } from '../models/RecoveryCodeIn';
import { SearchQueryIn } from '../models/SearchQueryIn';
import { SearchResultOut } from '../models/SearchResultOut';
import { SettingsInternalIn } from '../models/SettingsInternalIn';
import { SettingsInternalOut } from '../models/SettingsInternalOut';
import { SlackAppOut } from '../models/SlackAppOut';
import { SlackAppRegisterIn } from '../models/SlackAppRegisterIn';
import { StatisticsPeriod } from '../models/StatisticsPeriod';
import { StatsOut } from '../models/StatsOut';
import { StripeCoupon } from '../models/StripeCoupon';
import { StripeSetupIntentOut } from '../models/StripeSetupIntentOut';
import { SubscriptionEnum } from '../models/SubscriptionEnum';
import { SubscriptionOut } from '../models/SubscriptionOut';
import { SubscriptionPatchIn } from '../models/SubscriptionPatchIn';
import { UserMetadata } from '../models/UserMetadata';
import { ValidationError } from '../models/ValidationError';
import { VercelEnabledOut } from '../models/VercelEnabledOut';
import { VercelEnvVarsIn } from '../models/VercelEnvVarsIn';
import { VercelProjectOut } from '../models/VercelProjectOut';
import { VerificationCodeIn } from '../models/VerificationCodeIn';
import { ZapierIntegrationPackageOut } from '../models/ZapierIntegrationPackageOut';

import { AdminApiRequestFactory, AdminApiResponseProcessor} from "../apis/AdminApi";
export class ObservableAdminApi {
    private requestFactory: AdminApiRequestFactory;
    private responseProcessor: AdminApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AdminApiRequestFactory,
        responseProcessor?: AdminApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AdminApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AdminApiResponseProcessor();
    }

    /**
     * Get Admin Org Messages Stats
     * @param orgId 
     * @param startDate 
     * @param endDate 
     */
    public getAdminOrgMessagesStatsAdminStatsOrgMessagesGet(orgId: string, startDate?: Date, endDate?: Date, options?: Configuration): Observable<OrganizationMessageStatisticsResponse> {
        const requestContextPromise = this.requestFactory.getAdminOrgMessagesStatsAdminStatsOrgMessagesGet(orgId, startDate, endDate, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAdminOrgMessagesStatsAdminStatsOrgMessagesGet(rsp)));
            }));
    }
 
    /**
     * Admin only way of fetching items of a generic-collection
     * Get Generic Collections
     * @param type 
     * @param limit 
     */
    public getGenericCollectionsAdminGenericCollectionTypeGet(type: string, limit?: number, options?: Configuration): Observable<GenericCollectionListOut> {
        const requestContextPromise = this.requestFactory.getGenericCollectionsAdminGenericCollectionTypeGet(type, limit, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getGenericCollectionsAdminGenericCollectionTypeGet(rsp)));
            }));
    }
 
    /**
     * Use for internal stats
     * Stats
     */
    public statsAdminStatsGet(options?: Configuration): Observable<StatsOut> {
        const requestContextPromise = this.requestFactory.statsAdminStatsGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.statsAdminStatsGet(rsp)));
            }));
    }
 
}

import { AuditLogApiRequestFactory, AuditLogApiResponseProcessor} from "../apis/AuditLogApi";
export class ObservableAuditLogApi {
    private requestFactory: AuditLogApiRequestFactory;
    private responseProcessor: AuditLogApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AuditLogApiRequestFactory,
        responseProcessor?: AuditLogApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AuditLogApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AuditLogApiResponseProcessor();
    }

    /**
     * Get the audit log for an organization
     * Get Dashboard Audit Log
     * @param orgId 
     * @param before 
     * @param after 
     * @param iterator 
     * @param limit 
     */
    public getDashboardAuditLogAuditLogOrgIdGet(orgId: string, before?: Date, after?: Date, iterator?: string, limit?: number, options?: Configuration): Observable<DashboardAuditLog> {
        const requestContextPromise = this.requestFactory.getDashboardAuditLogAuditLogOrgIdGet(orgId, before, after, iterator, limit, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getDashboardAuditLogAuditLogOrgIdGet(rsp)));
            }));
    }
 
}

import { AuthenticationApiRequestFactory, AuthenticationApiResponseProcessor} from "../apis/AuthenticationApi";
export class ObservableAuthenticationApi {
    private requestFactory: AuthenticationApiRequestFactory;
    private responseProcessor: AuthenticationApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: AuthenticationApiRequestFactory,
        responseProcessor?: AuthenticationApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new AuthenticationApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new AuthenticationApiResponseProcessor();
    }

    /**
     * Change Password
     */
    public changePasswordAuthenticationResetPasswordPost(options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.changePasswordAuthenticationResetPasswordPost(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.changePasswordAuthenticationResetPasswordPost(rsp)));
            }));
    }
 
    /**
     * Create a new API Token
     * Create Api Token
     * @param authTokenIn 
     */
    public createApiTokenAuthenticationApiTokenPost(authTokenIn: AuthTokenIn, options?: Configuration): Observable<AuthTokenOut> {
        const requestContextPromise = this.requestFactory.createApiTokenAuthenticationApiTokenPost(authTokenIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createApiTokenAuthenticationApiTokenPost(rsp)));
            }));
    }
 
    /**
     * Mark user as inactive. If the user is the last Admin on the org group, mark all orgs and org group as deleted and all other users as inactive
     * Delete User
     */
    public deleteUserAuthenticationDeleteUserPost(options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteUserAuthenticationDeleteUserPost(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteUserAuthenticationDeleteUserPost(rsp)));
            }));
    }
 
    /**
     * Expire the selected auth token
     * Expire Api Token
     * @param keyId 
     * @param authTokenExpireIn 
     */
    public expireApiTokenAuthenticationApiTokenKeyIdExpirePost(keyId: string, authTokenExpireIn: AuthTokenExpireIn, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.expireApiTokenAuthenticationApiTokenKeyIdExpirePost(keyId, authTokenExpireIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.expireApiTokenAuthenticationApiTokenKeyIdExpirePost(rsp)));
            }));
    }
 
    /**
     * Get Dashboard
     */
    public getDashboardAuthenticationGetDashboardGet(options?: Configuration): Observable<DashboardAccessOut> {
        const requestContextPromise = this.requestFactory.getDashboardAuthenticationGetDashboardGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getDashboardAuthenticationGetDashboardGet(rsp)));
            }));
    }
 
    /**
     * Get Metadata
     */
    public getMetadataAuthenticationMetadataGet(options?: Configuration): Observable<UserMetadata> {
        const requestContextPromise = this.requestFactory.getMetadataAuthenticationMetadataGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getMetadataAuthenticationMetadataGet(rsp)));
            }));
    }
 
    /**
     * Setup a user account or retrieve existing one. This method should be called right after login / signup
     * Get Or Create User
     * @param loginFinishedIn 
     */
    public getOrCreateUserAuthenticationLoginFinishedPost(loginFinishedIn: LoginFinishedIn, options?: Configuration): Observable<LoginFinishedOut> {
        const requestContextPromise = this.requestFactory.getOrCreateUserAuthenticationLoginFinishedPost(loginFinishedIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getOrCreateUserAuthenticationLoginFinishedPost(rsp)));
            }));
    }
 
    /**
     * List all API tokens
     * List Api Tokens
     * @param iterator 
     * @param limit 
     */
    public listApiTokensAuthenticationApiTokenGet(iterator?: string, limit?: number, options?: Configuration): Observable<ListResponseAuthTokenCensoredOut> {
        const requestContextPromise = this.requestFactory.listApiTokensAuthenticationApiTokenGet(iterator, limit, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.listApiTokensAuthenticationApiTokenGet(rsp)));
            }));
    }
 
    /**
     * Resend Verification Email
     */
    public resendVerificationEmailAuthenticationResendVerificationEmailPost(options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.resendVerificationEmailAuthenticationResendVerificationEmailPost(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.resendVerificationEmailAuthenticationResendVerificationEmailPost(rsp)));
            }));
    }
 
    /**
     * Set Welcome Questionnaire
     * @param questionnaireIn 
     */
    public setWelcomeQuestionnaireAuthenticationWelcomeQuestionnairePost(questionnaireIn: QuestionnaireIn, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.setWelcomeQuestionnaireAuthenticationWelcomeQuestionnairePost(questionnaireIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.setWelcomeQuestionnaireAuthenticationWelcomeQuestionnairePost(rsp)));
            }));
    }
 
    /**
     * Update the name of the selected auth token
     * Update Api Token
     * @param keyId 
     * @param authTokenUpdateIn 
     */
    public updateApiTokenAuthenticationApiTokenKeyIdPut(keyId: string, authTokenUpdateIn: AuthTokenUpdateIn, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.updateApiTokenAuthenticationApiTokenKeyIdPut(keyId, authTokenUpdateIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateApiTokenAuthenticationApiTokenKeyIdPut(rsp)));
            }));
    }
 
    /**
     * Update Metadata
     * @param userMetadata 
     */
    public updateMetadataAuthenticationMetadataPatch(userMetadata: UserMetadata, options?: Configuration): Observable<UserMetadata> {
        const requestContextPromise = this.requestFactory.updateMetadataAuthenticationMetadataPatch(userMetadata, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateMetadataAuthenticationMetadataPatch(rsp)));
            }));
    }
 
}

import { BillingApiRequestFactory, BillingApiResponseProcessor} from "../apis/BillingApi";
export class ObservableBillingApi {
    private requestFactory: BillingApiRequestFactory;
    private responseProcessor: BillingApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: BillingApiRequestFactory,
        responseProcessor?: BillingApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new BillingApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new BillingApiResponseProcessor();
    }

    /**
     * Change a subscription back to the Starter plan
     * Change Subscription
     * @param subscriptionPatchIn 
     * @param setupIntentId 
     */
    public changeSubscriptionBillingSubscriptionPatch(subscriptionPatchIn: SubscriptionPatchIn, setupIntentId?: string, options?: Configuration): Observable<SubscriptionOut> {
        const requestContextPromise = this.requestFactory.changeSubscriptionBillingSubscriptionPatch(subscriptionPatchIn, setupIntentId, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.changeSubscriptionBillingSubscriptionPatch(rsp)));
            }));
    }
 
    /**
     * Get the billing alerts for the current user's organization group
     * Get Billing Alerts
     */
    public getBillingAlertsBillingAlertsGet(options?: Configuration): Observable<BillingAlertsOut> {
        const requestContextPromise = this.requestFactory.getBillingAlertsBillingAlertsGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getBillingAlertsBillingAlertsGet(rsp)));
            }));
    }
 
    /**
     * Get the subscription details for the current user
     * Get Payment Methods
     */
    public getPaymentMethodsBillingPaymentMethodsGet(options?: Configuration): Observable<ListResponsePaymentMethodOut> {
        const requestContextPromise = this.requestFactory.getPaymentMethodsBillingPaymentMethodsGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getPaymentMethodsBillingPaymentMethodsGet(rsp)));
            }));
    }
 
    /**
     * Get the stripe billing dashboard for the user
     * Get Stripe Dashboard Link
     */
    public getStripeDashboardLinkBillingGet(options?: Configuration): Observable<BillingOut> {
        const requestContextPromise = this.requestFactory.getStripeDashboardLinkBillingGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getStripeDashboardLinkBillingGet(rsp)));
            }));
    }
 
    /**
     * Get the subscription details for the current user
     * Get Subscription Details
     */
    public getSubscriptionDetailsBillingSubscriptionGet(options?: Configuration): Observable<SubscriptionOut> {
        const requestContextPromise = this.requestFactory.getSubscriptionDetailsBillingSubscriptionGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getSubscriptionDetailsBillingSubscriptionGet(rsp)));
            }));
    }
 
    /**
     * Create a SetupIntent
     * Start Billing Flow
     */
    public startBillingFlowBillingIntentPost(options?: Configuration): Observable<StripeSetupIntentOut> {
        const requestContextPromise = this.requestFactory.startBillingFlowBillingIntentPost(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.startBillingFlowBillingIntentPost(rsp)));
            }));
    }
 
    /**
     * Update the billing alerts for the current user's organization group
     * Update Billing Alerts
     * @param billingAlertsIn 
     */
    public updateBillingAlertsBillingAlertsPatch(billingAlertsIn: BillingAlertsIn, options?: Configuration): Observable<BillingAlertsOut> {
        const requestContextPromise = this.requestFactory.updateBillingAlertsBillingAlertsPatch(billingAlertsIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateBillingAlertsBillingAlertsPatch(rsp)));
            }));
    }
 
}

import { CLIApiRequestFactory, CLIApiResponseProcessor} from "../apis/CLIApi";
export class ObservableCLIApi {
    private requestFactory: CLIApiRequestFactory;
    private responseProcessor: CLIApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: CLIApiRequestFactory,
        responseProcessor?: CLIApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new CLIApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new CLIApiResponseProcessor();
    }

    /**
     * Approve a CLI login session.
     * Approve Cli Login
     * @param approveCliLoginIn 
     */
    public approveCliLoginCliLoginSessionApprovePost(approveCliLoginIn: ApproveCliLoginIn, options?: Configuration): Observable<ApproveCliLoginOut> {
        const requestContextPromise = this.requestFactory.approveCliLoginCliLoginSessionApprovePost(approveCliLoginIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.approveCliLoginCliLoginSessionApprovePost(rsp)));
            }));
    }
 
}

import { EventCatalogApiRequestFactory, EventCatalogApiResponseProcessor} from "../apis/EventCatalogApi";
export class ObservableEventCatalogApi {
    private requestFactory: EventCatalogApiRequestFactory;
    private responseProcessor: EventCatalogApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: EventCatalogApiRequestFactory,
        responseProcessor?: EventCatalogApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new EventCatalogApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new EventCatalogApiResponseProcessor();
    }

    /**
     * Return the list of event types.
     * List Event Types
     * @param orgId 
     * @param iterator 
     * @param limit 
     */
    public listEventTypesEventTypesOrgIdGet(orgId: string, iterator?: string, limit?: number, options?: Configuration): Observable<EventCatalogOut> {
        const requestContextPromise = this.requestFactory.listEventTypesEventTypesOrgIdGet(orgId, iterator, limit, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.listEventTypesEventTypesOrgIdGet(rsp)));
            }));
    }
 
}

import { IntegrationApiRequestFactory, IntegrationApiResponseProcessor} from "../apis/IntegrationApi";
export class ObservableIntegrationApi {
    private requestFactory: IntegrationApiRequestFactory;
    private responseProcessor: IntegrationApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: IntegrationApiRequestFactory,
        responseProcessor?: IntegrationApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new IntegrationApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new IntegrationApiResponseProcessor();
    }

    /**
     * Build Zapier Integration Package
     */
    public buildZapierIntegrationPackageIntegrationsClientZapierPost(options?: Configuration): Observable<ZapierIntegrationPackageOut> {
        const requestContextPromise = this.requestFactory.buildZapierIntegrationPackageIntegrationsClientZapierPost(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.buildZapierIntegrationPackageIntegrationsClientZapierPost(rsp)));
            }));
    }
 
    /**
     * Check if the Vercel integration is enabled for the authenticated account
     * Check Vercel Integration
     */
    public checkVercelIntegrationIntegrationsVercelGet(options?: Configuration): Observable<VercelEnabledOut> {
        const requestContextPromise = this.requestFactory.checkVercelIntegrationIntegrationsVercelGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.checkVercelIntegrationIntegrationsVercelGet(rsp)));
            }));
    }
 
    /**
     * Get a list of Vercel projects connected to the authenticated account
     * Get Vercel Projects
     * @param limit 
     * @param fromStamp 
     */
    public getVercelProjectsIntegrationsVercelProjectGet(limit?: number, fromStamp?: string, options?: Configuration): Observable<ListResponseVercelProjectOut> {
        const requestContextPromise = this.requestFactory.getVercelProjectsIntegrationsVercelProjectGet(limit, fromStamp, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getVercelProjectsIntegrationsVercelProjectGet(rsp)));
            }));
    }
 
    /**
     * Map a Vercel Project to a Svix Environment
     * Map Vercel Project To Svix Environment
     * @param projectId 
     * @param orgId 
     */
    public mapVercelProjectToSvixEnvironmentIntegrationsVercelProjectProjectIdMapToOrgIdPut(projectId: string, orgId: string, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.mapVercelProjectToSvixEnvironmentIntegrationsVercelProjectProjectIdMapToOrgIdPut(projectId, orgId, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.mapVercelProjectToSvixEnvironmentIntegrationsVercelProjectProjectIdMapToOrgIdPut(rsp)));
            }));
    }
 
    /**
     * Persist the Vercel access token to the authenticated account
     * Set Vercel Token
     * @param vercelEnvVarsIn 
     */
    public setVercelTokenIntegrationsVercelPost(vercelEnvVarsIn: VercelEnvVarsIn, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.setVercelTokenIntegrationsVercelPost(vercelEnvVarsIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.setVercelTokenIntegrationsVercelPost(rsp)));
            }));
    }
 
}

import { InvitationApiRequestFactory, InvitationApiResponseProcessor} from "../apis/InvitationApi";
export class ObservableInvitationApi {
    private requestFactory: InvitationApiRequestFactory;
    private responseProcessor: InvitationApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: InvitationApiRequestFactory,
        responseProcessor?: InvitationApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new InvitationApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new InvitationApiResponseProcessor();
    }

    /**
     * Invite a user (email address) to an organization
     * Create Invite
     * @param inviteIn 
     */
    public createInviteInvitePost(inviteIn: InviteIn, options?: Configuration): Observable<InviteOut> {
        const requestContextPromise = this.requestFactory.createInviteInvitePost(inviteIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createInviteInvitePost(rsp)));
            }));
    }
 
    /**
     * Delete an invite
     * Delete Invite
     * @param orgGroupInviteId 
     */
    public deleteInviteInviteOrgGroupInviteIdDelete(orgGroupInviteId: string, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteInviteInviteOrgGroupInviteIdDelete(orgGroupInviteId, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteInviteInviteOrgGroupInviteIdDelete(rsp)));
            }));
    }
 
    /**
     * Find Invite By Token
     * @param findInviteByTokenIn 
     */
    public findInviteByTokenInviteFindPost(findInviteByTokenIn: FindInviteByTokenIn, options?: Configuration): Observable<InviteOut> {
        const requestContextPromise = this.requestFactory.findInviteByTokenInviteFindPost(findInviteByTokenIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.findInviteByTokenInviteFindPost(rsp)));
            }));
    }
 
    /**
     * Get the invite items of the organization group the user is authenticated for (The users that are invited to the current organization group)
     * List Org Group Invites
     * @param iterator 
     * @param limit 
     * @param inviteStatus 
     */
    public listOrgGroupInvitesInviteOrgGroupGet(iterator?: string, limit?: number, inviteStatus?: OrganizationInviteStatus, options?: Configuration): Observable<ListResponseInviteOut> {
        const requestContextPromise = this.requestFactory.listOrgGroupInvitesInviteOrgGroupGet(iterator, limit, inviteStatus, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.listOrgGroupInvitesInviteOrgGroupGet(rsp)));
            }));
    }
 
    /**
     * Respond to an invite
     * Respond To Invite
     * @param orgGroupInviteId 
     * @param inviteResponseIn 
     */
    public respondToInviteInviteRespondOrgGroupInviteIdPost(orgGroupInviteId: string, inviteResponseIn: InviteResponseIn, options?: Configuration): Observable<InviteOut> {
        const requestContextPromise = this.requestFactory.respondToInviteInviteRespondOrgGroupInviteIdPost(orgGroupInviteId, inviteResponseIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.respondToInviteInviteRespondOrgGroupInviteIdPost(rsp)));
            }));
    }
 
}

import { MultifactorAuthenticationApiRequestFactory, MultifactorAuthenticationApiResponseProcessor} from "../apis/MultifactorAuthenticationApi";
export class ObservableMultifactorAuthenticationApi {
    private requestFactory: MultifactorAuthenticationApiRequestFactory;
    private responseProcessor: MultifactorAuthenticationApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: MultifactorAuthenticationApiRequestFactory,
        responseProcessor?: MultifactorAuthenticationApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new MultifactorAuthenticationApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new MultifactorAuthenticationApiResponseProcessor();
    }

    /**
     * Confirm Enroll Mfa
     * @param verificationCodeIn 
     */
    public confirmEnrollMfaAuthenticationMfaVerifyPost(verificationCodeIn: VerificationCodeIn, options?: Configuration): Observable<MfaValidation> {
        const requestContextPromise = this.requestFactory.confirmEnrollMfaAuthenticationMfaVerifyPost(verificationCodeIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.confirmEnrollMfaAuthenticationMfaVerifyPost(rsp)));
            }));
    }
 
    /**
     * Enroll Mfa
     */
    public enrollMfaAuthenticationMfaAddPost(options?: Configuration): Observable<MfaEnrollment> {
        const requestContextPromise = this.requestFactory.enrollMfaAuthenticationMfaAddPost(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.enrollMfaAuthenticationMfaAddPost(rsp)));
            }));
    }
 
    /**
     * Get Authenticators
     */
    public getAuthenticatorsAuthenticationMfaGet(options?: Configuration): Observable<Array<Authenticator>> {
        const requestContextPromise = this.requestFactory.getAuthenticatorsAuthenticationMfaGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAuthenticatorsAuthenticationMfaGet(rsp)));
            }));
    }
 
    /**
     * Opt Out Mfa
     * @param verificationCodeIn 
     */
    public optOutMfaAuthenticationMfaRemovePost(verificationCodeIn: VerificationCodeIn, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.optOutMfaAuthenticationMfaRemovePost(verificationCodeIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.optOutMfaAuthenticationMfaRemovePost(rsp)));
            }));
    }
 
    /**
     * Verify the recovery code provided and generate a new recovery code to be returned to the user. Referred to as \"recovery code challenge\" based on auth0 documentation: https://auth0.com/docs/mfa/authenticate-with-ropg-and-mfa/manage-authenticator-factors-mfa-api/challenge-with-recovery-codes
     * Recovery Code Challenge
     * @param recoveryCodeIn 
     */
    public recoveryCodeChallengeAuthenticationMfaRecoveryCodePost(recoveryCodeIn: RecoveryCodeIn, options?: Configuration): Observable<RecoveryCode> {
        const requestContextPromise = this.requestFactory.recoveryCodeChallengeAuthenticationMfaRecoveryCodePost(recoveryCodeIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.recoveryCodeChallengeAuthenticationMfaRecoveryCodePost(rsp)));
            }));
    }
 
}

import { OrganizationApiRequestFactory, OrganizationApiResponseProcessor} from "../apis/OrganizationApi";
export class ObservableOrganizationApi {
    private requestFactory: OrganizationApiRequestFactory;
    private responseProcessor: OrganizationApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: OrganizationApiRequestFactory,
        responseProcessor?: OrganizationApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new OrganizationApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new OrganizationApiResponseProcessor();
    }

    /**
     * Update the user's org group role
     * Change Role
     * @param userId 
     * @param organizationRoleUpdate 
     */
    public changeRoleOrganizationRoleUserIdPost(userId: string, organizationRoleUpdate: OrganizationRoleUpdate, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.changeRoleOrganizationRoleUserIdPost(userId, organizationRoleUpdate, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.changeRoleOrganizationRoleUserIdPost(rsp)));
            }));
    }
 
    /**
     * Create Org
     * @param organizationIn 
     */
    public createOrgOrganizationPost(organizationIn: OrganizationIn, options?: Configuration): Observable<OrganizationOut> {
        const requestContextPromise = this.requestFactory.createOrgOrganizationPost(organizationIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createOrgOrganizationPost(rsp)));
            }));
    }
 
    /**
     * Delete Org
     * @param orgId 
     */
    public deleteOrgOrganizationOrgIdDelete(orgId: string, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteOrgOrganizationOrgIdDelete(orgId, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteOrgOrganizationOrgIdDelete(rsp)));
            }));
    }
 
    /**
     * Update the user's org group role
     * Delete Override
     * @param envId 
     * @param userId 
     */
    public deleteOverrideOrganizationRoleUserIdOverridesEnvIdDelete(envId: string, userId: string, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteOverrideOrganizationRoleUserIdOverridesEnvIdDelete(envId, userId, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteOverrideOrganizationRoleUserIdOverridesEnvIdDelete(rsp)));
            }));
    }
 
    /**
     * Get User
     * @param userId 
     */
    public getUserOrganizationUsersUserIdGet(userId: string, options?: Configuration): Observable<OrganizationUser> {
        const requestContextPromise = this.requestFactory.getUserOrganizationUsersUserIdGet(userId, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getUserOrganizationUsersUserIdGet(rsp)));
            }));
    }
 
    /**
     * List Orgs
     */
    public listOrgsOrganizationGet(options?: Configuration): Observable<ListResponseOrganizationOut> {
        const requestContextPromise = this.requestFactory.listOrgsOrganizationGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.listOrgsOrganizationGet(rsp)));
            }));
    }
 
    /**
     * Get the user's org role overrides
     * List Overrides
     * @param userId 
     */
    public listOverridesOrganizationRoleUserIdOverridesGet(userId: string, options?: Configuration): Observable<ListResponseOrganizationRoleOverride> {
        const requestContextPromise = this.requestFactory.listOverridesOrganizationRoleUserIdOverridesGet(userId, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.listOverridesOrganizationRoleUserIdOverridesGet(rsp)));
            }));
    }
 
    /**
     * List Users
     */
    public listUsersOrganizationUsersGet(options?: Configuration): Observable<ListResponseOrganizationUserOut> {
        const requestContextPromise = this.requestFactory.listUsersOrganizationUsersGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.listUsersOrganizationUsersGet(rsp)));
            }));
    }
 
    /**
     * Remove user from the current org group
     * Revoke Access
     * @param userId 
     */
    public revokeAccessOrganizationUsersRevokeAccessUserIdPost(userId: string, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.revokeAccessOrganizationUsersRevokeAccessUserIdPost(userId, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.revokeAccessOrganizationUsersRevokeAccessUserIdPost(rsp)));
            }));
    }
 
    /**
     * Update Org
     * @param orgId 
     * @param organizationUpdate 
     */
    public updateOrgOrganizationOrgIdPut(orgId: string, organizationUpdate: OrganizationUpdate, options?: Configuration): Observable<OrganizationOut> {
        const requestContextPromise = this.requestFactory.updateOrgOrganizationOrgIdPut(orgId, organizationUpdate, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateOrgOrganizationOrgIdPut(rsp)));
            }));
    }
 
    /**
     * Update the user's org group role
     * Update Override
     * @param envId 
     * @param userId 
     * @param organizationRoleUpdate 
     */
    public updateOverrideOrganizationRoleUserIdOverridesEnvIdPost(envId: string, userId: string, organizationRoleUpdate: OrganizationRoleUpdate, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.updateOverrideOrganizationRoleUserIdOverridesEnvIdPost(envId, userId, organizationRoleUpdate, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateOverrideOrganizationRoleUserIdOverridesEnvIdPost(rsp)));
            }));
    }
 
}

import { OrganizationEEApiRequestFactory, OrganizationEEApiResponseProcessor} from "../apis/OrganizationEEApi";
export class ObservableOrganizationEEApi {
    private requestFactory: OrganizationEEApiRequestFactory;
    private responseProcessor: OrganizationEEApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: OrganizationEEApiRequestFactory,
        responseProcessor?: OrganizationEEApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new OrganizationEEApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new OrganizationEEApiResponseProcessor();
    }

    /**
     * Create Org
     * @param organizationIn 
     */
    public createOrgEeOrganizationPost(organizationIn: OrganizationIn, options?: Configuration): Observable<OrganizationOutEE> {
        const requestContextPromise = this.requestFactory.createOrgEeOrganizationPost(organizationIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.createOrgEeOrganizationPost(rsp)));
            }));
    }
 
    /**
     * Delete Org
     * @param orgId 
     */
    public deleteOrgEeOrganizationOrgIdDelete(orgId: string, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.deleteOrgEeOrganizationOrgIdDelete(orgId, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.deleteOrgEeOrganizationOrgIdDelete(rsp)));
            }));
    }
 
    /**
     * List Orgs
     */
    public listOrgsEeOrganizationGet(options?: Configuration): Observable<ListResponseOrganizationOutEE> {
        const requestContextPromise = this.requestFactory.listOrgsEeOrganizationGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.listOrgsEeOrganizationGet(rsp)));
            }));
    }
 
    /**
     * Update Org
     * @param orgId 
     * @param organizationUpdate 
     */
    public updateOrgEeOrganizationOrgIdPut(orgId: string, organizationUpdate: OrganizationUpdate, options?: Configuration): Observable<OrganizationOutEE> {
        const requestContextPromise = this.requestFactory.updateOrgEeOrganizationOrgIdPut(orgId, organizationUpdate, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateOrgEeOrganizationOrgIdPut(rsp)));
            }));
    }
 
}

import { OrganizationGroupApiRequestFactory, OrganizationGroupApiResponseProcessor} from "../apis/OrganizationGroupApi";
export class ObservableOrganizationGroupApi {
    private requestFactory: OrganizationGroupApiRequestFactory;
    private responseProcessor: OrganizationGroupApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: OrganizationGroupApiRequestFactory,
        responseProcessor?: OrganizationGroupApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new OrganizationGroupApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new OrganizationGroupApiResponseProcessor();
    }

    /**
     * Get Org Group Settings
     */
    public getOrgGroupSettingsOrganizationGroupGet(options?: Configuration): Observable<OrganizationGroupSettingsOut> {
        const requestContextPromise = this.requestFactory.getOrgGroupSettingsOrganizationGroupGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getOrgGroupSettingsOrganizationGroupGet(rsp)));
            }));
    }
 
    /**
     * Update Org Group Settings
     * @param organizationGroupSettingUpdate 
     */
    public updateOrgGroupSettingsOrganizationGroupPut(organizationGroupSettingUpdate: OrganizationGroupSettingUpdate, options?: Configuration): Observable<OrganizationGroupSettingsOut> {
        const requestContextPromise = this.requestFactory.updateOrgGroupSettingsOrganizationGroupPut(organizationGroupSettingUpdate, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.updateOrgGroupSettingsOrganizationGroupPut(rsp)));
            }));
    }
 
}

import { OrganizationSettingsApiRequestFactory, OrganizationSettingsApiResponseProcessor} from "../apis/OrganizationSettingsApi";
export class ObservableOrganizationSettingsApi {
    private requestFactory: OrganizationSettingsApiRequestFactory;
    private responseProcessor: OrganizationSettingsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: OrganizationSettingsApiRequestFactory,
        responseProcessor?: OrganizationSettingsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new OrganizationSettingsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new OrganizationSettingsApiResponseProcessor();
    }

    /**
     * Delete the organization's customer otel config
     * Otel Settings Delete
     */
    public otelSettingsDeleteOrganizationSettingsCustomerOtelDelete(options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.otelSettingsDeleteOrganizationSettingsCustomerOtelDelete(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.otelSettingsDeleteOrganizationSettingsCustomerOtelDelete(rsp)));
            }));
    }
 
    /**
     * Update the organization's customer otel config
     * Otel Settings Update
     * @param otelConfig 
     */
    public otelSettingsUpdateOrganizationSettingsCustomerOtelPut(otelConfig: OtelConfig, options?: Configuration): Observable<void> {
        const requestContextPromise = this.requestFactory.otelSettingsUpdateOrganizationSettingsCustomerOtelPut(otelConfig, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.otelSettingsUpdateOrganizationSettingsCustomerOtelPut(rsp)));
            }));
    }
 
    /**
     * Get the organization's settings
     * Settings Get
     */
    public settingsGetOrganizationSettingsGet(options?: Configuration): Observable<SettingsInternalOut> {
        const requestContextPromise = this.requestFactory.settingsGetOrganizationSettingsGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.settingsGetOrganizationSettingsGet(rsp)));
            }));
    }
 
    /**
     * Update the organization's settings
     * Settings Update
     * @param settingsInternalIn 
     */
    public settingsUpdateOrganizationSettingsPut(settingsInternalIn: SettingsInternalIn, options?: Configuration): Observable<SettingsInternalOut> {
        const requestContextPromise = this.requestFactory.settingsUpdateOrganizationSettingsPut(settingsInternalIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.settingsUpdateOrganizationSettingsPut(rsp)));
            }));
    }
 
}

import { SearchApiRequestFactory, SearchApiResponseProcessor} from "../apis/SearchApi";
export class ObservableSearchApi {
    private requestFactory: SearchApiRequestFactory;
    private responseProcessor: SearchApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SearchApiRequestFactory,
        responseProcessor?: SearchApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SearchApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SearchApiResponseProcessor();
    }

    /**
     * A generic search endpoint that returns results from all the db (usually used for searching IDs)
     * Search Db
     * @param searchQueryIn 
     * @param limit 
     */
    public searchDbSearchPost(searchQueryIn: SearchQueryIn, limit?: number, options?: Configuration): Observable<ListResponseSearchResultOut> {
        const requestContextPromise = this.requestFactory.searchDbSearchPost(searchQueryIn, limit, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.searchDbSearchPost(rsp)));
            }));
    }
 
}

import { SlackApiRequestFactory, SlackApiResponseProcessor} from "../apis/SlackApi";
export class ObservableSlackApi {
    private requestFactory: SlackApiRequestFactory;
    private responseProcessor: SlackApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: SlackApiRequestFactory,
        responseProcessor?: SlackApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new SlackApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new SlackApiResponseProcessor();
    }

    /**
     * Get Slack App
     */
    public getSlackAppSlackAppGet(options?: Configuration): Observable<SlackAppOut> {
        const requestContextPromise = this.requestFactory.getSlackAppSlackAppGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getSlackAppSlackAppGet(rsp)));
            }));
    }
 
    /**
     * Register Slack App
     * @param slackAppRegisterIn 
     */
    public registerSlackAppSlackAppPost(slackAppRegisterIn: SlackAppRegisterIn, options?: Configuration): Observable<SlackAppOut> {
        const requestContextPromise = this.requestFactory.registerSlackAppSlackAppPost(slackAppRegisterIn, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.registerSlackAppSlackAppPost(rsp)));
            }));
    }
 
}

import { StatisticsApiRequestFactory, StatisticsApiResponseProcessor} from "../apis/StatisticsApi";
export class ObservableStatisticsApi {
    private requestFactory: StatisticsApiRequestFactory;
    private responseProcessor: StatisticsApiResponseProcessor;
    private configuration: Configuration;

    public constructor(
        configuration: Configuration,
        requestFactory?: StatisticsApiRequestFactory,
        responseProcessor?: StatisticsApiResponseProcessor
    ) {
        this.configuration = configuration;
        this.requestFactory = requestFactory || new StatisticsApiRequestFactory(configuration);
        this.responseProcessor = responseProcessor || new StatisticsApiResponseProcessor();
    }

    /**
     * Returns the total number of applications
     * Get App Count
     */
    public getAppCountStatsAppCountGet(options?: Configuration): Observable<ApplicationCountResponse> {
        const requestContextPromise = this.requestFactory.getAppCountStatsAppCountGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAppCountStatsAppCountGet(rsp)));
            }));
    }
 
    /**
     * Returns the event types that this application is subscribed to, as well as a flag to indicate if any endpoints are subscribed to all event types.
     * Get App Event Types
     * @param appId 
     */
    public getAppEventTypesStatsAppAppIdEventTypesGet(appId: string, options?: Configuration): Observable<ApplicationEventTypesResponse> {
        const requestContextPromise = this.requestFactory.getAppEventTypesStatsAppAppIdEventTypesGet(appId, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getAppEventTypesStatsAppAppIdEventTypesGet(rsp)));
            }));
    }
 
    /**
     * Returns the total number of endpoints tied to the current org
     * Get Endpoint Count
     */
    public getEndpointCountStatsEndpointCountGet(options?: Configuration): Observable<EndpointCountResponse> {
        const requestContextPromise = this.requestFactory.getEndpointCountStatsEndpointCountGet(options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getEndpointCountStatsEndpointCountGet(rsp)));
            }));
    }
 
    /**
     * Get Org Group Messages Stats
     * @param startDate 
     * @param endDate 
     */
    public getOrgGroupMessagesStatsStatsOrgGroupMessagesGet(startDate?: Date, endDate?: Date, options?: Configuration): Observable<OrganizationMessageStatisticsResponse> {
        const requestContextPromise = this.requestFactory.getOrgGroupMessagesStatsStatsOrgGroupMessagesGet(startDate, endDate, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getOrgGroupMessagesStatsStatsOrgGroupMessagesGet(rsp)));
            }));
    }
 
    /**
     * Get Org Messages Stats
     * @param startDate 
     * @param endDate 
     */
    public getOrgMessagesStatsStatsOrgMessagesGet(startDate?: Date, endDate?: Date, options?: Configuration): Observable<OrganizationMessageStatisticsResponse> {
        const requestContextPromise = this.requestFactory.getOrgMessagesStatsStatsOrgMessagesGet(startDate, endDate, options);

        // build promise chain
        let middlewarePreObservable = from<RequestContext>(requestContextPromise);
        for (let middleware of this.configuration.middleware) {
            middlewarePreObservable = middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => middleware.pre(ctx)));
        }

        return middlewarePreObservable.pipe(mergeMap((ctx: RequestContext) => this.configuration.httpApi.send(ctx))).
            pipe(mergeMap((response: ResponseContext) => {
                let middlewarePostObservable = of(response);
                for (let middleware of this.configuration.middleware) {
                    middlewarePostObservable = middlewarePostObservable.pipe(mergeMap((rsp: ResponseContext) => middleware.post(rsp)));
                }
                return middlewarePostObservable.pipe(map((rsp: ResponseContext) => this.responseProcessor.getOrgMessagesStatsStatsOrgMessagesGet(rsp)));
            }));
    }
 
}
