import { Image, Skeleton } from "@chakra-ui/react";

import { WebhookSourceType } from "src/api/in";

const IconNames: Record<WebhookSourceType, string> = {
  "adobe-sign": "adobe-sign.svg",
  beehiiv: "beehiiv.svg",
  brex: "brex.svg",
  clerk: "clerk.svg",
  docusign: "docusign.svg",
  "generic-webhook": "webhook.svg",
  github: "github.svg",
  guesty: "guesty.svg",
  hubspot: "hubspot.svg",
  "incident-io": "incident.svg",
  lithic: "lithic.svg",
  nash: "nash.png",
  pleo: "pleo.svg",
  replicate: "replicate.svg",
  resend: "resend.svg",
  safebase: "safebase.png",
  sardine: "sardine.svg",
  segment: "segment.svg",
  shopify: "shopify.svg",
  slack: "slack.svg",
  stripe: "stripe.svg",
  stych: "stytch.svg",
  svix: "svix.svg",
  zoom: "zoom.svg",
};

interface SourceTypeIconProps {
  type: WebhookSourceType;
  width?: string;
  height?: string;
}

export default function SourceTypeIcon({
  type,
  width = "1.6em",
  height = "1.6em",
}: SourceTypeIconProps) {
  const iconName = IconNames[type];
  return (
    <Image
      objectFit="contain"
      width={width}
      height={height}
      src={`/ingest/sources/${iconName}`}
      fallback={<Skeleton width={width} height={height} />}
      alt={type}
    />
  );
}
