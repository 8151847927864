import { useEffect } from "react";
import {
  Box,
  Divider,
  Flex,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  HStack,
  Heading,
  Link as StyledLink,
  Stack,
  Tag,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { MoreVert } from "@material-ui/icons";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { EventTypeOut, EventTypeUpdate } from "svix";

import * as C from "@svix/common/constants";
import { setErrors } from "@svix/common/formUtils";
import { formatDateTime } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import Form from "@svix/common/widgets/Form";
import SchemaPreviewer from "@svix/common/widgets/JsonSchema/SchemaPreviewer";
import {
  generateSampleCodeForSchema,
  isSchemaConfigured,
} from "@svix/common/widgets/JsonSchema/SchemaPreviewer/utils";
import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";
import LoadingScreenSkeleton from "@svix/common/widgets/LoadingScreenSkeleton";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import Mono from "@svix/common/widgets/Mono";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import ResourceNotFound from "@svix/common/widgets/ResourceNotFound";
import Stat from "@svix/common/widgets/Stat";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import { useIsMemberOrAdmin, useIsTestMode } from "src/store/selectors";
import Description from "./properties/Description";
import { FeatureFlag } from "./properties/FeatureFlag";
import { GroupName } from "./properties/GroupName";
import SchemaField from "./SchemaField";
import { isSchemaValid, transformSchema } from "./SchemaField/utils";

const ONE_HOUR = 60 * 60 * 1000;

interface ISchemaPreviewProps {
  editSchema: () => void;
  editExample: () => void;
  schema?: JSONSchema7;
}

export function SchemaPreview(props: ISchemaPreviewProps) {
  const { editSchema, editExample, schema } = props;

  if (schema && isSchemaConfigured(schema) && isSchemaValid(schema)) {
    const onNoExample = (
      <Card h="100%">
        <Box justifyContent="space-around" h="100%">
          <Heading as="p" size="sm">
            <strong>No example configured.</strong>
          </Heading>
          <Text variant="caption" mt={3}>
            It is recommended that you configure your own custom example that reflects a
            typical event your users might see.
            <br />
            Your users will be able to use this example to test their endpoint in the App
            Portal.
          </Text>
          <HStack alignItems="center" mt={4}>
            <Button colorScheme="gray" size="sm" onClick={editExample} key="example">
              Configure example
            </Button>
            <Tag colorScheme="green">Recommended</Tag>
          </HStack>
        </Box>
      </Card>
    );

    return <SchemaPreviewer schema={schema} version={1} noExampleElement={onNoExample} />;
  }

  // If there is a schema but we don't recognize the spec, show warning message
  if (schema && !isSchemaConfigured(schema)) {
    return (
      <Box w="100%" textAlign="center" mt={8}>
        <Heading as="h2" size="sm" color="text.danger">
          No properties found for schema
        </Heading>
        <Text variant="caption" mt={2}>
          This schema is incomplete or doesn't conform to the JSONSchema Draft 7
          specifications.
        </Text>

        <Text variant="caption" mt={1} mb={4}>
          Update this schema to allow your customers to properly interpret this event
          type.
          <StyledLink
            isExternal
            ml={1}
            href={C.docs.eventTypes.schema}
            color="brand.500"
            fontWeight="medium"
          >
            Learn More
          </StyledLink>
        </Text>
        <Button colorScheme="gray" variant="outline" onClick={editSchema} size="sm">
          Edit schema
        </Button>
      </Box>
    );
  }

  return (
    <Box w="100%" textAlign="center" mt={8}>
      <Heading as="h2" size="sm">
        No Schema Defined
      </Heading>
      <Text variant="caption" mt={2} mb={4}>
        Adding a schema lets you define the shape that messages of this event type follow.{" "}
        <StyledLink
          isExternal
          ml={1}
          href={C.docs.eventTypes.schema}
          color="brand.500"
          fontWeight="medium"
        >
          Learn More
        </StyledLink>
      </Text>
      <Button colorScheme="gray" variant="outline" onClick={editSchema} size="sm">
        Edit schema
      </Button>
    </Box>
  );
}

export default function EventTypeScreen() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const isTestMode = useIsTestMode();
  const isMemberOrAdmin = useIsMemberOrAdmin();
  const { name: eventTypeName } = useParams<{ name: string }>();
  const [isEditingSchema, setEditingSchema] = useBoolean();
  const [isShowingEditWarning, setShowingEditWarning] = useBoolean();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useBoolean();
  const [isDeprecateDialogOpen, setDeprecateDialogOpen] = useBoolean();
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;

  const allEventTypesQueryKey = ["environments", activeEnvId, "eventTypes"];
  const eventTypeQueryKey = [...allEventTypesQueryKey, eventTypeName];

  const {
    data: eventType,
    error,
    isLoading,
  } = useQuery<EventTypeOut | undefined>(eventTypeQueryKey, async () => {
    const sv = await getSvix();
    return sv.eventType.get(eventTypeName);
  });

  const defaultValues = {
    name: eventType?.name ?? "",
    description: eventType?.description ?? "",
    schema: eventType?.schemas?.["1"],
  };
  const formCtx = useForm({ defaultValues });
  const { reset } = formCtx;

  useEffect(() => {
    reset({
      name: eventType?.name ?? "",
      description: eventType?.description ?? "",
      schema: eventType?.schemas?.["1"],
    });
  }, [eventType, reset]);

  if (error) {
    return (
      <ResourceNotFound
        resourceName="event type"
        to={routeResolver.getRoute("event-types")}
      />
    );
  }

  async function onUpdateEventType(form: any) {
    const api = await getSvix();
    const schema = cloneDeep(form.schema);
    transformSchema(schema);

    // If the example has been manually edited, prevent it from being overwritten
    // with autogenerated examples.
    if (!isEqual(schema.examples, eventType?.schemas?.["1"]["examples"])) {
      schema["x-svix-preserve-example"] = true;
    }

    const diff: EventTypeUpdate = {
      description: eventType?.description ?? "",
      schemas: {
        1: schema,
      },
    };

    try {
      await api.eventType.patch(eventTypeName, diff);
      setEditingSchema.off();
      queryClient.invalidateQueries(eventTypeQueryKey);
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  async function onDeleteEventType() {
    const api = await getSvix();
    try {
      await api.eventType.delete(eventTypeName);
      await queryClient.invalidateQueries(allEventTypesQueryKey);

      history.push(routeResolver.getRoute("event-types"));
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  async function onSetDeprecated(isDeprecated: boolean) {
    const api = await getSvix();
    try {
      await api.eventType.patch(eventTypeName, {
        deprecated: isDeprecated,
      });
      queryClient.invalidateQueries(eventTypeQueryKey);
      setDeprecateDialogOpen.off();
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  function tryToEditEventType() {
    // If the event type has a schema and hasn't been edited in the last hour,
    // assume it's "published" and being used in production.
    if (
      !isTestMode &&
      eventType?.schemas?.["1"] &&
      isSchemaConfigured(eventType.schemas["1"]) &&
      eventType.updatedAt.getTime() < Date.now() - ONE_HOUR
    ) {
      setShowingEditWarning.on();
    } else {
      setEditingSchema.on();
    }
  }

  function tryToSetExample() {
    const schema = formCtx.getValues("schema");
    const exampleSchema = generateSampleCodeForSchema(schema, schema.definitions);

    formCtx.setValue("schema", {
      ...schema,
      examples: [exampleSchema],
    });
    setEditingSchema.on();
  }

  const isDeprecated = !!eventType?.deprecated;

  return (
    <>
      <MetaTitle path={[eventTypeName]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("event-types")}>
            Event Types
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Mono>{eventTypeName}</Mono>
          </BreadcrumbItem>
        </Breadcrumbs>
        <Flex flexGrow={1} />
        {isMemberOrAdmin && (
          <Box>
            <Menu placement="bottom-end">
              <MenuButton as={IconButton} variant="rounded">
                <MoreVert />
              </MenuButton>
              <MenuList>
                {isDeprecated ? (
                  <MenuItem onClick={() => onSetDeprecated(false)}>
                    Mark as not deprecated
                  </MenuItem>
                ) : (
                  <MenuItem onClick={setDeprecateDialogOpen.on}>
                    Mark as deprecated
                  </MenuItem>
                )}
                <MenuItem textColor="text.danger" onClick={setDeleteDialogOpen.on}>
                  Archive
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
      </PageToolbar>

      {isLoading && <LoadingScreenSkeleton />}

      {eventType && (
        <>
          <Heading as="h1" size="md" isTruncated mt={2}>
            {eventTypeName}
            {eventType.archived && (
              <Tag size="md" colorScheme="orange" ml={2}>
                Archived
              </Tag>
            )}
            {!eventType.archived && isDeprecated && (
              <Tag size="md" colorScheme="yellow" ml={2}>
                Deprecated
              </Tag>
            )}
          </Heading>
          <Grid
            gridTemplateColumns={{
              sm: "minmax(0, 1fr)",
              md: "minmax(0, 3fr) minmax(240px, 1fr)",
            }}
            gap={8}
            mt={6}
          >
            <Box mb={8} data-cy="eventtype-description-form">
              <Description eventType={eventType} />
            </Box>
            <Stack spacing={4} mb={6}>
              <Stat name="Creation Date">{formatDateTime(eventType.createdAt)}</Stat>
              <Divider />
              <Stat name="Last Updated">{formatDateTime(eventType.updatedAt)}</Stat>
              <Divider />
              <FeatureFlag eventType={eventType} />
              <Divider />
              <GroupName eventType={eventType} />
            </Stack>
          </Grid>

          <Divider />
          <Form onSubmit={onUpdateEventType} {...formCtx}>
            <Flex alignItems="center" mb={3} mt={6}>
              <Heading as="h2" size="sm">
                Schema
              </Heading>
              <Flex flexGrow={1} />
              {isEditingSchema ? (
                <HStack>
                  <Button
                    type="button"
                    colorScheme="gray"
                    onClick={setEditingSchema.off}
                    size="sm"
                    key="cancel"
                  >
                    Cancel
                  </Button>
                  <SubmitButton
                    isLoading={formCtx.formState.isSubmitting}
                    size="sm"
                    key="save"
                  >
                    Save
                  </SubmitButton>
                </HStack>
              ) : isMemberOrAdmin ? (
                <Button
                  colorScheme="gray"
                  variant="outline"
                  onClick={tryToEditEventType}
                  size="sm"
                  key="edit"
                >
                  Edit
                </Button>
              ) : null}
            </Flex>
            {isEditingSchema ? (
              <SchemaField control={formCtx.control} name="schema" />
            ) : (
              <SchemaPreview
                editSchema={tryToEditEventType}
                editExample={tryToSetExample}
                schema={eventType.schemas?.["1"]}
              />
            )}
          </Form>
        </>
      )}

      <ConfirmationDialog
        title={`Archive ${eventTypeName}`}
        isOpen={isDeleteDialogOpen}
        onCancel={setDeleteDialogOpen.off}
        onOk={onDeleteEventType}
        labelOk="Archive"
      >
        Are you sure you would like to archive this event type? Users will no longer be
        able to subscribe to it from the app portal, but endpoints currently subscribed to
        it will continue working uninterrupted.
      </ConfirmationDialog>
      <ConfirmationDialog
        title="Mark as deprecated"
        isOpen={isDeprecateDialogOpen}
        onCancel={setDeprecateDialogOpen.off}
        onOk={() => onSetDeprecated(true)}
        labelOk="Mark as deprecated"
      >
        Are you sure you would like to deprecate this event type? Users will still be able
        to subscribe to it from the app portal.
        <br /> A message will be shown warning them it might stop being supported in the
        future.
      </ConfirmationDialog>
      <ConfirmationDialog
        title={`Are you sure you want to edit ${eventTypeName}?`}
        isOpen={isShowingEditWarning}
        colorScheme="red"
        onCancel={setShowingEditWarning.off}
        onOk={() => {
          setShowingEditWarning.off();
          setEditingSchema.on();
        }}
        labelOk="Edit Anyway"
      >
        Editing the event type schema will <b>not</b> have any effect on the payload being
        sent. Your message payload needs to be updated accordingly.
        <br />
        <b>Warning:</b> Changing the message payload can break your user's production
        environments.
      </ConfirmationDialog>
    </>
  );
}
