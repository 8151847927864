import { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Code,
  Flex,
  Link,
  Heading,
  Stack,
  Skeleton,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import QRCode from "react-qr-code";
import { useQuery, useQueryClient } from "react-query";

import { logError } from "@svix/common/logger";
import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@svix/common/widgets/Modal";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { trackEvent } from "src/analytics";
import { getApiConfiguration } from "src/api";
import {
  ApiException,
  MfaEnrollment,
  MultifactorAuthenticationApi,
} from "src/generated/dashboard-openapi";

interface IAuthenticatorAppEnrollModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface EnrollmentForm {
  verificationCode: string;
}

interface IEnrollModalContentProps {
  enrollment?: MfaEnrollment;
  onClose: () => void;
  nextStep: () => void;
}

function EnrollModalContent(props: IEnrollModalContentProps) {
  const { enrollment, nextStep } = props;
  const queryClient = useQueryClient();
  const [error, setError] = useState("");
  const [showSecret, setShowSecret] = useBoolean();
  const formCtx = useForm<EnrollmentForm>({
    defaultValues: {
      verificationCode: "",
    },
  });
  async function confirm2fa(form: EnrollmentForm) {
    const mfaApi = new MultifactorAuthenticationApi(
      await getApiConfiguration({ isForMfa: true })
    );

    try {
      await mfaApi.confirmEnrollMfaAuthenticationMfaVerifyPost({
        verificationCode: form.verificationCode,
      });
      trackEvent("Enabled MFA");

      queryClient.invalidateQueries("mfaFactors");

      nextStep();
    } catch (error) {
      setError(error.body.detail);

      if (error instanceof ApiException) {
        if (error.code === 403) {
          setError("Invalid code. Try again.");
        } else {
          // If the API response code is a 400, log it since this means Auth0 returned a 400 (which shouldn't happen)
          logError(error);
        }
      }
    }
  }

  return (
    <>
      <Form onSubmit={confirm2fa} {...formCtx}>
        <ModalBody>
          {error && (
            <Box my={2}>
              <Alert status="error">{error}</Alert>
            </Box>
          )}
          <Text variant="caption" fontSize="md">
            Scan the image below with a two-factor authentication app on your phone such
            as Google Authenticator.{" "}
          </Text>
          <Text variant="caption" fontSize="md" mt={2}>
            If you can't use the QR code,{" "}
            <Link
              fontWeight="medium"
              color="teal.500"
              href="#"
              onClick={setShowSecret.on}
            >
              enter this code
            </Link>{" "}
            manually instead.
          </Text>
          <Stack spacing={3}>
            <Flex my={4} justifyContent="center">
              <Skeleton isLoaded={!!enrollment}>
                <QRCode value={enrollment?.barcodeUri || ""} size={180} />
              </Skeleton>
            </Flex>
            <TextField
              control={formCtx.control}
              name="verificationCode"
              label="Enter the six-digit code from the application"
              autoFocus
              required
              placeholder="eg. 123456"
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <SubmitButton isLoading={formCtx.formState.isSubmitting}>
            Enable MFA
          </SubmitButton>
        </ModalFooter>
      </Form>

      <Modal onClose={setShowSecret.off} isOpen={showSecret}>
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>Authenticator App Secret</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="md" m={4}>
              Your authenticator app secret is:
              <Code>{enrollment?.secret}</Code>
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function EnrollSuccessModalContent(props: IEnrollModalContentProps) {
  const { enrollment, onClose } = props;
  return (
    <>
      <ModalBody>
        <Text>You've successfully enrolled in Two-Factor authentication.</Text>
        <Text mt={2}>
          In the event that you lose access to your device, you can use this{" "}
          <strong>one-time Recovery Code</strong> to gain access to it. Please it
          somewhere safe!
        </Text>

        <Flex justifyContent="space-between" alignItems="center" my={4}>
          <Box mr={4}>
            <Heading as="h4" size="xs" my={1}>
              Recovery Codes
            </Heading>
          </Box>
          <Button
            as="a"
            size="sm"
            variant="outline"
            whiteSpace="nowrap"
            fontWeight="medium"
            download="svix_recovery_codes.txt"
            href={`data:text/plain;charset=utf-8,${encodeURIComponent(
              enrollment?.recoveryCodes?.join("\n") || ""
            )}`}
          >
            Download Code
          </Button>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Done</Button>
      </ModalFooter>
    </>
  );
}

export default function AuthenticatorAppEnrollModal(
  props: IAuthenticatorAppEnrollModalProps
) {
  const { isOpen, onClose } = props;
  const [enrollmentStep, setEnrollmentStep] = useState("enroll");

  useEffect(() => {
    if (isOpen) {
      setEnrollmentStep("enroll");
    }
  }, [isOpen]);

  const { data: enrollment } = useQuery(
    "mfaEnrollment",
    async () => {
      const config = await getApiConfiguration({ isForMfa: true });
      const mfaApi = new MultifactorAuthenticationApi(config);
      const enrollment = mfaApi.enrollMfaAuthenticationMfaAddPost();

      return enrollment;
    },
    {
      enabled: isOpen && enrollmentStep === "enroll",
    }
  );

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent borderRadius="lg">
          <ModalHeader>Setup Authenticator App</ModalHeader>
          <ModalCloseButton />
          {enrollmentStep === "enroll" && (
            <EnrollModalContent
              enrollment={enrollment}
              onClose={onClose}
              nextStep={() => setEnrollmentStep("enroll-success")}
            />
          )}
          {enrollmentStep === "enroll-success" && (
            <EnrollSuccessModalContent
              enrollment={enrollment}
              onClose={onClose}
              nextStep={onClose}
            />
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
