import {
  Box,
  Flex,
  HStack,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  Popover,
  PopoverArrow,
  Text,
  Link,
  FormLabel,
} from "@chakra-ui/react";
import InfoIcon from "@material-ui/icons/Info";
import {
  GroupBase,
  OptionProps,
  Select,
  SelectComponentsConfig,
  SingleValueProps,
  chakraComponents,
} from "chakra-react-select";
import { Control, useController } from "react-hook-form";

import * as C from "@svix/common/constants";

import { WebhookSourceNames, WebhookSourceType } from "src/api/in";
import { ISourceConfigurationForm } from ".";
import SourceTypeIcon from "./SourceTypeIcon";

export default function SourceTypeSelect({
  control,
  name,
}: {
  control: Control<ISourceConfigurationForm>;
  name: keyof ISourceConfigurationForm;
}) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <Box>
      <FormLabel htmlFor="source-type">
        <HStack alignItems="center" spacing={1}>
          <Box>Source type</Box>
          <Popover trigger="hover" placement="right">
            <PopoverTrigger>
              <Flex color="text.muted" alignItems="center">
                <InfoIcon
                  style={{
                    width: "1rem",
                    height: "1rem",
                    lineHeight: 0,
                  }}
                />
              </Flex>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody fontSize="sm" fontWeight="normal" p={3}>
                <Text>
                  Choose which service the source is for. <br />
                  This determines the authentication method that will be used to validate
                  incoming requests.
                </Text>
                <Text mt={2}>
                  Not seeing the source you want?{" "}
                  <Link target="_blank" color="blue.500" href={C.contactUrl}>
                    Let us know.
                  </Link>
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </FormLabel>
      <Select
        id="source-type"
        variant="filled"
        options={options}
        value={options.find((option) => option.value === field.value)}
        onChange={(newValue) => field.onChange(newValue?.value)}
        components={customComponents}
        placeholder="Select source type"
      />
      {error && (
        <Text color="red.500" fontSize="sm" mt={1}>
          {error.message}
        </Text>
      )}
    </Box>
  );
}

interface IOption {
  label: string;
  value: WebhookSourceType;
}

const options: IOption[] = Object.entries(WebhookSourceNames).map(([type, name]) => ({
  label: name,
  value: type as WebhookSourceType,
}));

const customComponents: SelectComponentsConfig<IOption, false, GroupBase<IOption>> = {
  SingleValue: function SingleValue({
    children,
    ...props
  }: SingleValueProps<IOption, false, GroupBase<IOption>>) {
    return (
      <chakraComponents.SingleValue {...props}>
        <HStack spacing={3} py={1}>
          <SourceTypeIcon type={props.data.value} />
          <Box>{children}</Box>
        </HStack>
      </chakraComponents.SingleValue>
    );
  },
  Option: function Option({
    children,
    ...props
  }: OptionProps<IOption, false, GroupBase<IOption>>) {
    return (
      <chakraComponents.Option {...props}>
        <HStack spacing={3} py={1}>
          <SourceTypeIcon type={props.data.value} />
          <Box>{children}</Box>
        </HStack>
      </chakraComponents.Option>
    );
  },
};
