import { useState } from "react";
import { HStack, Stack, Box, Divider, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Steps, Step, useSteps } from "chakra-ui-steps";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";

import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import RadioButtons from "@svix/common/widgets/form/RadioButtons";
import TextField from "@svix/common/widgets/form/TextField";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { IngestSourceIn, SourceOut, SourcesApi } from "src/api/in";
import { routeResolver } from "src/App";
import { CronConfigurationForm } from "../Source/CronSourceConfiguration";
import {
  ISourceConfigurationForm,
  sourceAuthConfigFromForm,
  SourceConfigurationForm,
} from "../Source/SourceConfiguration";
import SourceUrlCard from "../Source/SourceUrlCard";

interface NewSourceForm {
  name: string;
  kind: SourceKind;
}

const newSourceSchema = yup.object().shape({
  name: yup.string().trim().min(3, "Name must be at least 3 characters"),
});

const samplePayload = `{
  "message": "Hello World!"
}`;

export default function SourceCreateScreen() {
  const history = useHistory();
  const formCtx = useForm<NewSourceForm>({
    defaultValues: {
      name: "",
      kind: "webhook",
    },
    resolver: yupResolver(newSourceSchema),
  });
  const webhookFormCtx = useForm<ISourceConfigurationForm>({
    defaultValues: {
      type: "generic-webhook",
      secret: "",
      enableAuth: false,
      clientId: "",
    },
  });
  const cronFormCtx = useForm<CronConfigurationForm>({
    defaultValues: {
      schedule: "0 * * * *",
      payload: samplePayload,
    },
  });
  const [createdSource, setCreatedSource] = useState<SourceOut | undefined>(undefined);

  const { nextStep, activeStep } = useSteps({
    initialStep: 0,
  });

  async function onAddSource(form: NewSourceForm) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);
    let newSource: SourceOut;
    if (form.kind === "webhook") {
      newSource = await api.create({
        name: form.name,
        type: "generic-webhook",
      });
    } else {
      newSource = await api.create({
        name: form.name,
        type: "cron",
        config: {
          schedule: "0 * * * *", // Run every hour
          payload: samplePayload,
        },
      });
    }
    setCreatedSource(newSource);
    nextStep();
  }

  async function onWebhookSourceSubmit(form: ISourceConfigurationForm) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);
    const updatedSource = {
      ...createdSource!,
      type: form.type,
      config: sourceAuthConfigFromForm(form),
    } as IngestSourceIn;

    await api.update(createdSource!.id, updatedSource);
    history.push(
      routeResolver.getRoute("in.sources._id", { sourceId: createdSource!.id })
    );
  }

  async function onCronSubmit(form: CronConfigurationForm) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);
    await api.update(createdSource!.id, {
      ...createdSource!,
      type: "cron",
      config: {
        schedule: form.schedule,
        payload: form.payload,
      },
    });
    history.push(
      routeResolver.getRoute("in.sources._id", { sourceId: createdSource!.id })
    );
  }

  const Step1 = (
    <Box maxW="30em">
      <Form onSubmit={onAddSource} {...formCtx}>
        <GeneralFormErrors />
        <Stack spacing={4}>
          <TextField
            control={formCtx.control}
            name="name"
            label="Name"
            autoFocus
            required
            placeholder="e.g. github-prod"
          />
          <SourceKindRadio control={formCtx.control} name="kind" />
          <Divider />
          <HStack spacing={4} justifyContent="flex-end">
            <Button
              colorScheme="gray"
              as={Link}
              to={routeResolver.getRoute("in.sources")}
            >
              Cancel
            </Button>
            <SubmitButton isLoading={formCtx.formState.isSubmitting}>Next</SubmitButton>
          </HStack>
        </Stack>
      </Form>
    </Box>
  );

  const Step2 = (
    <Stack spacing={4}>
      {createdSource && (
        <>
          {createdSource.type === "cron" ? (
            <Card maxW="45em">
              <CronConfigurationForm
                onSubmit={onCronSubmit}
                formCtx={cronFormCtx}
                showActions
                showCancel={false}
                cancelLabel="Done"
              />
            </Card>
          ) : (
            <>
              <SourceUrlCard source={createdSource} showRotateButton />
              <Card maxW="45em">
                <SourceConfigurationForm
                  formCtx={webhookFormCtx}
                  showActions
                  isNew
                  onSubmit={onWebhookSourceSubmit}
                />
              </Card>
            </>
          )}
        </>
      )}
    </Stack>
  );

  return (
    <>
      <MetaTitle path={["New Source"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("in.sources")}>
            Sources
          </BreadcrumbItem>
          <BreadcrumbItem>New Source</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      <Box maxW="70em">
        <Box maxW="30em">
          <Steps activeStep={activeStep} mb={6}>
            <Step label="Step 1" description="Create source" />
            <Step label="Step 2" description="Configure" />
          </Steps>
        </Box>
        {activeStep === 0 && Step1}
        {activeStep === 1 && Step2}
      </Box>
    </>
  );
}

type SourceKind = "webhook" | "cron";

interface ISourceKindRadioProps {
  isDisabled?: boolean;
  control: any;
  name: string;
}

export function SourceKindRadio(props: ISourceKindRadioProps) {
  const buttons = Object.freeze([
    {
      title: "Webhook",
      description: "Receive and forward webhook events.",
      value: "webhook" as SourceKind,
    },
    {
      title: "Cron",
      description: "Send a static payload on a schedule.",
      value: "cron" as SourceKind,
    },
  ]);

  return (
    <Stack spacing={0}>
      <Text fontSize="md" fontWeight="medium">
        Source type
      </Text>
      <RadioButtons<SourceKind> buttons={buttons} {...props} />
    </Stack>
  );
}
