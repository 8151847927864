import { useState, useEffect } from "react";
import { Box, FormLabel, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { EnvironmentIn, EnvironmentApi } from "svix/dist/openapi";
import * as yup from "yup";

import { FORM_ERROR, setErrors } from "@svix/common/formUtils";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import CheckboxField from "@svix/common/widgets/form/Checkbox";
import FileInput from "@svix/common/widgets/form/FileInput";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@svix/common/widgets/Modal";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { useLoading } from "src/utils";

const importDataSchema = yup.object().shape({
  eventTypes: yup.array().default(() => []),
  settings: yup
    .object()
    .shape({
      customBaseFontSize: yup.number().nullable(),
      customColor: yup.string().nullable(),
      customFontFamily: yup.string().nullable(),
      customLogoUrl: yup.string().nullable(),
      customThemeOverride: yup.object().nullable(),
      disableEndpointOnFailure: yup.boolean().nullable(),
      enableChannels: yup.boolean().nullable(),
      enforceHttps: yup.boolean().nullable(),
    })
    .nullable(),
  createdAt: yup.date().default(() => new Date()),
  version: yup.number().default(() => 1),
});

interface IImportForm {
  includeEventTypes: boolean;
  includeSettings: boolean;
  includeConnectors: boolean;
  file: FileList | null | undefined;
}

const defaultValues: IImportForm = {
  includeEventTypes: true,
  includeSettings: true,
  includeConnectors: true,
  file: null,
};

interface IAddEnvironmentModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function AddEnvironmentModal(props: IAddEnvironmentModalProps) {
  const { isOpen, onClose } = props;
  const toast = useToast();
  const [importData, setImportData] = useState<EnvironmentIn>();

  const formCtx = useForm<IImportForm>({ defaultValues });
  const { reset } = formCtx;

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
      setImportData(undefined);
    }
  }, [isOpen, reset]);

  async function onImportEnvironment(form: IImportForm) {
    const sv = await getSvix();
    const api = new EnvironmentApi(sv._configuration);
    try {
      await api.v1EnvironmentImport({
        environmentIn: {
          eventTypes: form.includeEventTypes ? importData?.eventTypes : undefined,
          settings: form.includeSettings ? importData?.settings || {} : null,
          connectors: form.includeConnectors ? importData?.connectors : undefined,
        },
      });
      toast({
        title: "Import successful",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (e: any) {
      setErrors(formCtx.setError, e.body);
    }
  }

  const file = (formCtx as any).watch("file") as FileList | null;

  useLoading(async () => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileStr = event.target?.result as string;
      if (fileStr) {
        try {
          const parsedData = JSON.parse(fileStr);
          setImportData(
            importDataSchema.validateSync({
              ...parsedData,
              createdAt: parsedData["createdAt"],
            })
          );
        } catch (e) {
          (formCtx.setError as any)(FORM_ERROR, {
            message: "Failed to parse import data from file",
            type: "error",
          });
        }
      }
    };
    reader.readAsText(file[0]);
  }, [file]);

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>Import Environment</ModalHeader>
        <Form onSubmit={onImportEnvironment} {...formCtx}>
          <ModalBody>
            <GeneralFormErrors />
            <ModalCloseButton />
            <Stack spacing={6}>
              <FileInput
                accept="application/JSON"
                name="file"
                prompt="Select an environment to import..."
              />

              {importData && (
                <Box>
                  <FormLabel my={2}>Choose what you want to have imported:</FormLabel>
                  {importData.eventTypes && importData.eventTypes.length > 0 && (
                    <CheckboxField control={formCtx.control} name="includeEventTypes">
                      Event types ({importData.eventTypes.length})
                    </CheckboxField>
                  )}
                  {importData.connectors && importData.connectors.length > 0 && (
                    <CheckboxField control={formCtx.control} name="includeConnectors">
                      Connectors ({importData.connectors.length})
                    </CheckboxField>
                  )}
                  <CheckboxField
                    isDisabled={!importData.settings}
                    control={formCtx.control}
                    name="includeSettings"
                  >
                    Environment settings
                  </CheckboxField>
                </Box>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <SubmitButton isDisabled={!importData} showLoading>
              Import
            </SubmitButton>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}
