import { ConnectorKind } from "svix/dist/openapi";

import discordIcon from "src/img/integrations/discord-icon.svg";
import hubspotIcon from "src/img/integrations/hubspot-icon.svg";
import inngestIcon from "src/img/integrations/inngest-icon.png";
import teamsIcon from "src/img/integrations/microsoft-teams-icon.svg";
import slackIcon from "src/img/integrations/slack-icon.svg";
import webhookIcon from "src/img/integrations/webhook-icon.svg";
import windmillIcon from "src/img/integrations/windmill-icon.svg";
import zapierIcon from "src/img/integrations/zapier-icon.svg";

export interface IntegrationData {
  name: string;
  icon: string;
  description: string;
}

export const templateTypes: { [k in ConnectorKind]: IntegrationData } = {
  Custom: {
    name: "Custom",
    icon: webhookIcon,
    description:
      "Define your own kind of connector, and provide instructions for how to connect it to an external service.",
  },
  Slack: {
    name: "Slack",
    icon: slackIcon,
    description:
      "The Slack connector lets your users get a Slack Incoming Webhooks URL directly from the App Portal, for a seamless integration with Slack channels.",
  },
  Discord: {
    name: "Discord",
    icon: discordIcon,
    description:
      "The Discord connector lets your users get a Discord Incoming Webhooks URL directly from the App Portal, for a seamless integration with Discord channels.",
  },
  Hubspot: {
    name: "Hubspot",
    icon: hubspotIcon,
    description:
      "The Hubspot connector lets your users connect your webhooks to their Hubspot account.",
  },
  Inngest: {
    name: "Inngest",
    icon: inngestIcon,
    description:
      "The Inngest connector lets your users get an Inngest Webhook URL directly from the App Portal.",
  },
  Teams: {
    name: "Teams",
    icon: teamsIcon,
    description:
      "The Teams connector gives your users instructions on how to get a Teams Incoming Webhook URL to set up a connection with Teams channels.",
  },
  Zapier: {
    name: "Zapier",
    icon: zapierIcon,
    description:
      "The Zapier connector gives your users instructions on how to get a Zapier Webhook URL to trigger a Zap using a webhook.",
  },
  Windmill: {
    name: "Windmill",
    icon: windmillIcon,
    description:
      "The Windmill connector lets your users get a Windmill Webhook URL directly from the App Portal.",
  },
  // --- TBD ---
  CustomerIO: {
    name: "Customer.io",
    icon: "",
    description: "",
  },
  Salesforce: {
    name: "Salesforce",
    icon: "",
    description: "",
  },
  TriggerDev: {
    name: "Trigger.dev",
    icon: "",
    description: "",
  },
  Segment: {
    name: "Segment",
    icon: "",
    description: "",
  },
};

const DEFAULT_CODE = `
  // modify the webhook object...

  // and return it
  return webhook;
`;

export const getExampleCode = (kind: ConnectorKind) => {
  let functionCode = DEFAULT_CODE;

  switch (kind) {
    case "Slack":
      functionCode = `
  // Format the Slack message with the event payload
  // See https://api.slack.com/messaging/webhooks#posting_with_webhooks
  webhook.payload = {
    text: \`New event: \${JSON.stringify(webhook.payload)}\`
  }

  return webhook;`;
      break;
    case "Discord":
      functionCode = `
  // Format the Discord message with the event payload
  // See https://discord.com/developers/docs/resources/webhook#execute-webhook
  webhook.payload = {
    username: "Webhooks Integration",
    content: \`New event: \${JSON.stringify(webhook.payload)}\` 
  }

  return webhook;`;
      break;
    case "Hubspot":
      functionCode = `
  // See https://developers.hubspot.com/docs/api/crm/objects
  webhook.url = "https://api.hubapi.com/crm/v3/objects/contacts";
  webhook.payload = {
    "properties": {
      "email": "test@gmail.com",
      "payload": \`\${JSON.stringify(webhook.payload)}\`
    }
  }

  return webhook;`;
      break;
    case "Teams":
      functionCode = `
  // See https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/connectors-using#send-adaptive-cards-using-an-incoming-webhook
  webhook.payload = {
    "type":"message",
    "attachments":[
      {
        "contentType":"application/vnd.microsoft.card.adaptive",
        "contentUrl":null,
        "content":{
          "$schema":"http://adaptivecards.io/schemas/adaptive-card.json",
          "type":"AdaptiveCard",
          "version":"1.2",
          "body":[{
            "type": "TextBlock",
            "text": \`New event: \${JSON.stringify(webhook.payload)}\`
          }]
        }
      }
    ]
  }

  return webhook;`;
      break;
    case "Inngest":
    case "Zapier":
    default:
      functionCode = DEFAULT_CODE;
  }

  return `/**
 * @param webhook the webhook object
 * @param webhook.method destination method. Allowed values: "POST", "PUT"
 * @param webhook.url current destination address
 * @param webhook.eventType current webhook Event Type
 * @param webhook.payload JSON payload
 * @param webhook.cancel whether to cancel dispatch of the given webhook
 */
function handler(webhook) {${functionCode}
}
`;
};
