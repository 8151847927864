import { useState, useCallback } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Kbd,
  Progress,
  Text,
  useBoolean,
  useToken,
} from "@chakra-ui/react";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router";
import { useDebounce } from "use-debounce";

import useKeyboardShortcut from "@svix/common/hooks/keyboardShortcut";
import { wrapAround } from "@svix/common/utils";
import { Modal, ModalBody, ModalOverlay, ModalContent } from "@svix/common/widgets/Modal";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { SearchApi } from "src/generated/dashboard-openapi";
import { useLoading } from "src/utils";

export default function DashboardSearch() {
  const history = useHistory();

  const placeholderColor = useToken("colors", "brand.300");
  const [showModal, setShowModal] = useBoolean();

  const [focusedIdx, setFocusedIdx] = useState(0);
  const [searchStr, setSearchStr] = useState("");
  const [debouncedSearch] = useDebounce(searchStr, 250);
  useKeyboardShortcut("/", null, setShowModal.on);

  const [searchResults, _, isLoading] = useLoading(async () => {
    const config = await getApiConfiguration();
    const api = new SearchApi(config);

    return debouncedSearch.length >= 2
      ? api.searchDbSearchPost({ idOrUid: debouncedSearch })
      : undefined;
  }, [debouncedSearch]);

  const jumpToAnything = useCallback(
    (idx?: number) => {
      const selectedIdx = idx ?? focusedIdx;
      const selectedResult = searchResults?.data![selectedIdx];

      if (selectedResult?.type === "app") {
        history.push(
          routeResolver.getRoute("applications._id", { appId: selectedResult.id })
        );
      }
      setShowModal.off();
    },
    [searchResults, focusedIdx, history, setShowModal]
  );

  return (
    <>
      <InputGroup
        display={["none", "none", "flex"]}
        flexShrink={1}
        maxWidth="300px"
        mr={2}
      >
        <InputLeftElement pointerEvents="none">
          <SearchIcon style={{ color: placeholderColor }} />
        </InputLeftElement>
        <Input
          alignItems="center"
          display="flex"
          as="div"
          cursor="pointer"
          onClick={setShowModal.on}
          bg="brand.600"
          color="brand.300"
          _hover={{
            boxShadow: "outline",
          }}
          placeholder=""
          variant="filled"
        >
          Jump to app...
        </Input>
        <InputRightElement>
          <Kbd color="brand.600" bgColor="brand.300" borderColor="brand.400" py="0.1em">
            /
          </Kbd>
        </InputRightElement>
      </InputGroup>

      <Modal isOpen={showModal} onClose={setShowModal.off}>
        <ModalOverlay />
        <ModalContent position="relative">
          <ModalBody p={2}>
            <InputGroup maxWidth="300px" mr={2}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon style={{ color: placeholderColor }} />
              </InputLeftElement>
              <Input
                variant="outline"
                border="none"
                _focus={{
                  border: "none",
                }}
                autoFocus
                onClick={setShowModal.on}
                placeholder="Search by App Name, ID, or UID"
                value={searchStr}
                onChange={(evt) => {
                  setSearchStr(evt.target.value);
                }}
                onKeyDown={(evt) => {
                  const records = searchResults?.data;
                  if (!records) {
                    return;
                  }

                  if (evt.key === "ArrowDown") {
                    setFocusedIdx(wrapAround(focusedIdx + 1, records.length));
                  }
                  if (evt.key === "ArrowUp") {
                    setFocusedIdx(wrapAround(focusedIdx - 1, records.length));
                  }
                  if (evt.key === "Enter") {
                    jumpToAnything();
                  }
                }}
              />
            </InputGroup>
            {isLoading && <Progress position="absolute" size="xs" isIndeterminate />}
            <Box maxHeight="calc(100vh - 10rem)" overflowY="scroll">
              {searchResults?.data.map((result, idx) => (
                <Box
                  cursor="pointer"
                  p={3}
                  key={result.id}
                  _focus={{
                    bg: "background.secondary",
                  }}
                  bg={focusedIdx === idx ? "background.hover" : "background.secondary"}
                  _hover={{ bg: "background.hover" }}
                  onClick={() => {
                    jumpToAnything(idx);
                  }}
                >
                  <Text>{result.name}</Text>
                  <Text variant="caption" size="xs">
                    {result.uid}
                  </Text>
                  <Text variant="caption" size="xs">
                    {result.id}
                  </Text>
                </Box>
              ))}
              {searchResults?.data.length === 0 && (
                <Box textAlign="center" p={2}>
                  <Text variant="caption">No search results found.</Text>
                </Box>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
