import { Stack, Button, Text, Heading, AlertIcon, Alert } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { Redirect } from "react-router-dom";
import { ApiException, HttpErrorOut } from "svix/dist/openapi";

import { useSearch } from "@svix/common/hooks/search";
import Card from "@svix/common/widgets/Card";

import { getApiConfiguration } from "src/api";
import { ApproveCliLoginOut, CliApi } from "src/generated/dashboard-openapi";

export default function CliLoginScreen() {
  const sessionId = useSearch("sessionId");
  const code = useSearch("code");

  const {
    mutate: approveCliLoginSession,
    isLoading,
    isSuccess,
    error,
  } = useMutation<ApproveCliLoginOut, ApiException<HttpErrorOut>>(async () => {
    const api = await getApiConfiguration();
    const cliApi = new CliApi(api);
    return await cliApi.approveCliLoginCliLoginSessionApprovePost({
      sessionId: sessionId!,
    });
  });

  if (!sessionId || !code) {
    return <Redirect to="/" />;
  }

  // The SDK can't handle 409s, so handle them manually.
  let errorMessage: string | undefined = undefined;
  switch (error?.code) {
    case 409:
      errorMessage = "This login session has expired.";
      break;
    case 422:
      errorMessage = "Invalid session ID. Close this window and try again.";
      break;
    default:
      errorMessage = "Could not approve login. Close this window and try again.";
      break;
  }

  return (
    <Stack>
      <Stack justifyContent="center" alignItems="center" spacing={4} textAlign="center">
        <Heading size="lg">Svix CLI Login</Heading>
        <Stack spacing={0}>
          <Text>Approve login to use in the Svix CLI</Text>
          <Text variant="caption" fontSize="sm">
            Session ID: {sessionId}
          </Text>
        </Stack>
        <Card justifyContent="center" alignItems="center" display="flex" maxW="32em">
          <Text textAlign="center" fontSize="3xl">
            {code}
          </Text>
          <Text variant="caption" fontSize="sm" textAlign="center">
            Make sure this code matches what you see in your terminal before approving.
          </Text>
          <Button
            colorScheme="green"
            size="md"
            w="fit-content"
            variant="outline"
            onClick={() => approveCliLoginSession()}
            isLoading={isLoading}
            disabled={isSuccess}
            mt={6}
          >
            {isSuccess ? "Approved" : "Approve"}
          </Button>
          {error && (
            <Alert status="error" borderRadius="md" mt={4}>
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          {isSuccess && (
            <Text
              fontWeight="semibold"
              color="green"
              fontSize="sm"
              mt={2}
              textAlign="center"
            >
              Login successful. You can close this window.
            </Text>
          )}
        </Card>
      </Stack>
    </Stack>
  );
}
