import { Svix } from "svix";
import { RequestContext, HttpMethod } from "svix/dist/openapi";

import { Iterator } from "@svix/common/hooks/pagination";

export interface ListSourceOut {
  iterator: Iterator;
  done: boolean;
  data: SourceOut[];
}

export interface SourceOutBase {
  id: string;
  uid?: string;
  name: string;
  type: SourceType;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  ingestUrl: string;
}

const NoSecretSources = ["generic-webhook"] as const;

const OptionalSecretSources = ["github", "hubspot", "docusign"] as const;

const RequiredSecretSources = [
  "beehiiv",
  "brex",
  "clerk",
  "guesty",
  "incident-io",
  "lithic",
  "nash",
  "pleo",
  "replicate",
  "resend",
  "safebase",
  "sardine",
  "segment",
  "shopify",
  "slack",
  "stripe",
  "stych",
  "svix",
  "zoom",
] as const;

export const WebhookSourceNames: Record<WebhookSourceType, string> = {
  "generic-webhook": "Generic Webhook",
  "adobe-sign": "Adobe Sign",
  beehiiv: "Beehiiv",
  brex: "Brex",
  clerk: "Clerk",
  docusign: "DocuSign",
  github: "Github",
  guesty: "Guesty",
  hubspot: "Hubspot",
  "incident-io": "Incident.io",
  lithic: "Lithic",
  nash: "Nash",
  pleo: "Pleo",
  replicate: "Replicate",
  resend: "Resend",
  safebase: "Safebase",
  sardine: "Sardine",
  segment: "Segment",
  shopify: "Shopify",
  slack: "Slack",
  stripe: "Stripe",
  stych: "Stych",
  svix: "Svix",
  zoom: "Zoom",
} as const;

export const SourceNames: Record<SourceType, string> = {
  ...WebhookSourceNames,
  cron: "Cron",
};

type NoSecretSourceType = typeof NoSecretSources[number];
type OptionalSecretSourceType = typeof OptionalSecretSources[number];
type RequiredSecretSourceType = typeof RequiredSecretSources[number];

export type SourceConfig =
  | NoSecretConfig
  | OptionalSecretConfig
  | RequiredSecretConfig
  | AdobeSignConfig
  | CronConfig;

interface NoSecretConfig {
  type: NoSecretSourceType;
  config?: undefined;
}

interface OptionalSecretConfig {
  type: OptionalSecretSourceType;
  config?: {
    secret?: string;
  };
}

interface RequiredSecretConfig {
  type: RequiredSecretSourceType;
  config: {
    secret: string;
  };
}

interface AdobeSignConfig {
  type: "adobe-sign";
  config: {
    clientId: string;
  };
}

interface CronConfig {
  type: "cron";
  config: {
    schedule: string;
    payload: string;
    contentType?: string;
  };
}

export type SourceType = SourceConfig["type"];

export type WebhookSourceType = Exclude<SourceType, "cron">;

export const isNoSecretSource = (type: SourceType): type is NoSecretSourceType => {
  return NoSecretSources.includes(type as NoSecretSourceType);
};

export const isOptionalSecretSource = (
  type: SourceType
): type is OptionalSecretSourceType => {
  return OptionalSecretSources.includes(type as OptionalSecretSourceType);
};

export const isRequiredSecretSource = (
  type: SourceType
): type is RequiredSecretSourceType => {
  return RequiredSecretSources.includes(type as RequiredSecretSourceType);
};

export const isAdobeSignSource = (type: SourceType): type is "adobe-sign" => {
  return type === "adobe-sign";
};

export const isNoSecretSourceConfig = (
  config: SourceConfig
): config is NoSecretConfig => {
  return isNoSecretSource(config.type);
};

export const isOptionalSecretSourceConfig = (
  config: SourceConfig
): config is OptionalSecretConfig => {
  return isOptionalSecretSource(config.type);
};

export const isRequiredSecretSourceConfig = (
  config: SourceConfig
): config is RequiredSecretConfig => {
  return isRequiredSecretSource(config.type);
};

export const isAdobeSignSourceConfig = (
  config: SourceConfig
): config is AdobeSignConfig => {
  return isAdobeSignSource(config.type);
};

export type SourceOut = SourceOutBase & SourceConfig;

export type IngestSourceIn = {
  name: string;
  uid?: string;
} & SourceConfig;

export interface SourceAuthOptionalConfig {
  secret?: string;
}

export interface SourceAuthRequiredConfig {
  secret: string;
}

export interface SourceAdobeSignConfig {
  clientId: string;
}

export interface SourceDashboardOut {
  token: string;
  url: string;
}

export interface ListIngestLogOut {
  data: IngestLogOut[];
  iterator: Iterator;
  done: boolean;
}

export interface IngestLogOut {
  id: string;
  org_id: string;
  source_id: string;
  status_code: number;
  error_text: string;
  headers: Record<string, string>;
  payload: string;
  created_at: string;
  updated_at: string;
}

export interface SourceConfigurationForm {
  type: SourceType;
  enableAuth: boolean;
  secret?: string;
  clientId?: string;
}

export class SourcesApi {
  private readonly basePath = "/ingest/api/v1/source";

  private readonly svix: Svix;

  constructor(svix: Svix) {
    this.svix = svix;
  }

  private async getReqContext(path: string, method: HttpMethod) {
    const requestContext = this.svix._configuration.baseServer.makeRequestContext(
      path,
      method
    );
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
    const authMethod = this.svix._configuration.authMethods["HTTPBearer"];
    await authMethod?.applySecurityAuthentication(requestContext);
    return requestContext;
  }

  async create(source: IngestSourceIn): Promise<SourceOut> {
    const path = this.basePath;
    const requestContext = await this.getReqContext(path, HttpMethod.POST);

    requestContext.setHeaderParam("Content-Type", "application/json");
    requestContext.setBody(JSON.stringify(source));

    return this.sendRequest(requestContext);
  }

  async delete(sourceId: string): Promise<void> {
    const path = `${this.basePath}/${sourceId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.DELETE);
    return this.sendRequest(requestContext);
  }

  async get(sourceId: string): Promise<SourceOut> {
    const path = `${this.basePath}/${sourceId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest(requestContext);
  }

  async getDashboard(sourceId: string): Promise<SourceDashboardOut> {
    const path = `${this.basePath}/${sourceId}/dashboard`;
    const requestContext = await this.getReqContext(path, HttpMethod.POST);
    requestContext.setBody(JSON.stringify({}));
    return this.sendRequest(requestContext);
  }

  async list(
    limit?: number,
    iterator?: Iterator,
    order?: string
  ): Promise<ListSourceOut> {
    const path = this.basePath;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);

    if (iterator) {
      requestContext.setQueryParam("iterator", iterator);
    }
    if (limit !== undefined) {
      requestContext.setQueryParam("limit", limit.toString());
    }
    if (order !== undefined) {
      requestContext.setQueryParam("order", order);
    }

    return this.sendRequest(requestContext);
  }

  async update(sourceId: string, source: IngestSourceIn): Promise<SourceOut> {
    const path = `${this.basePath}/${sourceId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.PUT);

    requestContext.setHeaderParam("Content-Type", "application/json");
    requestContext.setBody(JSON.stringify(source));

    return this.sendRequest(requestContext);
  }

  async getIngestLogs(
    sourceId: string,
    limit?: number,
    iterator?: Iterator,
    order?: string
  ): Promise<ListIngestLogOut> {
    const path = `${this.basePath}/${sourceId}/log`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);

    if (iterator) {
      requestContext.setQueryParam("iterator", iterator);
    }
    if (limit !== undefined) {
      requestContext.setQueryParam("limit", limit.toString());
    }
    if (order !== undefined) {
      requestContext.setQueryParam("order", order);
    }

    return this.sendRequest(requestContext);
  }

  async rotateToken(sourceId: string): Promise<SourceOut> {
    const path = `${this.basePath}/${sourceId}/token/rotate`;
    const requestContext = await this.getReqContext(path, HttpMethod.POST);
    return this.sendRequest(requestContext);
  }

  private async sendRequest(requestContext: RequestContext) {
    const response = await this.svix._configuration.httpApi
      .send(requestContext)
      .toPromise();

    const body = await response.body.text();
    const bodyJson = body.length > 0 ? JSON.parse(body) : undefined;
    const converted = convertDates(bodyJson);

    if (200 <= response.httpStatusCode && response.httpStatusCode < 300) {
      return converted;
    } else {
      throw converted;
    }
  }
}

// FIXME: This is a shitty way to do this.
function convertDates<T extends Record<string, any>>(obj: T): T {
  if (typeof obj !== "object") {
    return obj;
  }

  const dateFields = ["createdAt", "updatedAt"];

  const result = { ...obj };
  for (const [key, value] of Object.entries(result)) {
    if (typeof value === "string" && dateFields.includes(key)) {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        (result as any)[key] = date;
      }
    }
  }

  return result;
}
