import { Grid, Heading, Text, Stack } from "@chakra-ui/react";
import { useQuery } from "react-query";

import Card from "@svix/common/widgets/Card";
import Link from "@svix/common/widgets/Link";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { OrganizationGroupApi } from "src/generated/dashboard-openapi";
import { useIsAdmin, useIsMemberOrAdmin } from "src/store/selectors";

export default function SettingsScreen() {
  const isAdmin = useIsAdmin();
  const isMemberOrAdmin = useIsMemberOrAdmin();

  const { data: orgGroupSettings } = useQuery("orgGroupSettings", async () => {
    const config = await getApiConfiguration();
    const orgGroupApi = new OrganizationGroupApi(config);
    return await orgGroupApi.getOrgGroupSettingsOrganizationGroupGet();
  });

  return (
    <>
      <MetaTitle path={["Settings"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem>Settings</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <Grid gridTemplateColumns={["1fr", "1fr", "1fr", "1fr 1fr"]} gap={4}>
        <Card>
          <Heading as="h2" size="sm" mb={4}>
            User Settings
          </Heading>
          <Text variant="caption">Configure personal account settings</Text>
          <Stack spacing={2} mt={3}>
            <Link
              to={routeResolver.getRoute("settings.user.display")}
              alignSelf="flex-start"
            >
              Display preferences
            </Link>
            <Link
              to={routeResolver.getRoute("settings.user.account")}
              alignSelf="flex-start"
            >
              Account Management
            </Link>
          </Stack>
        </Card>
        <Card>
          <Heading as="h2" size="sm" mb={4}>
            Organization Settings
          </Heading>
          <Text variant="caption">Manage organization settings and members</Text>
          <Stack spacing={2} mt={3}>
            <Link
              to={routeResolver.getRoute("settings.organizationGroup")}
              alignSelf="flex-start"
            >
              Organization Settings
            </Link>
            <Link
              to={routeResolver.getRoute("settings.organizationGroup.members")}
              alignSelf="flex-start"
            >
              Organization Members
            </Link>
          </Stack>
        </Card>
        {isAdmin && (
          <Card>
            <Heading as="h2" size="sm" mb={4}>
              Billing
            </Heading>
            <Text variant="caption">Manage your organization's billing</Text>
            <Stack spacing={2} mt={3}>
              <Link to={routeResolver.getRoute("billing")} alignSelf="flex-start">
                Manage plan &amp; billing information
              </Link>
            </Stack>
          </Card>
        )}
        {isMemberOrAdmin && (
          <Card>
            <Heading as="h2" size="sm" mb={4}>
              Environment Settings
            </Heading>
            <Text variant="caption">Configure settings for the current environment</Text>
            <Stack spacing={2} mt={3}>
              <Link
                to={routeResolver.getRoute("settings.organization.generalSettings")}
                alignSelf="flex-start"
              >
                General Settings
              </Link>
              <Link
                to={routeResolver.getRoute("settings.organization.whiteLabel")}
                alignSelf="flex-start"
              >
                App Portal White Labeling
              </Link>
              <Link
                to={routeResolver.getRoute("settings.organization.catalog")}
                alignSelf="flex-start"
              >
                Event Catalog Configuration
              </Link>
            </Stack>
          </Card>
        )}
        {orgGroupSettings?.auditLogEnabled && (
          <Card>
            <Heading as="h2" size="sm" mb={4}>
              Security
            </Heading>
            <Link to={routeResolver.getRoute("usage.audit-log")} alignSelf="flex-start">
              Audit Log
            </Link>
          </Card>
        )}
      </Grid>
    </>
  );
}
