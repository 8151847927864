export * from './ApplicationCountResponse';
export * from './ApplicationEventTypesResponse';
export * from './ApproveCliLoginIn';
export * from './ApproveCliLoginOut';
export * from './AuditLogRecord';
export * from './AuthTokenCensoredOut';
export * from './AuthTokenExpireIn';
export * from './AuthTokenIn';
export * from './AuthTokenOut';
export * from './AuthTokenUpdateIn';
export * from './Authenticator';
export * from './BillingAlertsIn';
export * from './BillingAlertsOut';
export * from './BillingOut';
export * from './BorderRadiusConfig';
export * from './BorderRadiusEnum';
export * from './CustomColorPalette';
export * from './CustomStringsOverride';
export * from './CustomThemeOverride';
export * from './DashboardAccessOut';
export * from './DashboardAuditLog';
export * from './EndpointCountResponse';
export * from './EventCatalogOut';
export * from './EventTypeOut';
export * from './Feature';
export * from './FindInviteByTokenIn';
export * from './FontSizeConfig';
export * from './GenericCollectionListOut';
export * from './GenericCollectionOut';
export * from './HTTPValidationError';
export * from './HttpErrorOut';
export * from './InviteIn';
export * from './InviteOut';
export * from './InviteResponseIn';
export * from './InvoiceLineOut';
export * from './ListResponseAuthTokenCensoredOut';
export * from './ListResponseInviteOut';
export * from './ListResponseOrganizationOut';
export * from './ListResponseOrganizationOutEE';
export * from './ListResponseOrganizationRoleOverride';
export * from './ListResponseOrganizationUserOut';
export * from './ListResponsePaymentMethodOut';
export * from './ListResponseSearchResultOut';
export * from './ListResponseVercelProjectOut';
export * from './LoginFinishedIn';
export * from './LoginFinishedOut';
export * from './MfaEnrollment';
export * from './MfaValidation';
export * from './OrganizationAndRole';
export * from './OrganizationEnvironment';
export * from './OrganizationGroupRow';
export * from './OrganizationGroupSettingUpdate';
export * from './OrganizationGroupSettingsOut';
export * from './OrganizationIdAndEnvironment';
export * from './OrganizationIn';
export * from './OrganizationInviteStatus';
export * from './OrganizationMessageStatisticsData';
export * from './OrganizationMessageStatisticsResponse';
export * from './OrganizationOut';
export * from './OrganizationOutEE';
export * from './OrganizationRegion';
export * from './OrganizationRole';
export * from './OrganizationRoleOverride';
export * from './OrganizationRoleUpdate';
export * from './OrganizationUpdate';
export * from './OrganizationUser';
export * from './OrganizationUserOut';
export * from './OtelConfig';
export * from './PaymentMethodOut';
export * from './QuestionnaireIn';
export * from './RecoveryCode';
export * from './RecoveryCodeIn';
export * from './SearchQueryIn';
export * from './SearchResultOut';
export * from './SettingsInternalIn';
export * from './SettingsInternalOut';
export * from './SlackAppOut';
export * from './SlackAppRegisterIn';
export * from './StatisticsPeriod';
export * from './StatsOut';
export * from './StripeCoupon';
export * from './StripeSetupIntentOut';
export * from './SubscriptionEnum';
export * from './SubscriptionOut';
export * from './SubscriptionPatchIn';
export * from './UserMetadata';
export * from './ValidationError';
export * from './VercelEnabledOut';
export * from './VercelEnvVarsIn';
export * from './VercelProjectOut';
export * from './VerificationCodeIn';
export * from './ZapierIntegrationPackageOut';

import { ApplicationCountResponse } from './ApplicationCountResponse';
import { ApplicationEventTypesResponse } from './ApplicationEventTypesResponse';
import { ApproveCliLoginIn } from './ApproveCliLoginIn';
import { ApproveCliLoginOut } from './ApproveCliLoginOut';
import { AuditLogRecord } from './AuditLogRecord';
import { AuthTokenCensoredOut } from './AuthTokenCensoredOut';
import { AuthTokenExpireIn } from './AuthTokenExpireIn';
import { AuthTokenIn } from './AuthTokenIn';
import { AuthTokenOut } from './AuthTokenOut';
import { AuthTokenUpdateIn } from './AuthTokenUpdateIn';
import { Authenticator } from './Authenticator';
import { BillingAlertsIn } from './BillingAlertsIn';
import { BillingAlertsOut } from './BillingAlertsOut';
import { BillingOut } from './BillingOut';
import { BorderRadiusConfig } from './BorderRadiusConfig';
import { BorderRadiusEnum } from './BorderRadiusEnum';
import { CustomColorPalette } from './CustomColorPalette';
import { CustomStringsOverride } from './CustomStringsOverride';
import { CustomThemeOverride } from './CustomThemeOverride';
import { DashboardAccessOut } from './DashboardAccessOut';
import { DashboardAuditLog } from './DashboardAuditLog';
import { EndpointCountResponse } from './EndpointCountResponse';
import { EventCatalogOut } from './EventCatalogOut';
import { EventTypeOut } from './EventTypeOut';
import { Feature } from './Feature';
import { FindInviteByTokenIn } from './FindInviteByTokenIn';
import { FontSizeConfig } from './FontSizeConfig';
import { GenericCollectionListOut } from './GenericCollectionListOut';
import { GenericCollectionOut } from './GenericCollectionOut';
import { HTTPValidationError } from './HTTPValidationError';
import { HttpErrorOut } from './HttpErrorOut';
import { InviteIn } from './InviteIn';
import { InviteOut } from './InviteOut';
import { InviteResponseIn } from './InviteResponseIn';
import { InvoiceLineOut } from './InvoiceLineOut';
import { ListResponseAuthTokenCensoredOut } from './ListResponseAuthTokenCensoredOut';
import { ListResponseInviteOut } from './ListResponseInviteOut';
import { ListResponseOrganizationOut } from './ListResponseOrganizationOut';
import { ListResponseOrganizationOutEE } from './ListResponseOrganizationOutEE';
import { ListResponseOrganizationRoleOverride } from './ListResponseOrganizationRoleOverride';
import { ListResponseOrganizationUserOut } from './ListResponseOrganizationUserOut';
import { ListResponsePaymentMethodOut } from './ListResponsePaymentMethodOut';
import { ListResponseSearchResultOut } from './ListResponseSearchResultOut';
import { ListResponseVercelProjectOut } from './ListResponseVercelProjectOut';
import { LoginFinishedIn } from './LoginFinishedIn';
import { LoginFinishedOut } from './LoginFinishedOut';
import { MfaEnrollment } from './MfaEnrollment';
import { MfaValidation } from './MfaValidation';
import { OrganizationAndRole } from './OrganizationAndRole';
import { OrganizationEnvironment } from './OrganizationEnvironment';
import { OrganizationGroupRow } from './OrganizationGroupRow';
import { OrganizationGroupSettingUpdate } from './OrganizationGroupSettingUpdate';
import { OrganizationGroupSettingsOut } from './OrganizationGroupSettingsOut';
import { OrganizationIdAndEnvironment } from './OrganizationIdAndEnvironment';
import { OrganizationIn } from './OrganizationIn';
import { OrganizationInviteStatus } from './OrganizationInviteStatus';
import { OrganizationMessageStatisticsData } from './OrganizationMessageStatisticsData';
import { OrganizationMessageStatisticsResponse } from './OrganizationMessageStatisticsResponse';
import { OrganizationOut } from './OrganizationOut';
import { OrganizationOutEE } from './OrganizationOutEE';
import { OrganizationRegion } from './OrganizationRegion';
import { OrganizationRole } from './OrganizationRole';
import { OrganizationRoleOverride } from './OrganizationRoleOverride';
import { OrganizationRoleUpdate } from './OrganizationRoleUpdate';
import { OrganizationUpdate } from './OrganizationUpdate';
import { OrganizationUser } from './OrganizationUser';
import { OrganizationUserOut } from './OrganizationUserOut';
import { OtelConfig } from './OtelConfig';
import { PaymentMethodOut } from './PaymentMethodOut';
import { QuestionnaireIn } from './QuestionnaireIn';
import { RecoveryCode } from './RecoveryCode';
import { RecoveryCodeIn } from './RecoveryCodeIn';
import { SearchQueryIn } from './SearchQueryIn';
import { SearchResultOut } from './SearchResultOut';
import { SettingsInternalIn } from './SettingsInternalIn';
import { SettingsInternalOut } from './SettingsInternalOut';
import { SlackAppOut } from './SlackAppOut';
import { SlackAppRegisterIn } from './SlackAppRegisterIn';
import { StatisticsPeriod } from './StatisticsPeriod';
import { StatsOut } from './StatsOut';
import { StripeCoupon } from './StripeCoupon';
import { StripeSetupIntentOut } from './StripeSetupIntentOut';
import { SubscriptionEnum } from './SubscriptionEnum';
import { SubscriptionOut } from './SubscriptionOut';
import { SubscriptionPatchIn } from './SubscriptionPatchIn';
import { UserMetadata } from './UserMetadata';
import { ValidationError } from './ValidationError';
import { VercelEnabledOut } from './VercelEnabledOut';
import { VercelEnvVarsIn } from './VercelEnvVarsIn';
import { VercelProjectOut } from './VercelProjectOut';
import { VerificationCodeIn } from './VerificationCodeIn';
import { ZapierIntegrationPackageOut , ZapierIntegrationPackageOutMimeEnum   } from './ZapierIntegrationPackageOut';

/* tslint:disable:no-unused-variable */
let primitives = [
                    "string",
                    "boolean",
                    "double",
                    "integer",
                    "long",
                    "float",
                    "number",
                    "any"
                 ];

const supportedMediaTypes: { [mediaType: string]: number } = {
  "application/json": Infinity,
  "application/octet-stream": 0
}

                 
let enumsMap: Set<string> = new Set<string>([
    "BorderRadiusEnum",
    "Feature",
    "OrganizationEnvironment",
    "OrganizationInviteStatus",
    "OrganizationRegion",
    "OrganizationRole",
    "StatisticsPeriod",
    "SubscriptionEnum",
    "ZapierIntegrationPackageOutMimeEnum",
]);

let typeMap: {[index: string]: any} = {
    "ApplicationCountResponse": ApplicationCountResponse,
    "ApplicationEventTypesResponse": ApplicationEventTypesResponse,
    "ApproveCliLoginIn": ApproveCliLoginIn,
    "ApproveCliLoginOut": ApproveCliLoginOut,
    "AuditLogRecord": AuditLogRecord,
    "AuthTokenCensoredOut": AuthTokenCensoredOut,
    "AuthTokenExpireIn": AuthTokenExpireIn,
    "AuthTokenIn": AuthTokenIn,
    "AuthTokenOut": AuthTokenOut,
    "AuthTokenUpdateIn": AuthTokenUpdateIn,
    "Authenticator": Authenticator,
    "BillingAlertsIn": BillingAlertsIn,
    "BillingAlertsOut": BillingAlertsOut,
    "BillingOut": BillingOut,
    "BorderRadiusConfig": BorderRadiusConfig,
    "CustomColorPalette": CustomColorPalette,
    "CustomStringsOverride": CustomStringsOverride,
    "CustomThemeOverride": CustomThemeOverride,
    "DashboardAccessOut": DashboardAccessOut,
    "DashboardAuditLog": DashboardAuditLog,
    "EndpointCountResponse": EndpointCountResponse,
    "EventCatalogOut": EventCatalogOut,
    "EventTypeOut": EventTypeOut,
    "FindInviteByTokenIn": FindInviteByTokenIn,
    "FontSizeConfig": FontSizeConfig,
    "GenericCollectionListOut": GenericCollectionListOut,
    "GenericCollectionOut": GenericCollectionOut,
    "HTTPValidationError": HTTPValidationError,
    "HttpErrorOut": HttpErrorOut,
    "InviteIn": InviteIn,
    "InviteOut": InviteOut,
    "InviteResponseIn": InviteResponseIn,
    "InvoiceLineOut": InvoiceLineOut,
    "ListResponseAuthTokenCensoredOut": ListResponseAuthTokenCensoredOut,
    "ListResponseInviteOut": ListResponseInviteOut,
    "ListResponseOrganizationOut": ListResponseOrganizationOut,
    "ListResponseOrganizationOutEE": ListResponseOrganizationOutEE,
    "ListResponseOrganizationRoleOverride": ListResponseOrganizationRoleOverride,
    "ListResponseOrganizationUserOut": ListResponseOrganizationUserOut,
    "ListResponsePaymentMethodOut": ListResponsePaymentMethodOut,
    "ListResponseSearchResultOut": ListResponseSearchResultOut,
    "ListResponseVercelProjectOut": ListResponseVercelProjectOut,
    "LoginFinishedIn": LoginFinishedIn,
    "LoginFinishedOut": LoginFinishedOut,
    "MfaEnrollment": MfaEnrollment,
    "MfaValidation": MfaValidation,
    "OrganizationAndRole": OrganizationAndRole,
    "OrganizationGroupRow": OrganizationGroupRow,
    "OrganizationGroupSettingUpdate": OrganizationGroupSettingUpdate,
    "OrganizationGroupSettingsOut": OrganizationGroupSettingsOut,
    "OrganizationIdAndEnvironment": OrganizationIdAndEnvironment,
    "OrganizationIn": OrganizationIn,
    "OrganizationMessageStatisticsData": OrganizationMessageStatisticsData,
    "OrganizationMessageStatisticsResponse": OrganizationMessageStatisticsResponse,
    "OrganizationOut": OrganizationOut,
    "OrganizationOutEE": OrganizationOutEE,
    "OrganizationRoleOverride": OrganizationRoleOverride,
    "OrganizationRoleUpdate": OrganizationRoleUpdate,
    "OrganizationUpdate": OrganizationUpdate,
    "OrganizationUser": OrganizationUser,
    "OrganizationUserOut": OrganizationUserOut,
    "OtelConfig": OtelConfig,
    "PaymentMethodOut": PaymentMethodOut,
    "QuestionnaireIn": QuestionnaireIn,
    "RecoveryCode": RecoveryCode,
    "RecoveryCodeIn": RecoveryCodeIn,
    "SearchQueryIn": SearchQueryIn,
    "SearchResultOut": SearchResultOut,
    "SettingsInternalIn": SettingsInternalIn,
    "SettingsInternalOut": SettingsInternalOut,
    "SlackAppOut": SlackAppOut,
    "SlackAppRegisterIn": SlackAppRegisterIn,
    "StatsOut": StatsOut,
    "StripeCoupon": StripeCoupon,
    "StripeSetupIntentOut": StripeSetupIntentOut,
    "SubscriptionOut": SubscriptionOut,
    "SubscriptionPatchIn": SubscriptionPatchIn,
    "UserMetadata": UserMetadata,
    "ValidationError": ValidationError,
    "VercelEnabledOut": VercelEnabledOut,
    "VercelEnvVarsIn": VercelEnvVarsIn,
    "VercelProjectOut": VercelProjectOut,
    "VerificationCodeIn": VerificationCodeIn,
    "ZapierIntegrationPackageOut": ZapierIntegrationPackageOut,
}

export class ObjectSerializer {
    public static findCorrectType(data: any, expectedType: string) {
        if (data == undefined) {
            return expectedType;
        } else if (primitives.indexOf(expectedType.toLowerCase()) !== -1) {
            return expectedType;
        } else if (expectedType === "Date") {
            return expectedType;
        } else {
            if (enumsMap.has(expectedType)) {
                return expectedType;
            }

            if (!typeMap[expectedType]) {
                return expectedType; // w/e we don't know the type
            }

            // Check the discriminator
            let discriminatorProperty = typeMap[expectedType].discriminator;
            if (discriminatorProperty == null) {
                return expectedType; // the type does not have a discriminator. use it.
            } else {
                if (data[discriminatorProperty]) {
                    var discriminatorType = data[discriminatorProperty];
                    if(typeMap[discriminatorType]){
                        return discriminatorType; // use the type given in the discriminator
                    } else {
                        return expectedType; // discriminator did not map to a type
                    }
                } else {
                    return expectedType; // discriminator was not present (or an empty string)
                }
            }
        }
    }

    public static serialize(data: any, type: string, format: string) {
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.serialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            if (format == "date") {
                let month = data.getMonth()+1
                month = month < 10 ? "0" + month.toString() : month.toString()
                let day = data.getDate();
                day = day < 10 ? "0" + day.toString() : day.toString();

                return data.getFullYear() + "-" + month + "-" + day;
            } else {
                return data.toISOString();
            }
        } else {
            if (enumsMap.has(type)) {
                return data;
            }
            if (!typeMap[type]) { // in case we dont know the type
                return data;
            }
            
            // Get the actual type of this object
            type = this.findCorrectType(data, type);

            // get the map for the correct type.
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            let instance: {[index: string]: any} = {};
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.baseName] = ObjectSerializer.serialize(data[attributeType.name], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }

    public static deserialize(data: any, type: string, format: string) {
        // polymorphism may change the actual type.
        type = ObjectSerializer.findCorrectType(data, type);
        if (data == undefined) {
            return data;
        } else if (primitives.indexOf(type.toLowerCase()) !== -1) {
            return data;
        } else if (type.lastIndexOf("Array<", 0) === 0) { // string.startsWith pre es6
            let subType: string = type.replace("Array<", ""); // Array<Type> => Type>
            subType = subType.substring(0, subType.length - 1); // Type> => Type
            let transformedData: any[] = [];
            for (let index in data) {
                let date = data[index];
                transformedData.push(ObjectSerializer.deserialize(date, subType, format));
            }
            return transformedData;
        } else if (type === "Date") {
            return new Date(data);
        } else {
            if (enumsMap.has(type)) {// is Enum
                return data;
            }

            if (!typeMap[type]) { // dont know the type
                return data;
            }
            let instance = new typeMap[type]();
            let attributeTypes = typeMap[type].getAttributeTypeMap();
            for (let index in attributeTypes) {
                let attributeType = attributeTypes[index];
                instance[attributeType.name] = ObjectSerializer.deserialize(data[attributeType.baseName], attributeType.type, attributeType.format);
            }
            return instance;
        }
    }


    /**
     * Normalize media type
     *
     * We currently do not handle any media types attributes, i.e. anything
     * after a semicolon. All content is assumed to be UTF-8 compatible.
     */
    public static normalizeMediaType(mediaType: string | undefined): string | undefined {
        if (mediaType === undefined) {
            return undefined;
        }
        return mediaType.split(";")[0].trim().toLowerCase();
    }

    /**
     * From a list of possible media types, choose the one we can handle best.
     *
     * The order of the given media types does not have any impact on the choice
     * made.
     */
    public static getPreferredMediaType(mediaTypes: Array<string>): string {
        /** According to OAS 3 we should default to json */
        if (!mediaTypes) {
            return "application/json";
        }

        const normalMediaTypes = mediaTypes.map(this.normalizeMediaType);
        let selectedMediaType: string | undefined = undefined;
        let selectedRank: number = -Infinity;
        for (const mediaType of normalMediaTypes) {
            if (supportedMediaTypes[mediaType!] > selectedRank) {
                selectedMediaType = mediaType;
                selectedRank = supportedMediaTypes[mediaType!];
            }
        }

        if (selectedMediaType === undefined) {
            throw new Error("None of the given media types are supported: " + mediaTypes.join(", "));
        }

        return selectedMediaType!;
    }

    /**
     * Convert data to a string according the given media type
     */
    public static stringify(data: any, mediaType: string): string {
        if (mediaType === "application/json") {
            return JSON.stringify(data);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.stringify.");
    }

    /**
     * Parse data from a string according to the given media type
     */
    public static parse(rawData: string, mediaType: string | undefined) {
        if (mediaType === undefined) {
            throw new Error("Cannot parse content. No Content-Type defined.");
        }

        if (mediaType === "application/json") {
            return JSON.parse(rawData);
        }

        throw new Error("The mediaType " + mediaType + " is not supported by ObjectSerializer.parse.");
    }
}
