import { useState } from "react";
import { Alert, Box, Flex, Text, Heading, Tag, useBoolean } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";

import * as C from "@svix/common/constants";
import { logError } from "@svix/common/logger";
import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@svix/common/widgets/Modal";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import {
  ApiException,
  MultifactorAuthenticationApi,
} from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import { useSvixAuth } from "src/utils";
import AuthenticatorAppEnrollModal from "./AuthenticatorAppEnrollModal";

interface OptOutForm {
  verificationCode: string;
}

interface IDisableMfaModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function DisableMfaModal(props: IDisableMfaModalProps) {
  const { isOpen, onClose } = props;
  const queryClient = useQueryClient();
  const [error, setError] = useState("");
  const formCtx = useForm<OptOutForm>({
    defaultValues: {
      verificationCode: "",
    },
  });

  async function disableMFA(form: OptOutForm) {
    try {
      const mfaApi = new MultifactorAuthenticationApi(
        await getApiConfiguration({ isForMfa: true })
      );
      await mfaApi.optOutMfaAuthenticationMfaRemovePost(form);

      queryClient.invalidateQueries("mfaFactors");

      onClose();
    } catch (error) {
      setError(error.body.detail);

      if (error instanceof ApiException) {
        if (error.code === 403) {
          setError("Invalid code. Try again.");
        } else {
          // If the API response code is a 400, log it since this means Auth0 returned a 400 (which shouldn't happen)
          logError(error);
        }
      }
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="lg">
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>Disable Two-Factor Authentication</ModalHeader>
        <ModalCloseButton />
        <Form onSubmit={disableMFA} {...formCtx}>
          <ModalBody>
            {error && (
              <Box my={2}>
                <Alert status="error">{error}</Alert>
              </Box>
            )}
            <Text fontSize="md" mb={2}>
              Are you sure you want to{" "}
              <Text as="span" color="text.danger" fontWeight="bold">
                disable
              </Text>{" "}
              Multi-Factor Authentication for your account?
            </Text>
            <Text fontSize="md" mb={4}>
              Doing so will impact the security of your account and leave your account
              vulnerable to security exploits.
            </Text>
            <TextField
              control={formCtx.control}
              name="verificationCode"
              label="Enter the six-digit code from your authentication app"
              autoFocus
              required
              placeholder="eg. 123456"
            />
          </ModalBody>
          <ModalFooter>
            <SubmitButton colorScheme="red" isLoading={formCtx.formState.isSubmitting}>
              Disable
            </SubmitButton>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}

export default function ToggleTwoFactorAuth() {
  const { loginWithRedirect } = useSvixAuth();
  const emailVerified = useAppSelector((store) => store.auth.emailVerified);

  const [showAuthenticatorAppModal, setShowAuthenticatorAppModal] = useBoolean();
  const [showDisableMFAModal, setShowDisableMFAModal] = useBoolean();
  const [hasPermission, setHasPermission] = useState<boolean>();

  const { data: factors } = useQuery("mfaFactors", async () => {
    try {
      const config = await getApiConfiguration({ isForMfa: true });
      const mfaApi = new MultifactorAuthenticationApi(config);
      const factors = await mfaApi.getAuthenticatorsAuthenticationMfaGet();
      setHasPermission(true);
      return factors;
    } catch (e) {
      setHasPermission(false);
    }
    return [];
  });

  const hasOTPEnabled = factors?.some(
    (factor) => factor.authenticatorType === "otp" && factor.active
  );

  function requestMfaPermission() {
    loginWithRedirect({
      authorizationParams: {
        audience: `https://${C.envConfig.auth0.mfa_domain}/mfa/`,
        scope: "enroll read:authenticators remove:authenticators",
        redirect_uri: window.location.origin,
      },
      appState: {
        returnTo: routeResolver.getRoute("settings.user.account"),
      },
    });
  }

  return (
    <>
      <Text variant="caption" my={4}>
        Multi-Factor Authentication (MFA) protects against phishing, social engineering,
        and password brute-force attacks and secures your logins from attackers exploiting
        weak or stolen credentials.
      </Text>
      {hasPermission === false && (
        <Box>
          <Button my={1} onClick={requestMfaPermission} isDisabled={!emailVerified}>
            Enable MFA
          </Button>
          {!emailVerified && (
            <Text variant="caption">You must verify your email before enabling MFA.</Text>
          )}
        </Box>
      )}
      <Flex flexDirection="column">
        <Flex
          justifyContent="space-between"
          py={2}
          mb={3}
          mt={5}
          borderBottom="1px solid"
          borderColor="background.modifier.border"
        >
          <Heading as="h3" size="sm">
            Multi-factor Methods
          </Heading>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Box mr={4}>
            <Heading as="h4" size="xs" display="flex" alignItems="center" mb={1}>
              Authenticator App{" "}
              {hasOTPEnabled && (
                <Tag size="sm" ml={2}>
                  Enabled
                </Tag>
              )}
            </Heading>
            <Text variant="caption">
              Use an app such as Google Authenticator or Authy to verify your identify
              when logging in to Svix.
            </Text>
          </Box>
          {hasOTPEnabled ? (
            <Button
              size="sm"
              colorScheme="red"
              variant="outline"
              onClick={setShowDisableMFAModal.on}
            >
              Disable
            </Button>
          ) : (
            <Button
              size="sm"
              isDisabled={!hasPermission}
              onClick={setShowAuthenticatorAppModal.on}
            >
              Configure
            </Button>
          )}
        </Flex>
      </Flex>

      <AuthenticatorAppEnrollModal
        isOpen={showAuthenticatorAppModal}
        onClose={setShowAuthenticatorAppModal.off}
      />

      <DisableMfaModal
        isOpen={showDisableMFAModal}
        onClose={setShowDisableMFAModal.off}
      />
    </>
  );
}
