import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import posthog from "posthog-js";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import * as C from "@svix/common/constants";
import { useSearch } from "@svix/common/hooks/search";
import { logError } from "@svix/common/logger";
import { withRetries } from "@svix/common/utils";

import { trackPageView } from "src/analytics";
import { getApiConfiguration, getToken } from "src/api";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { sendLoginFinished } from "src/store/auth";
import { enableFlag, isFlag } from "src/store/featureFlags";
import { setSettings } from "src/store/settings";
import { isEE, SvixUser, useLoading, useSvixAuth } from "src/utils";

interface IAuthedContainerProps {
  children: React.ReactNode;
}

function useFeatureFlags() {
  const dispatch = useDispatch();
  const flags = useSearch("flags");

  useEffect(() => {
    if (flags) {
      for (const flag of flags.split(",")) {
        if (isFlag(flag)) {
          dispatch(enableFlag(flag));
        }
      }
    }
  }, [flags, dispatch]);
}

function useTrackPageviews() {
  const location = useLocation();
  useEffect(() => {
    trackPageView();
  }, [location]);
}

function useIdentifyUser(user: SvixUser | undefined) {
  const { orgGroupId } = useAppSelector((state) => state.auth);

  useEffect(() => {
    Sentry.setUser({ id: user?.sub });

    if (user?.sub && orgGroupId) {
      window.analytics?.identify(user.sub, { email: user.email, orgGroupId });
      posthog.identify(user.sub, { email: user.email, orgGroupId });
    }
  }, [user, orgGroupId]);
}

function useUserMode() {
  const dispatch = useAppDispatch();
  const engineerParam = useSearch("_svix_engineer");

  useEffect(() => {
    if (engineerParam) {
      dispatch(setSettings({ engineerMode: engineerParam === "true" }));
    }
  }, [engineerParam, dispatch]);
}

export default function AuthedContainer({ children }: IAuthedContainerProps) {
  const dispatch = useAppDispatch();
  const { user } = useSvixAuth();
  const { isLoggedIn, emailVerified, activeEnvId } = useAppSelector(
    (state) => state.auth
  );
  useFeatureFlags();
  useTrackPageviews();
  useIdentifyUser(user);
  useUserMode();

  useLoading(async () => {
    // Don't use the stored active env, in case the user
    // has logged in with a different account or the env does not exist anymore.
    const config = await getApiConfiguration({ noEnvSelected: true });

    // Finish loading user
    try {
      await withRetries(() => sendLoginFinished(config, dispatch));
    } catch (e) {
      if (e.code !== "login_failed_account_exists") {
        logError(e);
      }
      throw e;
    }
  }, []);

  // FIXME #8985: This is a temporary hack to make sure the user role in the active
  // organization is loaded correctly.
  useLoading(async () => {
    if (activeEnvId !== null) {
      const config = await getApiConfiguration();
      await sendLoginFinished(config, dispatch);
    }
  }, [activeEnvId]);

  useEffect(() => {
    if (!isLoggedIn || emailVerified || isEE) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    // Poll for email verification
    const intervalId = setInterval(async () => {
      // Poll the auth0 api using a special auth token - this avoids the need to
      // go through the auth0 management api on the backend, which has a single
      // rate limit for the entire organization (instead of a per-user rate limit).
      const token = await getToken({ noEnvSelected: true, isForAuth0UserInfo: true });
      const res = await fetch(`https://${C.envConfig.auth0.domain}/userinfo`, {
        headers: new Headers({
          Authorization: "Bearer " + token,
        }),
      }).then((res) => res.json());

      // If auth0 gives us back 'email_verified', then call the login-finished api
      // to have the backend refresh the user's status. This also calls 'setLoginInfo'
      // to update our local user state.
      if (res["email_verified"]) {
        const config = await getApiConfiguration();
        const res = await sendLoginFinished(config, dispatch);

        if (res.emailVerified) {
          clearInterval(intervalId);
        }
      }
      // Use a 12-second rate limit to respect the auth0 5-request-per-minute limit:
      // https://community.auth0.com/t/rate-limits-applied-to-userinfo-from-server-side-calls/88682
      // Note that this limit is per user id.
    }, 1000 * 12);

    return () => clearInterval(intervalId);
  }, [dispatch, isLoggedIn, emailVerified]);

  return <>{children}</>;
}
