import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { AuthProvider as OIDCAuthProvider, AuthProviderProps } from "react-oidc-context";
import { QueryClient, QueryClientProvider } from "react-query";
import { useHistory } from "react-router-dom";

import * as C from "@svix/common/constants";
import { MetaTitleProvider } from "@svix/common/widgets/MetaTitle";
import { DashboardThemeProvider } from "@svix/common/widgets/ThemeProvider";

import EERouter from "./EERouter";
import { useAppSelector } from "./hooks/store";
import MainRouter from "./MainRouter";
import { RouteResolver } from "./routes";
import { isEE } from "./utils";

export const routeResolver = new RouteResolver({
  home: "",
  "getting-started": {},
  "getting-started-ee": {},
  applications: {
    _id: {
      _base: ":appId",
    },
    new: "new",
  },
  in: {
    _base: "ingest",
    sources: {
      _base: "",
      _id: {
        _base: ":sourceId",
      },
      new: "new",
    },
    "getting-started": "getting-started",
  },
  integrations: {
    _base: "",
    vercel: {
      _base: "",
      install: "install",
      envSelect: "env-select",
      configure: "configure",
    },
  },
  documentation: {
    generator: "generator",
  },
  "api-access": {},
  "event-types": {
    create: {
      new: "new",
      import: "import",
      generateSDK: {
        _taskId: {
          _base: ":taskId",
        },
      },
    },
    _name: {
      _base: ":name",
    },
  },
  connectors: {
    _base: "",
    create: {
      new: "new",
    },
    update: {
      _id: {
        _base: ":id",
      },
    },
  },
  environments: {},
  billing: {
    _base: "",
    paymentComplete: {
      _planName: {
        _base: ":planName",
      },
    },
    payment: {
      _planName: {
        _base: ":planName",
      },
      new: {
        _planName: {
          _base: ":planName",
        },
      },
    },
  },
  usage: {
    _base: "",
    "audit-log": "audit-log",
  },
  settings: {
    _base: "",
    organizationGroup: {
      _base: "organization-group",
      members: {
        _base: "",
        _userId: {
          _base: ":userId",
        },
      },
    },
    organization: {
      _base: "",
      catalog: "catalog",
      whiteLabel: "white-label",
      generalSettings: "general-settings",
    },
    user: {
      _base: "",
      display: "display",
      account: "account",
    },
    slack: {
      _base: "",
      register: "register",
    },
  },
  security: {
    _base: "",
    "audit-log": "audit-log",
  },
  login: {},
  cli: {
    login: {},
  },
  signup: {},
  invite: {},
  webhooks: {},
  "admin-stats": {
    _base: "",
    _orgid: {
      _base: ":orgid",
    },
  },
  token: {},
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
      staleTime: 1000,
      cacheTime: Infinity,
      notifyOnChangeProps: "tracked",
    },
  },
});

function App() {
  const darkMode = useAppSelector((state) => state.settings.darkMode);
  const history = useHistory();
  const onRedirectCallback = (appState: AppState | undefined) => {
    if (appState) {
      history.push({
        pathname: appState.returnTo,
        ...appState,
      });
    }
  };

  return (
    <Auth0Provider
      domain={C.envConfig.auth0.domain}
      clientId={C.envConfig.auth0.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: C.envConfig.auth0.audience,
        scope:
          "openid profile email write:organization https://svix.com/email: enroll read:authenticators remove:authenticators",
      }}
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <MetaTitleProvider base="Svix Dashboard">
        <DashboardThemeProvider darkMode={darkMode} primaryColor="#3182ce">
          <MainRouter />
        </DashboardThemeProvider>
      </MetaTitleProvider>
    </Auth0Provider>
  );
}

// FIXME: add onRedirectCallback
const oidcConfig: AuthProviderProps = {
  authority: C.envConfig.oidc?.authority || "",
  scope: "openid profile email",
  client_id: C.envConfig.oidc?.client_id || "",
  redirect_uri: `${window.location.origin}/admin`,
  // For auth0 OIDC, which expects this param or returns an encrypted access token which we can't use
  extraQueryParams: C.envConfig.oidc?.audience
    ? {
        audience: C.envConfig.oidc.audience,
      }
    : undefined,
};

function EEApp() {
  const darkMode = useAppSelector((state) => state.settings.darkMode);

  return (
    <OIDCAuthProvider {...oidcConfig}>
      <MetaTitleProvider base="Svix Dashboard">
        <DashboardThemeProvider darkMode={darkMode} primaryColor="#225b90">
          <EERouter />
        </DashboardThemeProvider>
      </MetaTitleProvider>
    </OIDCAuthProvider>
  );
}

export default function AppWithQueryProvider() {
  return (
    <QueryClientProvider client={queryClient}>
      {isEE ? <EEApp /> : <App />}
    </QueryClientProvider>
  );
}
