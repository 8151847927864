import { Box, Code, Button, Flex, Tooltip, Text, useToast } from "@chakra-ui/react";
import { ArrowForward } from "@material-ui/icons";

import * as C from "@svix/common/constants";
import { useInterval } from "@svix/common/hooks/interval";
import CodeBlock from "@svix/common/widgets/Code";
import { ModalBody, ModalHeader, ModalFooter } from "@svix/common/widgets/Modal";

import { getSvix } from "src/api";
import { useRegion } from "src/store/selectors";
import { IOnboardingStepProps } from "./types";

const REFRESH_INTERVAL_MS = 2000;

export default function MessagesStep(props: IOnboardingStepProps) {
  const { appUid, orgKey, stepper } = props;
  const toast = useToast();
  const region = useRegion();

  const CREATE_MESSAGE_CODE_BLOCK = `\
curl -X POST "${C.envConfig.getServerUrl(region)}/api/v1/app/${appUid}/msg/" \\
    -H  "Accept: application/json" \\
    -H  "Content-Type: application/json" \\
    -H  "Authorization: Bearer ${orgKey}" \\
    -d '{
      "eventType": "invoice.paid", 
      "data": {"id": "invoice_WF7WtC", "status": "paid", "attempt": 1}
    }'\
`;

  useInterval(async () => {
    const api = await getSvix();
    try {
      const messages = await api.message.list(appUid);
      if (messages.data.length) {
        props.nextStep();
        toast({
          title: "New Message created",
          description: `${messages!.data[0].id}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      // could not fetch messages, fail silently...
    }
  }, REFRESH_INTERVAL_MS);

  return (
    <>
      {stepper}
      <ModalHeader>Send a Message</ModalHeader>
      <ModalBody>
        <Text fontSize="md" mb={4}>
          Now that you have an Application, let's try sending a <strong>Message</strong>.
          We are using the same Application UID we used in the previous command. Our
          payload has <Code>data</Code> and an <Code>eventType</Code>.
        </Text>

        <Text fontSize="md">
          To create one, run the following snippet from your terminal:
        </Text>
        <Box my={4}>
          <CodeBlock language="bash" code={CREATE_MESSAGE_CODE_BLOCK} copyToClipboard />
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button onClick={props.handleCancel} variant="outline" colorScheme="gray">
          Not now
        </Button>
        <Flex flexGrow={1} />
        <Tooltip
          label="Send a message to continue"
          shouldWrapChildren
          hasArrow
          placement="top"
        >
          <Button isDisabled rightIcon={<ArrowForward />} colorScheme="blue">
            Continue
          </Button>
        </Tooltip>
      </ModalFooter>
    </>
  );
}
