/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomColorPalette } from './CustomColorPalette';
import { CustomStringsOverride } from './CustomStringsOverride';
import { CustomThemeOverride } from './CustomThemeOverride';
import { URI } from './URI';
import { HttpFile } from '../http/http';

export class SettingsInternalOut {
    'colorPaletteDark'?: CustomColorPalette;
    'colorPaletteLight'?: CustomColorPalette;
    'copyAppEventsTopLevel'?: boolean;
    'customBaseFontSize'?: number;
    'customColor'?: string;
    'customFontFamily'?: string;
    'customFontFamilyUrl'?: URI;
    'customLogoUrl'?: URI;
    'customStringsOverride'?: CustomStringsOverride;
    'customThemeOverride'?: CustomThemeOverride;
    'disableEndpointOnFailure'?: boolean;
    'displayName'?: string;
    'enableChannels'?: boolean;
    'enableEndpointMtlsConfig'?: boolean;
    'enableEndpointOauthConfig'?: boolean;
    'enableIntegrationManagement'?: boolean;
    'enableMessageStream'?: boolean;
    'enableMessageTags'?: boolean;
    'enableMsgAtmptLog'?: boolean;
    'enableOtlp'?: boolean;
    'enableTransformations'?: boolean;
    'enforceHttps'?: boolean;
    'eventCatalogPublished'?: boolean;
    'readOnly'?: boolean;
    'requireEndpointChannel'?: boolean;
    'requireEndpointFilterTypes'?: boolean;
    'retryPolicy'?: Array<number>;
    'showUseSvixPlay'?: boolean;
    'whitelabelHeaders'?: boolean;
    'wipeSuccessfulPayload'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "colorPaletteDark",
            "baseName": "colorPaletteDark",
            "type": "CustomColorPalette",
            "format": ""
        },
        {
            "name": "colorPaletteLight",
            "baseName": "colorPaletteLight",
            "type": "CustomColorPalette",
            "format": ""
        },
        {
            "name": "copyAppEventsTopLevel",
            "baseName": "copyAppEventsTopLevel",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "customBaseFontSize",
            "baseName": "customBaseFontSize",
            "type": "number",
            "format": ""
        },
        {
            "name": "customColor",
            "baseName": "customColor",
            "type": "string",
            "format": "color"
        },
        {
            "name": "customFontFamily",
            "baseName": "customFontFamily",
            "type": "string",
            "format": ""
        },
        {
            "name": "customFontFamilyUrl",
            "baseName": "customFontFamilyUrl",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "customLogoUrl",
            "baseName": "customLogoUrl",
            "type": "URI",
            "format": "uri"
        },
        {
            "name": "customStringsOverride",
            "baseName": "customStringsOverride",
            "type": "CustomStringsOverride",
            "format": ""
        },
        {
            "name": "customThemeOverride",
            "baseName": "customThemeOverride",
            "type": "CustomThemeOverride",
            "format": ""
        },
        {
            "name": "disableEndpointOnFailure",
            "baseName": "disableEndpointOnFailure",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "displayName",
            "baseName": "displayName",
            "type": "string",
            "format": ""
        },
        {
            "name": "enableChannels",
            "baseName": "enableChannels",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enableEndpointMtlsConfig",
            "baseName": "enableEndpointMtlsConfig",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enableEndpointOauthConfig",
            "baseName": "enableEndpointOauthConfig",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enableIntegrationManagement",
            "baseName": "enableIntegrationManagement",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enableMessageStream",
            "baseName": "enableMessageStream",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enableMessageTags",
            "baseName": "enableMessageTags",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enableMsgAtmptLog",
            "baseName": "enableMsgAtmptLog",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enableOtlp",
            "baseName": "enableOtlp",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enableTransformations",
            "baseName": "enableTransformations",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "enforceHttps",
            "baseName": "enforceHttps",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "eventCatalogPublished",
            "baseName": "eventCatalogPublished",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "readOnly",
            "baseName": "readOnly",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "requireEndpointChannel",
            "baseName": "requireEndpointChannel",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "requireEndpointFilterTypes",
            "baseName": "requireEndpointFilterTypes",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "retryPolicy",
            "baseName": "retryPolicy",
            "type": "Array<number>",
            "format": ""
        },
        {
            "name": "showUseSvixPlay",
            "baseName": "showUseSvixPlay",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "whitelabelHeaders",
            "baseName": "whitelabelHeaders",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "wipeSuccessfulPayload",
            "baseName": "wipeSuccessfulPayload",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SettingsInternalOut.attributeTypeMap;
    }
    
    public constructor() {
    }
}

