import { useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  Flex,
  Link as StyledLink,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Launch } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";

import * as C from "@svix/common/constants";
import { stripEmptyFields } from "@svix/common/formUtils";
import Toggle from "@svix/common/widgets/form/Toggle";

import { trackEvent } from "src/analytics";
import { getApiConfiguration } from "src/api";
import { queryClient, routeResolver } from "src/App";
import {
  OrganizationSettingsApi,
  SettingsInternalIn,
} from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import { ReactComponent as NotFound } from "src/img/no_data.inline.svg";

export default function ConnectorsEmptyState() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const { control, reset, watch } = useForm<{ enableTransformations: boolean }>({
    defaultValues: {
      enableTransformations: false,
    },
  });
  const toast = useToast();
  const { data: orgSettings, refetch } = useQuery(
    ["environments", activeEnvId, "orgSettings"],
    async () => {
      const config = await getApiConfiguration();
      const orgSettingsApi = new OrganizationSettingsApi(config);
      return orgSettingsApi.settingsGetOrganizationSettingsGet();
    }
  );

  useEffect(() => {
    reset({
      enableTransformations: orgSettings?.enableTransformations ?? false,
    });
  }, [reset, orgSettings]);

  const saveSettings = useMutation(async () => {
    const config = await getApiConfiguration();
    const orgSettingsApi = new OrganizationSettingsApi(config);
    try {
      await orgSettingsApi.settingsUpdateOrganizationSettingsPut(
        stripEmptyFields<SettingsInternalIn>({
          ...orgSettings,
          enableTransformations: watch("enableTransformations"),
        })
      );
      await queryClient.invalidateQueries(["environments", activeEnvId, "orgSettings"]);
      toast({ status: "success", title: "Transformations enabled" });
      trackEvent("Enabled Transformations");
      refetch();
    } catch (e) {
      toast({ status: "error", title: "Failed to enable transformations" });
    }
  });

  return (
    <Box
      maxWidth={800}
      margin="0 auto"
      p={4}
      textAlign="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <Box width="80%" maxW="240px">
        <NotFound width="100%" height="100%" />
      </Box>
      <Heading as="h2" size="md" mt={8} mb={2}>
        No Connectors
      </Heading>
      <Text variant="caption">
        Connectors let you provide pre-made integrations to connect your webhooks to other
        services.
      </Text>
      {orgSettings?.enableTransformations ? (
        <Text variant="caption">
          <StyledLink
            as={Link}
            textDecoration="underline"
            to={routeResolver.getRoute("connectors.create.new")}
          >
            Create one
          </StyledLink>{" "}
          to get started.
        </Text>
      ) : (
        <Box>
          <Text variant="caption" pt={2}>
            To use Connectors, you need to enable Transformations in this environment.{" "}
            <StyledLink
              as="a"
              textDecoration="underline"
              href={C.docs.advanced.transformations.index}
              target="_blank"
              isExternal
            >
              Learn more about transformations.
            </StyledLink>
          </Text>
          <Flex pt={4} justifyContent="center">
            <Box w="fit-content">
              <Toggle
                w="fit-content"
                label={<>Enable Transformations</>}
                control={control}
                name="enableTransformations"
                isLoading={saveSettings.isLoading}
                onChange={() => saveSettings.mutate()}
              />
            </Box>
          </Flex>
        </Box>
      )}
      <Button
        as="a"
        mt={4}
        size="sm"
        variant="outline"
        rightIcon={<Launch fontSize="small" />}
        target="_blank"
        href={C.docs.advanced.transformations.templates}
      >
        Connectors docs
      </Button>
    </Box>
  );
}
