import { Box, Button, Grid, Image, Text, Link } from "@chakra-ui/react";

import * as C from "@svix/common/constants";
import { getSvixPlayViewUrl } from "@svix/common/play";
import { ModalBody, ModalHeader, ModalFooter } from "@svix/common/widgets/Modal";

import { getSvix } from "src/api";
import appPortalImg from "src/img/onboarding_management_ui.svg";
import { useLoading } from "src/utils";
import { IOnboardingStepProps } from "./types";
import { trackEvent } from "../../../../analytics";

export default function AppPortalStep(props: IOnboardingStepProps) {
  const { appUid, handleComplete, playToken, stepper } = props;
  const [loginUrl] = useLoading(async () => {
    const api = await getSvix();
    const access = await api.authentication.dashboardAccess(appUid);
    const onboardingUrl = new URL(access.url);
    onboardingUrl.searchParams.set("dashboardTour", "true");
    return onboardingUrl.toString();
  }, [appUid]);

  const finish = () => {
    trackEvent("Complete Onboarding");
    handleComplete();
  };

  return (
    <>
      {stepper}
      <ModalHeader>Message successfully sent, well done!</ModalHeader>
      <ModalBody>
        <Grid gridTemplateColumns={["1fr", null, null, "2fr 1fr"]}>
          <Box>
            <Text fontSize="md" mb={4}>
              The message was sent to an example endpoint we've added to your App. View
              the message on{" "}
              <Link color="teal.500" href={getSvixPlayViewUrl(playToken)} isExternal>
                Svix Play
              </Link>
              .
            </Text>
            <Text fontSize="md" my={4}>
              In production, your users will add their own endpoints. You can let them do
              it either by using the pre-built App Portal (
              <Link color="teal.500" href={C.docs.appPortal} isExternal>
                see docs
              </Link>
              ) in your dashboard, or building the UI yourself using{" "}
              <Link color="teal.500" href={C.docs.quickstart.endpoints} isExternal>
                our API
              </Link>
              .
            </Text>
          </Box>
          <Image
            alignSelf="center"
            m={4}
            borderRadius="md"
            src={appPortalImg}
            height="12em"
            width="12em"
            mb={4}
          />
        </Grid>
      </ModalBody>
      <ModalFooter justifyContent="start">
        <Button
          as="a"
          mr={2}
          href={loginUrl}
          target="_blank"
          variant="outline"
          colorScheme="brand"
          onClick={() => trackEvent("Complete Onboarding - Preview App Portal")}
        >
          Preview App Portal
        </Button>
        <Button colorScheme="green" onClick={finish}>
          Start using Svix
        </Button>
      </ModalFooter>
    </>
  );
}
