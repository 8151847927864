import { useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Divider,
  Flex,
  Heading,
  SkeletonText,
  Text,
  Tooltip,
  Thead,
  Tr,
  Th,
  Tbody,
  Stack,
  Skeleton,
  useDisclosure,
} from "@chakra-ui/react";
import { Launch, Replay } from "@material-ui/icons";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";
import { ApplicationOut, ListResponseApplicationOut, MessageOut } from "svix";
import { StatisticsApi, AttemptStatisticsResponse } from "svix/dist/openapi";

import usePagination from "@svix/common/hooks/pagination";
import { useSearch } from "@svix/common/hooks/search";
import { logError } from "@svix/common/logger";
import { formatDate } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import LoadingScreenSkeleton from "@svix/common/widgets/LoadingScreenSkeleton";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@svix/common/widgets/Modal";
import Mono from "@svix/common/widgets/Mono";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
  PageTitle,
  BreadcrumbItemWithId,
} from "@svix/common/widgets/PageToolbar";
import ResourceNotFound from "@svix/common/widgets/ResourceNotFound";
import Stat from "@svix/common/widgets/Stat";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";
import TimeIntervalLineChart from "@svix/common/widgets/TimeIntervalLineChart";

import { getApiConfiguration, getSvix } from "src/api";
import { routeResolver } from "src/App";
import { OrganizationSettingsApi } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import MessageRow from "./MessageRow";
import SubscribedEventTypes from "./SubscribedEventTypes";

function safeFormatDate(date: Date | undefined): string {
  // XXX: Log errors where date is undefined
  if (!date) {
    logError(`Attempted for formatDate, but date was ${date}`);
    return "N/A";
  }

  return formatDate(date);
}

const FIVE_MINUTES = 1000 * 60 * 5;

function transformStats(stats: AttemptStatisticsResponse) {
  const startTime = new Date(stats.startDate);
  const successData = (stats.data.successCount || []).map((successCount, idx) => ({
    x: new Date(startTime.getTime() + idx * FIVE_MINUTES),
    y: successCount,
  }));
  const failureData = (stats.data.failureCount || []).map((failureCount, idx) => ({
    x: new Date(startTime.getTime() + idx * FIVE_MINUTES),
    y: failureCount,
  }));

  return [
    {
      id: "success",
      data: successData,
    },
    {
      id: "failure",
      data: failureData,
    },
  ];
}

export default function ApplicationScreen() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId) || "";
  const orgRole = useAppSelector((store) => store.auth.role);
  const queryClient = useQueryClient();
  const rootQueryKey = ["environments", activeEnvId, "applications"];

  const { appId } = useParams<{ appId: string }>();
  const next = useSearch("next");

  const {
    isOpen: isPreviewModalOpen,
    onClose: onPreviewModalClose,
    onOpen: onPreviewModalOpen,
  } = useDisclosure();

  let previewPortalError = "";
  if (orgRole === "Viewer") {
    previewPortalError =
      "Your account does not have permission to preview the App Portal";
  }

  const { data: orgSettings } = useQuery(["orgSettings"], async () => {
    const config = await getApiConfiguration();
    const api = new OrganizationSettingsApi(config);
    return api.settingsGetOrganizationSettingsGet();
  });

  const { data: application, error } = useQuery<ApplicationOut>(
    [...rootQueryKey, appId],
    async () => {
      const api = await getSvix();
      return api.application.get(appId);
    },
    {
      placeholderData: () =>
        queryClient
          .getQueryData<ListResponseApplicationOut>(rootQueryKey)
          ?.data.find((d) => d.id === appId),
    }
  );

  const [messages, messagesCtx] = usePagination(
    ["environments", activeEnvId, "applications", appId, "messages"],
    async (iterator) => {
      const api = await getSvix();
      return api.message.list(appId, { iterator, limit: 20 });
    }
  );

  const { data: stats } = useQuery("stats", async () => {
    const sv = await getSvix();
    const api = new StatisticsApi(sv._configuration);

    return api.v1StatsAppAttempts({
      appId,
    });
  });

  const { data: loginUrl } = useQuery(
    ["enviornments", activeEnvId, appId, "appPortal"],
    async () => {
      const api = await getSvix();
      const access = await api.authentication.dashboardAccess(appId);
      return access.url;
    },
    {
      enabled: !previewPortalError,
      // We want to refetch the App Portal magic link in case the user logged out
      // in a separate tab. This prevents the chance a user clicks a manually-expired
      // login url
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    if (application && next) {
      onPreviewModalOpen();
    }
  }, [application, next, onPreviewModalOpen]);

  if (error) {
    return (
      <ResourceNotFound
        resourceName="application"
        to={routeResolver.getRoute("applications")}
      />
    );
  }

  if (!application) {
    return <LoadingScreenSkeleton />;
  }

  const hasMessages = !messagesCtx.error && messages && messages.data.length > 0;

  return (
    <>
      <MetaTitle path={[application.name]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("applications")}>
            Consumer Applications
          </BreadcrumbItem>
          <BreadcrumbItemWithId identifier={appId} />
        </Breadcrumbs>
        <Flex flexGrow={1} />
        <Box>
          <PreviewAppPortalButton loginUrl={loginUrl} previewError={previewPortalError} />
        </Box>
      </PageToolbar>
      <PageTitle>{application.name}</PageTitle>

      <Grid
        gridTemplateColumns={{
          sm: "minmax(0, 1fr)",
          md: "minmax(0, 3fr) minmax(240px, 1fr)",
        }}
        gap={8}
        mt={4}
      >
        <Box>
          <Card title="Recent Activity" w="100%">
            <Skeleton isLoaded={!!stats?.data}>
              <Box height="280px" w="100%" position="relative">
                {stats?.data && (
                  <TimeIntervalLineChart
                    startTime={stats.startDate}
                    endTime={stats.endDate}
                    data={transformStats(stats)}
                    emptyState={
                      <>
                        <Heading textAlign="center" as="h3" size="md" mb={2}>
                          No messages have been sent to this app in the last 6 hours.
                        </Heading>
                      </>
                    }
                  />
                )}
              </Box>
            </Skeleton>
          </Card>
        </Box>
        <Stack spacing={4} mb={6}>
          {application.uid && (
            <>
              <Stat name="UID">
                <Mono>{application.uid}</Mono>
              </Stat>
              <Divider />
            </>
          )}
          <Stat name="Created on">{safeFormatDate(application.createdAt)}</Stat>
          <Divider />
          <Stat name="Last updated">{safeFormatDate(application.updatedAt)}</Stat>
          <Divider />
          <SubscribedEventTypes />
        </Stack>
      </Grid>

      <Flex alignItems="center" mt={12} mb={4} gridGap={2}>
        <Heading as="h2" size="sm">
          Recent Messages
        </Heading>
        <Flex flexGrow={1} />
        <IconButton
          size="sm"
          aria-label="Refresh"
          variant="outline"
          isLoading={messagesCtx.isFetching}
          onClick={messagesCtx.refetch}
        >
          <Replay style={{ fontSize: 16 }} />
        </IconButton>
      </Flex>
      <Table
        data-cy="recentmessages-table"
        response={messages}
        requestElems={messagesCtx}
        emptyState="This application has not received any messages"
      >
        <Thead>
          <Tr>
            <Th>Event Type</Th>
            <Th>Message ID</Th>
            {orgSettings?.enableChannels && <Th>Channels</Th>}
            <Th isNumeric>Timestamp</Th>
          </Tr>
        </Thead>
        <Tbody>
          {hasMessages &&
            messages.data.map((message: MessageOut) => (
              <MessageRow
                key={message.id}
                message={message}
                enableChannels={orgSettings?.enableChannels ?? false}
              />
            ))}
          {!messages && (
            <Tr>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
            </Tr>
          )}
        </Tbody>
      </Table>

      <Modal isOpen={isPreviewModalOpen} onClose={onPreviewModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview in App Portal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Go to{" "}
            <Mono fontWeight="semibold">
              {next ? getAppPortalPath(next, appId) : "/"}
            </Mono>{" "}
            in the App Portal for application{" "}
            <Mono fontWeight="semibold">{application.uid || application.id}</Mono> (
            {application.name})
          </ModalBody>
          <ModalFooter>
            <PreviewAppPortalButton
              loginUrl={loginUrl && getAppPortalRedirectUrl(loginUrl, appId, next)}
              previewError={previewPortalError}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function PreviewAppPortalButton({
  loginUrl,
  previewError,
}: {
  loginUrl?: string;
  previewError?: string;
}) {
  return (
    <>
      {!previewError ? (
        <Button
          as="a"
          mr={2}
          href={loginUrl}
          isLoading={!loginUrl}
          spinnerPlacement="end"
          loadingText="Preview App Portal"
          target="_blank"
          variant="outline"
          rightIcon={<Launch style={{ fontSize: 16 }} />}
          size="sm"
        >
          Preview App Portal
        </Button>
      ) : (
        <Tooltip hasArrow label={<Text>{previewError}</Text>}>
          <Button
            as="div"
            mr={2}
            isDisabled
            variant="outline"
            rightIcon={<Launch style={{ fontSize: 16 }} />}
            size="sm"
          >
            Preview App Portal
          </Button>
        </Tooltip>
      )}
    </>
  );
}

function getAppPortalPath(next: string, appId: string) {
  // Remove appId from next path
  return next.replace(`/${appId}`, "");
}

function getAppPortalRedirectUrl(loginUrl: string, appId: string, next?: string) {
  const url = new URL(loginUrl);
  if (next) {
    url.searchParams.append("next", getAppPortalPath(next, appId));
  }
  return url.href;
}
