import { useEffect } from "react";
import {
  Box,
  Code,
  Heading,
  Stack,
  Text,
  useToast,
  Tag,
  Divider,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";

import * as C from "@svix/common/constants";
import { stripEmptyFields, setErrors } from "@svix/common/formUtils";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import Toggle from "@svix/common/widgets/form/Toggle";
import Link from "@svix/common/widgets/Link";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import ResourceError from "@svix/common/widgets/ResourceError";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { trackEvent } from "src/analytics";
import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import {
  OrganizationSettingsApi,
  SettingsInternalIn,
  SubscriptionOut,
  HttpErrorOut,
  BillingApi,
} from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import { isEE } from "src/utils";

const schema = yup.object().shape({
  customFontFamily: yup.string().nullable(),
  customColor: yup.string().nullable(),
  customLogoUrl: yup.string().nullable(),
  enableChannels: yup.boolean().nullable(),
  enforceHttps: yup.boolean().nullable(),
  enableIntegrationManagement: yup.boolean().nullable(),
  enableMessageTags: yup.boolean().nullable(),
  enableMessageStream: yup.boolean().nullable(),
  enableTransformations: yup.boolean(),
  whitelabelHeaders: yup.boolean(),
  wipeSuccessfulPayloads: yup.boolean().nullable(),
  enableEndpointOauthConfig: yup.boolean().nullable(),
  enableEndpointMtlsConfig: yup.boolean().nullable(),
});

const WHITELABEL_HEADERS_PLANS = ["business", "enterprise"];
const WIPE_SUCCESSFUL_PAYLOADS_PLANS = ["business", "enterprise"];
const ENABLE_MESSAGE_STREAM_PLANS = ["business", "enterprise"];
const ENABLE_ENDPOINT_OAUTH_CONFIG_PLANS = ["enterprise"];
const ENABLE_ENDPOINT_MTLS_CONFIG_PLANS = ["enterprise"];

export default function GeneralOrganizationSettings() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);
  const hasIntegrationsFeatureFlag = useAppSelector((state) =>
    state.auth.features.has("Integrations")
  );
  const toast = useToast();
  const {
    data: orgSettings,
    isLoading: loadingSettings,
    error,
    refetch,
  } = useQuery(["environments", activeEnvId, "orgSettings"], async () => {
    const config = await getApiConfiguration();
    const orgSettingsApi = new OrganizationSettingsApi(config);
    return orgSettingsApi.settingsGetOrganizationSettingsGet();
  });

  const { data: billing, isLoading: loadingBilling } = useQuery<
    SubscriptionOut,
    { body: HttpErrorOut }
  >(
    ["billing", "subscription"],
    async () => {
      const config = await getApiConfiguration();
      const billingApi = new BillingApi(config);
      return billingApi.getSubscriptionDetailsBillingSubscriptionGet();
    },
    { enabled: !isEE }
  );

  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const formCtx = useForm({
    resolver: yupResolver(schema),
    defaultValues: orgSettings || {
      customColor: "",
      customFontFamily: "",
      customLogoUrl: "",
      enableChannels: false,
      readOnly: false,
      enforceHttps: false,
      disableEndpointOnFailure: false,
      enableIntegrationManagement: false,
      enableMessageTags: false,
      enableMessageStream: false,
      enableTransformations: false,
      requireEndpointFilterTypes: false,
      requireEndpointChannel: false,
      whitelabelHeaders: false,
      wipeSuccessfulPayload: false,
    },
    mode: "onBlur",
  });

  const { reset, watch } = formCtx;

  const whitelabelHeadersEnabled =
    isEE || (billing && WHITELABEL_HEADERS_PLANS.includes(billing.planName));

  // If already enabled, let the user use the toggle
  const wipeSuccessfulPayloadsEnabled =
    isEE ||
    watch("wipeSuccessfulPayload") ||
    (billing && WIPE_SUCCESSFUL_PAYLOADS_PLANS.includes(billing.planName));

  const enableMessageStreamEnabled =
    watch("enableMessageStream") ||
    (billing && ENABLE_MESSAGE_STREAM_PLANS.includes(billing.planName));

  const enableEndpointOauthConfigEnabled =
    isEE ||
    watch("enableEndpointOauthConfig") ||
    (billing && ENABLE_ENDPOINT_OAUTH_CONFIG_PLANS.includes(billing.planName));

  const enableEndpointMtlsConfigEnabled =
    isEE ||
    watch("enableEndpointMtlsConfig") ||
    (billing && ENABLE_ENDPOINT_MTLS_CONFIG_PLANS.includes(billing.planName));

  useEffect(() => {
    reset(orgSettings);
  }, [reset, orgSettings]);

  const enableChannels = watch("enableChannels");
  useEffect(() => {
    if (!enableChannels) {
      formCtx.setValue("requireEndpointChannel", false);
    }
  }, [formCtx, enableChannels]);

  async function saveSettings(values: SettingsInternalIn) {
    const config = await getApiConfiguration();
    const orgSettingsApi = new OrganizationSettingsApi(config);
    try {
      const settings = await orgSettingsApi.settingsUpdateOrganizationSettingsPut(
        stripEmptyFields<SettingsInternalIn>(values)
      );
      reset(settings);

      if (settings.enableTransformations && !orgSettings?.enableTransformations) {
        trackEvent("Enabled Transformations");
      }

      if (settings.enableMessageStream && !orgSettings?.enableMessageStream) {
        trackEvent("Enabled Polling Endpoints");
      }

      toast({ status: "success", title: "Environment settings saved" });
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }

    onConfirmationClose();
  }

  async function onSave(values: SettingsInternalIn) {
    if (!!orgSettings?.whitelabelHeaders !== watch("whitelabelHeaders")) {
      onConfirmationOpen();
    } else {
      await saveSettings(values);
    }
  }

  if (error) {
    return <ResourceError resourceName="environment settings" onClick={refetch} />;
  }

  return (
    <>
      <MetaTitle path={["General Settings"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("settings")}>
            Settings
          </BreadcrumbItem>
          <BreadcrumbItem>General Settings</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <Card>
        <Heading as="h2" size="md" mb={2}>
          General Settings
        </Heading>
        <Text size="md" variant="caption">
          Customize this environment.
        </Text>
        <Form onSubmit={onSave} {...formCtx}>
          <Stack spacing={4} mt={4} width="100%" maxW="40em">
            <Divider />
            <Heading as="h3" size="sm" pb={1}>
              Endpoints
            </Heading>
            <Toggle
              label="HTTPS Only Endpoints"
              control={formCtx.control}
              name="enforceHttps"
              helpText={<>Enforces HTTPS on all endpoints of this environment.</>}
              isLoading={loadingSettings}
            />
            <Toggle
              label="Disable endpoint on failure"
              control={formCtx.control}
              name="disableEndpointOnFailure"
              helpText={
                <>
                  If messages to a particular endpoint have been consistently failing for
                  some time, we will automatically disable the endpoint and let you know{" "}
                  <Link isExternal href={C.docs.operationalWebhooks}>
                    via webhook
                  </Link>
                  . Read more about it{" "}
                  <Link isExternal href={C.docs.advanced.retries.disablingEndpoints}>
                    in the docs
                  </Link>
                  .
                </>
              }
              isLoading={loadingSettings}
            />
            <Divider />
            <Heading as="h3" size="sm" pb={1}>
              App Portal
            </Heading>

            <Toggle
              label="Enable Channels"
              control={formCtx.control}
              name="enableChannels"
              helpText={
                <>
                  Controls whether or not your users can configure{" "}
                  <strong>channels</strong> from the Consumer App Portal.
                </>
              }
              isLoading={loadingSettings}
            />
            {!isEE && (
              <Toggle
                label="Read Only mode"
                control={formCtx.control}
                name="readOnly"
                helpText={
                  <>
                    Sets your Consumer App Portal to read only so your customers can view
                    but not modify their data.
                  </>
                }
                isLoading={loadingSettings}
              />
            )}
            {hasIntegrationsFeatureFlag && (
              <Toggle
                label="Allow users to manage integrations"
                control={formCtx.control}
                name="enableIntegrationManagement"
                helpText={
                  <>
                    Controls whether or not your users can manage integrations from the
                    Consumer App Portal. We recommend disabling this if you manage
                    integrations on your users' behalf.
                  </>
                }
                isLoading={loadingSettings}
              />
            )}
            <Toggle
              label={<>Require event type filters for endpoints</>}
              control={formCtx.control}
              name="requireEndpointFilterTypes"
              helpText={
                <>If enabled, all new Endpoints must filter on at least one event type.</>
              }
              isLoading={loadingSettings}
            />

            <Toggle
              label={<>Require channel filters for endpoints</>}
              control={formCtx.control}
              isDisabled={!watch("enableChannels")}
              name="requireEndpointChannel"
              helpText={
                <>If enabled, all new Endpoints must filter on at least one channel.</>
              }
              isLoading={loadingSettings}
            />

            {!isEE && (
              <Toggle
                label={<RequiresPaidPlanFeatureLabel label="Advanced endpoint types" />}
                control={formCtx.control}
                name="enableMessageStream"
                isDisabled={!enableMessageStreamEnabled}
                helpText={
                  <>
                    Allows users to configure Polling Endpoints and FIFO endpoints to get
                    messages. Read more about them in the{" "}
                    <Link isExternal href={C.docs.advanced.advancedEndpointTypes}>
                      docs
                    </Link>
                    .
                  </>
                }
                isLoading={loadingSettings}
              />
            )}

            <Toggle
              label={
                <RequiresPaidPlanFeatureLabel
                  label="Enable OAuth configuration"
                  enterpriseOnly
                />
              }
              control={formCtx.control}
              name="enableEndpointOauthConfig"
              isDisabled={!enableEndpointOauthConfigEnabled}
              helpText={<>Allows users to configure OAuth for their endpoints.</>}
              isLoading={loadingSettings}
            />

            <Toggle
              label={
                <RequiresPaidPlanFeatureLabel
                  label="Enable mTLS configuration"
                  enterpriseOnly
                />
              }
              control={formCtx.control}
              name="enableEndpointMtlsConfig"
              isDisabled={!enableEndpointMtlsConfigEnabled}
              helpText={
                <>Allows users to configure mutual TLS (mTLS) for their endpoints.</>
              }
              isLoading={loadingSettings}
            />

            <Divider />
            <Heading as="h3" size="sm" pb={1}>
              Other
            </Heading>

            <Toggle
              label="Transformations"
              control={formCtx.control}
              name="enableTransformations"
              helpText={
                <>
                  Controls whether or not your users can add transformations to their
                  endpoints. Transformations are code that can change a message's HTTP
                  method, destination URL, and payload body in-flight.
                </>
              }
              isLoading={loadingSettings}
            />

            {!isEE && (
              <Toggle
                label={
                  <RequiresPaidPlanFeatureLabel label="Delete successful message payloads" />
                }
                control={formCtx.control}
                name="wipeSuccessfulPayload"
                isDisabled={!wipeSuccessfulPayloadsEnabled}
                helpText={
                  <>
                    Delete message payloads from Svix after they are successfully
                    delivered to the endpoint. Only affects messages sent after this
                    setting is enabled.
                  </>
                }
                isLoading={loadingSettings}
              />
            )}

            <Toggle
              label={<RequiresPaidPlanFeatureLabel label="White label headers" />}
              control={formCtx.control}
              name="whitelabelHeaders"
              isDisabled={!whitelabelHeadersEnabled}
              helpText={
                <>
                  Changes the prefix of the webhook HTTP headers to use the{" "}
                  <Code>webhook-</Code> prefix.{" "}
                  <Text as="strong">
                    Changing this setting can break existing integrations.
                  </Text>
                </>
              }
              isLoading={loadingSettings || loadingBilling}
            />
            <Divider />
          </Stack>
          <Box maxW="32em" my={2}>
            <GeneralFormErrors />
          </Box>
          <Box mt={3}>
            <SubmitButton
              type="submit"
              variant="solid"
              loadingText="Saving"
              showLoading
              isDisabled={loadingSettings}
            >
              Save
            </SubmitButton>
          </Box>

          <ConfirmationDialog
            title="Save settings"
            onCancel={onConfirmationClose}
            isOpen={isConfirmationOpen}
            onOk={formCtx.handleSubmit(saveSettings)}
            typeToConfirmStr="whitelabel-headers"
            colorScheme="red"
            labelOk="Save changes"
          >
            You are changing the White label headers settings, which can break existing
            integration with your webhooks. Are you sure you want to proceed?
            <GeneralFormErrors />
          </ConfirmationDialog>
        </Form>
      </Card>
    </>
  );
}

function RequiresPaidPlanFeatureLabel({
  label,
  enterpriseOnly = false,
}: {
  label: React.ReactNode;
  enterpriseOnly?: boolean;
}) {
  return (
    <Wrap spacing={2}>
      <WrapItem>
        <Text>{label}</Text>
      </WrapItem>
      <WrapItem>
        <Link href={routeResolver.getRoute("billing")}>
          {enterpriseOnly ? (
            <Tag color="blue.500">Requires Enterprise plan</Tag>
          ) : (
            <Tag color="blue.500">Requires Pro or Enterprise plan</Tag>
          )}
        </Link>
      </WrapItem>
    </Wrap>
  );
}
