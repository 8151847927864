import { Analytics } from "@segment/analytics-next";
import posthog from "posthog-js";

import * as C from "@svix/common/constants";
import { logError } from "@svix/common/logger";

declare global {
  interface Window {
    analytics: Analytics | undefined;
  }
}

window.analytics?.ready(() => {
  posthog.init("phc_vqk6r12rFcoSqqRVNIT81inyGF0y1a753NjKzKGaPg", {
    api_host: "https://eu.i.posthog.com",
    segment: window.analytics as any, // TODO: Posthog has a different type?
    capture_pageview: false, // we use segment's `analytics.page()` for pageviews
    capture_exceptions: false,
    capture_performance: false,
    capture_heatmaps: false,
    capture_dead_clicks: false,
    capture_pageleave: false,
  });
});

export function trackEvent(event: string, properties?: object) {
  return analyticsWrapper(async () => {
    await window.analytics!.track(event, properties);
  });
}

export async function trackPageView() {
  return analyticsWrapper(async () => {
    await window.analytics!.page();
  });
}

export function reset() {
  return analyticsWrapper(() => {
    window.analytics!.reset();
  });
}

const analyticsWrapper = async (fn: (() => Promise<void>) | (() => void)) => {
  if (C.envConfig.name !== "prod") {
    return;
  }

  try {
    await fn();
  } catch (e) {
    logError(e);
  }
};
