import { lazy, Suspense, useEffect } from "react";
import {
  Box,
  Divider,
  HStack,
  SkeletonText,
  Stack,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Heading,
  Link as StyledLink,
  useRadioGroup,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useForm, Control, useController } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { ConnectorIn, TemplateOut } from "svix";
import { TransformationTemplateApi, ConnectorKind } from "svix/dist/openapi";

import * as C from "@svix/common/constants";
import { setErrors } from "@svix/common/formUtils";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import TextArea from "@svix/common/widgets/form/TextArea";
import TextField from "@svix/common/widgets/form/TextField";
import IntegrationTile from "@svix/common/widgets/IntegrationTile";
import SubmitButton from "@svix/common/widgets/SubmitButton";
import TestTransformation from "@svix/common/widgets/TestTransformation";

import { trackEvent } from "src/analytics";
import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import AIModalButton from "./AIGenerateButton";
import { getExampleCode, templateTypes } from "./constants";

const CodeEditor = lazy(() => import("@svix/common/widgets/form/CodeEditor"));

export function ConnectorForm(props: { isUpdate?: boolean; template?: TemplateOut }) {
  const { isUpdate = false, template } = props;
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const queryClient = useQueryClient();
  const history = useHistory();
  const formCtx = useForm<ConnectorIn>({
    defaultValues: {
      ...template,
      name: template?.name ?? "",
      logo: template?.logo ?? "",
      kind: template?.kind,
      transformation: template?.transformation ?? getExampleCode(ConnectorKind.Custom),
    },
  });
  const { watch, reset, setValue } = formCtx;
  const [kind] = watch(["kind"]);

  useEffect(() => {
    reset({
      ...template,
      transformation:
        template?.transformation ??
        getExampleCode(template?.kind ?? ConnectorKind.Custom),
    });
  }, [reset, template]);

  useEffect(() => {
    if (kind) {
      if (kind === "Custom") {
        setValue("name", "");
        setValue("logo", "");
      } else {
        setValue("name", kind);
        setValue("logo", `${window.location.origin}${templateTypes[kind].icon}`);
      }
      setValue("transformation", getExampleCode(kind));
    }
  }, [kind, setValue]);

  const { data: availableEvents } = useQuery(
    ["environments", activeEnvId, "eventTypes"],
    async () => {
      const api = await getSvix();
      return await api.eventType.list({ limit: 250, includeArchived: false });
    }
  );

  const onSubmit = async (form: ConnectorIn) => {
    deleteEmptyFields(form);
    try {
      trackEvent("Add Transformation Template", { type: form.kind });
      const svix = await getSvix();
      const api = new TransformationTemplateApi(svix._configuration);
      await api.v1TransformationTemplateCreate({ connectorIn: form });
      await queryClient.invalidateQueries(["environments", activeEnvId, "connectors"]);
      history.push(routeResolver.getRoute("connectors"));
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  };

  const onUpdate = async (form: ConnectorIn) => {
    deleteEmptyFields(form);
    try {
      const svix = await getSvix();
      const api = new TransformationTemplateApi(svix._configuration);
      await api.v1TransformationTemplateUpdate({
        transformationTemplateId: template!.id,
        templateUpdate: form,
      });
      await queryClient.refetchQueries(["environments", activeEnvId, "connectors"]);
      history.push(routeResolver.getRoute("connectors"));
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  };

  const isCustom = kind === "Custom";

  return (
    <>
      <Box maxW="60em">
        <Form onSubmit={isUpdate ? onUpdate : onSubmit} {...formCtx}>
          <Stack spacing={5}>
            <Text variant="caption">
              Connectors lets you provide your customers with pre-made integrations to
              connect your webhooks to other services. Learn more{" "}
              <StyledLink
                color="blue.500"
                href={C.docs.advanced.transformations.templates}
                isExternal
              >
                in our docs.
              </StyledLink>
            </Text>
            <TemplateTypeSelector control={formCtx.control} />
            <Divider />
            {isCustom && (
              <TextField
                control={formCtx.control}
                name="name"
                label="Name"
                required
                autoFocus
                placeholder="e.g. Send message to Slack"
              />
            )}
            {isCustom && (
              <TextField
                control={formCtx.control}
                name="logo"
                label="Logo URL"
                required
                placeholder="https://example.com/logo.png"
              />
            )}
            <TextArea
              control={formCtx.control}
              name="description"
              label="Description"
              placeholder="What does this connector do?"
            />

            <Divider />

            {isCustom && (
              <>
                <TextArea
                  control={formCtx.control}
                  name="instructions"
                  label="Instructions"
                  placeholder="Let your users know how to use this integration"
                />
                <TextField
                  control={formCtx.control}
                  name="instructionsLink"
                  label="Learn more link"
                  placeholder="https://example.com/integration/learn-more"
                  helperText="Link to your site where users can learn more about this connector (optional)"
                />
                <Divider />
              </>
            )}
            <EventsList
              control={formCtx.control}
              name="filterTypes"
              label="Supported event types"
              availableEvents={availableEvents?.data || []}
              emptyState="Using all events."
            />

            <Box>
              <Text fontWeight="semibold" fontSize="md">
                Transformation code
              </Text>
              <Text mb={2}>
                The default transformation that will be applied to the event after
                creating the integration. This code needs to work for all the event types
                selected above. It can be customized by your users.
              </Text>
              <Suspense fallback={<SkeletonText noOfLines={2} />}>
                <Box
                  border="1px solid"
                  borderColor="background.modifier.border"
                  borderRadius="lg"
                  position="relative"
                >
                  <Box position="absolute" zIndex={5} right={1} bottom={1}>
                    <AIModalButton
                      eventTypes={watch("filterTypes") ?? []}
                      templateType={watch("kind") ?? ConnectorKind.Custom}
                      onGenerate={(code) => {
                        setValue("transformation", code);
                      }}
                    />
                  </Box>
                  <CodeEditor
                    lang={Lang.Javascript}
                    value={watch("transformation")}
                    onChange={(value) => setValue("transformation", value)}
                  />
                </Box>
              </Suspense>
            </Box>
            <Accordion allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Heading size="sm" as="div" flex="1" textAlign="left">
                        Test transformation
                      </Heading>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <TestTransformation code={watch("transformation")} getSvix={getSvix} />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <TextField
              control={formCtx.control}
              name="featureFlag"
              label="Feature flag (optional)"
              placeholder="Limit who can see this connector (optional)"
              helperText="Feature flags allow you to control which connectors are visible to which users. When set, only users with the same feature flag enabled will be able to see this connector."
            />
            <GeneralFormErrors />
          </Stack>
          <HStack
            mt={4}
            pt={4}
            spacing={4}
            borderTop="1px"
            borderColor="background.modifier.border"
          >
            <Button
              colorScheme="gray"
              as={Link}
              to={routeResolver.getRoute("connectors")}
            >
              Cancel
            </Button>

            {isUpdate ? (
              <SubmitButton isLoading={formCtx.formState.isSubmitting}>
                Update
              </SubmitButton>
            ) : (
              <SubmitButton isLoading={formCtx.formState.isSubmitting}>
                Create
              </SubmitButton>
            )}
          </HStack>
        </Form>
      </Box>
    </>
  );
}

function deleteEmptyFields(form: ConnectorIn) {
  const nullableFields: (keyof ConnectorIn)[] = ["featureFlag", "instructionsLink"];
  nullableFields.forEach((key) => {
    const field = form[key];
    if (typeof field === "string" && field.trim() === "") {
      delete form[key];
    }
  });
}

const TemplateTypes: ConnectorKind[] = [
  ConnectorKind.Slack,
  ConnectorKind.Discord,
  ConnectorKind.Teams,
  ConnectorKind.Zapier,
  ConnectorKind.Hubspot,
  ConnectorKind.Inngest,
  ConnectorKind.Windmill,
  ConnectorKind.Custom,
];

function TemplateTypeSelector(props: { control: Control<ConnectorIn> }) {
  const { control } = props;
  const { field } = useController({ control, name: "kind" });
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "kind",
    onChange: field.onChange,
    value: field.value,
  });
  const group = getRootProps();

  return (
    <Stack spacing={4} {...group}>
      <Text as="label" fontWeight="semibold" fontSize="md">
        Choose connector type
      </Text>
      <Wrap mt={0} spacing={2}>
        {TemplateTypes.map((tt) => {
          const radio = getRadioProps({ value: tt });

          return (
            <WrapItem key={tt}>
              <IntegrationTile
                key={tt}
                {...radio}
                icon={templateTypes[tt].icon}
                name={templateTypes[tt].name}
              />
            </WrapItem>
          );
        })}
      </Wrap>
      <Text>{field.value ? templateTypes[field.value].description : ""}</Text>
    </Stack>
  );
}
