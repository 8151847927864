import { Flex, Icon } from "@chakra-ui/react";

import RadioButtons from "@svix/common/widgets/form/RadioButtons";

import { regions } from "src/constants";
import { OrganizationRegion } from "src/generated/dashboard-openapi/models/OrganizationRegion";
import { useAppSelector } from "src/hooks/store";

interface IEnvTagToggleProps {
  isDisabled?: boolean;
  control: any;
  name: string;
}

const DEFAULT_REGIONS: readonly OrganizationRegion[] = Object.freeze(["eu", "us"]);

export default function EnvTagRadio(props: IEnvTagToggleProps) {
  const features = useAppSelector((store) => store.auth.features);

  let availableRegions = DEFAULT_REGIONS;
  if (features.has("NewRegions")) {
    availableRegions = [...availableRegions, "ca", "au"];
  }

  const buttons = availableRegions.map((region) => ({
    title: (
      <Flex alignItems="center">
        <Icon boxSize={8} as={regions[region].flag} mr={4} />
        {regions[region].shortLabel}
      </Flex>
    ),
    description: "",
    value: region,
  }));

  return (
    <RadioButtons<OrganizationRegion>
      label="Region"
      buttons={buttons}
      direction="row"
      {...props}
    />
  );
}
