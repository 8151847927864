import { useMemo } from "react";
import {
  Box,
  Flex,
  ButtonGroup,
  Button,
  useToast,
  Text,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { parseExpression as parseCronExpression } from "cron-parser";
import { toString as cronToString } from "cronstrue";
import { SubmitHandler, useForm, UseFormReturn } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";

import { setErrors } from "@svix/common/formUtils";
import { isValidJSON, isValidURLEncoded } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import CodeEditor from "@svix/common/widgets/form/CodeEditor";
import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import Select from "@svix/common/widgets/form/Select";
import TextField from "@svix/common/widgets/form/TextField";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { SourceOut, SourcesApi, IngestSourceIn } from "src/api/in";
import { useAppSelector } from "src/hooks/store";

const schema = yup.object().shape({
  schedule: yup
    .string()
    .required("Schedule is required")
    .test("valid-cron", "Invalid cron expression", (v) =>
      Boolean(v && isValidCronSchedule(v))
    ),
});

export default function CronSourceConfiguration({
  source,
}: {
  source: SourceOut & { type: "cron" };
}) {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;

  const queryClient = useQueryClient();
  const toast = useToast();
  const defaultValues = {
    schedule: source.config.schedule,
    payload: source.config.payload,
    contentType: source.config.contentType ?? "application/json",
  };
  const formCtx = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  async function onSave(form: CronConfigurationForm) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);

    const updatedSource = {
      ...source,
      config: {
        ...form,
      },
    } as IngestSourceIn;

    try {
      await api.update(source.id, updatedSource);
      formCtx.reset({}, { keepValues: true });
      queryClient.invalidateQueries([
        "environments",
        activeEnvId,
        "ingest",
        "sources",
        source.id,
      ]);
      toast({
        title: "Configuration updated",
        status: "success",
      });
    } catch (error) {
      setErrors(formCtx.setError, error);
    }
  }

  return (
    <Card maxW="50em">
      <CronConfigurationForm
        formCtx={formCtx}
        onSubmit={onSave}
        showActions={formCtx.formState.isDirty}
        onCancel={() => formCtx.reset(defaultValues)}
      />
    </Card>
  );
}

export interface CronConfigurationForm {
  schedule: string;
  payload: string;
  contentType: string;
}

export function CronConfigurationForm({
  formCtx,
  onSubmit,
  onCancel,
  showCancel = true,
  showActions = true,
  cancelLabel = "Cancel",
}: {
  formCtx: UseFormReturn<CronConfigurationForm>;
  onSubmit: SubmitHandler<CronConfigurationForm>;
  onCancel?: () => void;
  showCancel?: boolean;
  showActions?: boolean;
  cancelLabel?: string;
}) {
  const { watch } = formCtx;

  const onCancelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    formCtx.reset({}, { keepDefaultValues: true });
    onCancel?.();
  };

  const [payload, schedule, contentType] = watch(["payload", "schedule", "contentType"]);
  const humanizedSchedule = useMemo(() => {
    if (isValidCronSchedule(schedule)) {
      return cronToString(schedule);
    }
    return "Invalid cron schedule";
  }, [schedule]);

  return (
    <Form onSubmit={onSubmit} {...formCtx} shouldPromptOnDirty={false}>
      <Stack spacing={4}>
        <Box>
          <Text fontSize="md">Payload</Text>
          <Text variant="caption" mb={1}>
            The static payload that will be sent to the destination.
          </Text>
          <Box border="1px solid" borderColor="gray.200" borderRadius="md">
            <CodeEditor
              value={payload}
              onChange={(value) =>
                formCtx.setValue("payload", value, { shouldDirty: true })
              }
              lang={Lang.Json}
            />
          </Box>
          {!isValidPayloadForContentType(payload, contentType) && (
            <HStack color="text.muted" alignItems="center" spacing={1} mt={0.5}>
              <ErrorOutlineIcon style={{ fontSize: "1.2em" }} />
              <Text color="text.warning">
                {`The payload is not valid for the selected content type (${contentType}).`}
              </Text>
            </HStack>
          )}
        </Box>
        <Select label="Content Type" control={formCtx.control} name="contentType">
          <option key="application/json" value="application/json">
            application/json
          </option>
          <option
            key="application/x-www-form-urlencoded"
            value="application/x-www-form-urlencoded"
          >
            application/x-www-form-urlencoded
          </option>
          <option key="text/plain" value="text/plain">
            text/plain
          </option>
        </Select>
        <TextField
          label="Schedule (in unix-cron format)"
          name="schedule"
          control={formCtx.control}
          helperText={humanizedSchedule}
          placeholder="i.e. 0 * * * *"
        />
        <GeneralFormErrors />
      </Stack>
      {showActions && (
        <Box mt={4}>
          <Flex justifyContent="flex-end" mt={4}>
            <ButtonGroup>
              {showCancel && (
                <Button variant="outline" onClick={onCancelClick}>
                  {cancelLabel}
                </Button>
              )}
              <SubmitButton isLoading={formCtx.formState.isSubmitting}>Save</SubmitButton>
            </ButtonGroup>
          </Flex>
        </Box>
      )}
    </Form>
  );
}

const isValidCronSchedule = (schedule: string) => {
  try {
    parseCronExpression(schedule);
    // Found cases where this can throw even when cronParser says it's valid
    cronToString(schedule);
    return true;
  } catch (e) {
    return false;
  }
};

const isValidPayloadForContentType = (payload: string, contentType: string) => {
  switch (contentType) {
    case "application/json":
      return isValidJSON(payload);
    case "application/x-www-form-urlencoded":
      return isValidURLEncoded(payload);
    case "text/plain":
      return true;
    default:
      return true;
  }
};
