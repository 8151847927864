import {
  Thead,
  Tr,
  Th,
  Tbody,
  Text,
  Flex,
  Button,
  SkeletonText,
  Stack,
  HStack,
  Tag,
} from "@chakra-ui/react";
import { Add } from "@material-ui/icons";
import UpdateIcon from "@material-ui/icons/Update";
import { toString as cronToString } from "cronstrue";
import { Link } from "react-router-dom";

import usePagination from "@svix/common/hooks/pagination";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import Mono from "@svix/common/widgets/Mono";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import ResourceError from "@svix/common/widgets/ResourceError";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getSvix } from "src/api";
import { ListSourceOut, SourceNames, SourcesApi } from "src/api/in";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import SourcesEmptyState from "./SourcesEmptyState";

export default function Sources() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const [sources, sourcesCtx] = usePagination<ListSourceOut>(
    ["environments", activeEnvId, "ingest", "sources"],
    async (_iterator) => {
      const sv = await getSvix();
      const api = new SourcesApi(sv);
      return api.list(50);
    }
  );

  if (sourcesCtx.error) {
    return <ResourceError resourceName="sources" onClick={sourcesCtx.refetch} />;
  }

  const hasNoSources = sources?.data.length === 0;
  if (hasNoSources) {
    return <SourcesEmptyState />;
  }

  return (
    <>
      <MetaTitle path={["Applications"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem>Sources</BreadcrumbItem>
        </Breadcrumbs>
        <Flex flexGrow={1} />
        <Button
          colorScheme="brand"
          as={Link}
          leftIcon={<Add />}
          to={routeResolver.getRoute("in.sources.new")}
          size="sm"
        >
          Create Source
        </Button>
      </PageToolbar>
      <Table
        response={sources}
        requestElems={sourcesCtx}
        variant="hover"
        showPagination={false}
      >
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {sources?.data.map((source) => (
            <Tr key={source.id}>
              <TableCell
                to={routeResolver.getRoute("in.sources._id", { sourceId: source.id })}
                align="left"
              >
                <Stack spacing={1}>
                  <HStack spacing={2} alignItems="center">
                    <Text fontWeight="semibold">{source.name}</Text>
                    <Tag size="sm" colorScheme="gray" variant="subtle">
                      <Mono fontSize="xs">{SourceNames[source.type]}</Mono>
                    </Tag>
                  </HStack>
                  {source.type === "cron" ? (
                    <HStack spacing={1} alignItems="center" color="gray.500">
                      <UpdateIcon style={{ fontSize: "1.2em" }} />
                      <Mono fontSize="xs">{cronToString(source.config.schedule)}</Mono>
                    </HStack>
                  ) : (
                    <Mono fontSize="xs" color="gray.500">
                      {source.ingestUrl}
                    </Mono>
                  )}
                </Stack>
              </TableCell>
              <TableCell
                isNumeric
                to={routeResolver.getRoute("in.sources._id", { sourceId: source.id })}
              >
                {source.deletedAt ? <Tag colorScheme="red">Disabled</Tag> : <></>}
              </TableCell>
            </Tr>
          ))}
          {sourcesCtx.isLoading && (
            <Tr>
              <TableCell>
                <SkeletonText noOfLines={2} />
              </TableCell>
              <TableCell />
            </Tr>
          )}
        </Tbody>
      </Table>
    </>
  );
}
