// TODO: better import syntax?
import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import {Configuration} from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {isCodeInRange} from '../util';

import { ApproveCliLoginIn } from '../models/ApproveCliLoginIn';
import { ApproveCliLoginOut } from '../models/ApproveCliLoginOut';
import { HTTPValidationError } from '../models/HTTPValidationError';

/**
 * no description
 */
export class CLIApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Approve a CLI login session.
     * Approve Cli Login
     * @param approveCliLoginIn 
     */
    public async approveCliLoginCliLoginSessionApprovePost(approveCliLoginIn: ApproveCliLoginIn, options?: Configuration): Promise<RequestContext> {
        let config = options || this.configuration;

        // verify required parameter 'approveCliLoginIn' is not null or undefined
        if (approveCliLoginIn === null || approveCliLoginIn === undefined) {
            throw new RequiredError('Required parameter approveCliLoginIn was null or undefined when calling approveCliLoginCliLoginSessionApprovePost.');
        }


        // Path Params
        const localVarPath = '/cli-login/session/approve/';

        // Make Request Context
        const requestContext = config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params

        // Header Params

        // Form Params


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(approveCliLoginIn, "ApproveCliLoginIn", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod = null;
        // Apply auth methods
        authMethod = config.authMethods["HTTPBearer"]
        if (authMethod) {
            await authMethod.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class CLIApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to approveCliLoginCliLoginSessionApprovePost
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async approveCliLoginCliLoginSessionApprovePost(response: ResponseContext): Promise<ApproveCliLoginOut > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: ApproveCliLoginOut = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ApproveCliLoginOut", ""
            ) as ApproveCliLoginOut;
            return body;
        }
        if (isCodeInRange("422", response.httpStatusCode)) {
            const body: HTTPValidationError = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "HTTPValidationError", ""
            ) as HTTPValidationError;
            throw new ApiException<HTTPValidationError>(422, body);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: ApproveCliLoginOut = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "ApproveCliLoginOut", ""
            ) as ApproveCliLoginOut;
            return body;
        }

        let body = response.body || "";
        throw new ApiException<string>(response.httpStatusCode, "Unknown API Status Code!\nBody: \"" + body + "\"");
    }

}
